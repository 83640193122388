import React, { PropsWithChildren } from "react";
import "./entity_toolbar.scss";
import Button, { ButtonStyle } from "src/components/Ui/Button/Button";
import AnimatedHider, { AnimatedHiderVariant } from "src/components/Ui/AnimatedHider/AnimatedHider";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface EntityToolbarProps {
    onAddEntityClick?: () => void,
    isFormOpened?: boolean
}

function EntityToolbar({ onAddEntityClick, isFormOpened, children }: PropsWithChildren<EntityToolbarProps>) {
    const { t } = useTranslation();

    return (
        <div className={classNames("entity-toolbar", { open: isFormOpened })}>
            {children}

            {onAddEntityClick && (
                <AnimatedHider variant={AnimatedHiderVariant.ShrinkLeftRight}
                               className="add-entity-btn"
                               open={!isFormOpened}>
                    <Button onClick={onAddEntityClick}
                            buttonStyle={ButtonStyle.LightBlue}
                            className={"text-uppercase"}>
                        + {t("add")}
                    </Button>
                </AnimatedHider>
            )}
        </div>
    );
}

export default EntityToolbar;
