import React, { ChangeEvent, useCallback, useMemo, useRef } from "react";
import "./search_input.scss";
import { IconSearch, IconX } from "@tabler/icons";
import InputField from "src/components/Ui/InputField/InputField";
import classNames from "classnames";
import { WithClassname } from "src/utils/type_utils";
import { useTranslation } from "react-i18next";

interface SearchInputProps {
    search: string,
    onSearch: (search: string) => void,
    onClear: () => void,
    fullBorder?: boolean,
    readonly?: boolean
    placeholder?: string,
    showIcon?: boolean
}

function SearchInput({
                         placeholder,
                         search,
                         onSearch,
                         onClear,
                         fullBorder = false,
                         readonly,
                         className,
                         showIcon = true
                     }: WithClassname<SearchInputProps>) {

    const { t } = useTranslation();

    const searchActive = Boolean(search);
    const inputRef = useRef<HTMLDivElement>(null);
    const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => onSearch(e.target.value), [onSearch]);

    const toggleSearchActive = useCallback(() => {
        if (searchActive) {
            onClear();
        } else {
            inputRef.current?.querySelector("input")?.focus();
        }
    }, [searchActive, onClear]);

    const activeIcon = useMemo(() => !showIcon ? undefined : (searchActive ? IconX : IconSearch), [searchActive, showIcon]);

    return (
        <div className={classNames("search-input", className, {
            "full-border": fullBorder,
            "bottom-border": !fullBorder
        })}>
            <InputField onChange={handleSearch}
                        value={search}
                        ref={inputRef}
                        icon={activeIcon}
                        readonly={readonly}
                        onIconClick={toggleSearchActive}
                        placeholder={placeholder ?? t("search_placeholder")}
                        className={classNames({ "focused": searchActive })}/>
        </div>
    );
}

export default SearchInput;
