import "./dropdown_item.scss";
import { MouseEventHandler } from "react";
import classNames from "classnames";
import { ReactChildren } from "../../../utils/type_utils";

export interface DropdownItemProps {
    children: ReactChildren,
    className?: string,
    onClick?: MouseEventHandler<HTMLDivElement>
}

function DropdownItem({ onClick, className, children }: DropdownItemProps) {
    return (
        <div onClick={onClick}
             className={classNames("dropdown-row", className)}>
            {children}
        </div>
    );
}

export default DropdownItem;
