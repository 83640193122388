import React, { CSSProperties, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import { useOnClickOutside } from "src/utils/hooks/useOnClickOutside";
import classNames from "classnames";
import AnimatedHider from "../AnimatedHider/AnimatedHider";
import "./date_range_picker.scss";
import { dateFnsLocale, Utils } from "src/utils/utils";
import {
    addDays,
    addMonths,
    addYears,
    eachWeekOfInterval,
    endOfMonth,
    getDay,
    getDecade,
    getMonth,
    getYear,
    isAfter,
    isBefore,
    isSameDay,
    setDay,
    setMonth,
    setYear,
    startOfMonth,
    subMonths,
    subYears,
} from "date-fns";
import Button, { ButtonStyle } from "../Button/Button";
import GIcon from "../GIcon/GIcon";

import { clone } from "lodash";
import { useMutationObserver } from "rooks";
import { formatLocale } from "src/utils/date_fns_format_locale";
import { DateTime } from "luxon";

export enum DatePickerMode {
    Year,
    Month,
    Week,
    Day,
}

export interface DateRangePickerProps<T extends HTMLElement> {
    className?: string;
    children?: React.ReactNode | React.ReactElement | React.ComponentType<any>;
    style?: CSSProperties;
    date: Array<Date>;
    onDateChange: (index: number, newDate: Date) => void;
    onIndexChange?: (index: number) => void;
    mode?: DatePickerMode;
    anchorRef: MutableRefObject<T | null>;
    onOpenChange?: (open: boolean) => void;
    isOpen?: boolean;
    noPicker?: boolean;
}

interface DateRangePickerDecadeYearsProps {
    shownDecade: Date;
    date: Array<Date>;
    onPickYear: (newShownDecade: Date) => void;
}

interface DateRangePickerYearMonthsProps {
    shownYear: Date;
    date: Array<Date>;
    onPickMonth: (newShownYear: Date) => void;
}

function DateRangePickerDecadeYears({
                                        onPickYear,
                                        date,
                                        shownDecade,
                                    }: DateRangePickerDecadeYearsProps) {
    const currentYear = getYear(date[0]);
    const currentDecade = getDecade(shownDecade);

    return (
        <div className="year-months">
            {Utils.genArrayFromTo(
                currentDecade,
                currentDecade + 11,
                1,
                true,
            ).map((year) => {
                const thisYear = setYear(shownDecade, year);
                const isHighlighted = year === currentYear;

                return (
                    <Button
                        className="month"
                        onClick={() => onPickYear(thisYear)}
                        buttonStyle={
                            isHighlighted
                                ? ButtonStyle.Blue
                                : ButtonStyle.TransparentBlack
                        }
                        key={year}
                    >
                        {formatLocale(thisYear, "y")}
                    </Button>
                );
            })}
        </div>
    );
}

function DateRangePickerYearMonths({
                                       onPickMonth,
                                       date,
                                       shownYear,
                                   }: DateRangePickerYearMonthsProps) {
    const currentMonth = getMonth(date[0]);

    return (
        <div className="year-months">
            {Utils.genArrayFromTo(0, 11, 1, true).map((month) => {
                const thisMonth = setMonth(shownYear, month);
                const isHighlighted = month === currentMonth;

                return (
                    <Button
                        className="month"
                        onClick={() => onPickMonth(thisMonth)}
                        buttonStyle={
                            isHighlighted
                                ? ButtonStyle.Blue
                                : ButtonStyle.TransparentBlack
                        }
                        key={month}
                    >
                        {formatLocale(thisMonth, "MMMM")}
                    </Button>
                );
            })}
        </div>
    );
}

interface DateRangePickerMonthDaysProps {
    date: Array<Date>;
    shownMonth: Date;
    selectingIndex: number;
    selection: Array<Date>;
    setSelection: (selection: Array<Date>) => void;
    setSelectingIndex: (num: number) => void;
    onDateChange: (index: number, newDate: Date) => void;
}

const DateRangePickerMonthDays = React.memo(function DateRangePickerMonthDays({
                                                                                  setSelectingIndex,
                                                                                  selectingIndex,
                                                                                  date,
                                                                                  onDateChange,
                                                                                  setSelection,
                                                                                  selection,
                                                                                  shownMonth,
                                                                              }: DateRangePickerMonthDaysProps) {
    let startDate = date[0];
    let endDate: Date | undefined = date[1];
    const now = new Date();

    const shownDate = shownMonth;

    const isSelecting = selectingIndex > 0;
    const selectionStart: Date | undefined = selection[0];
    const selectionEnd: Date | undefined = selection[1];

    const start = startOfMonth(shownDate);
    const end = endOfMonth(shownDate);

    const firstDay = getDay(start);
    const lastDay = getDay(end);

    const eachWeek = eachWeekOfInterval({
        start,
        end,
    });

    const onDayHover = useCallback(
        (today: Date) => {
            const newSelection = [...selection];
            newSelection[selectingIndex] = today;
            if (selectingIndex === 0) {
                newSelection[1] = today;
            }
            setSelection(newSelection);
        },
        [selection, setSelection, selectingIndex],
    );

    const onDayClick = useCallback(
        (today: Date) => {
            const corrToday = DateTime.now().setZone("utc")
                .set({
                    day: today.getDate(),
                    month: today.getMonth() + 1,
                    year: today.getFullYear(),
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                }).toJSDate();

            onDateChange(selectingIndex, corrToday);
            setSelectingIndex(selectingIndex + 1);
        },
        [onDateChange, selectingIndex, setSelectingIndex],
    );

    return (
        <div className="month-days">
            {eachWeek.map((week, weekIndex) => {
                return (
                    <div className="week" key={weekIndex}>
                        {Utils.genArrayFromTo(0, 6, 1, true).map((day) => {
                            // if it's the first week of the month
                            // check which days don't belong to this month
                            // same for last week of the month
                            const today = addDays(week, day);

                            const isThisDayCurrentDay = isSameDay(now, today);

                            const shouldRenderSpacer =
                                (firstDay > day && weekIndex === 0) ||
                                (weekIndex === eachWeek.length - 1 &&
                                    lastDay < day);

                            const isTodayBetweenStartAndEnd =
                                endDate !== undefined &&
                                ((isAfter(today, startDate) &&
                                        isBefore(today, endDate)) ||
                                    isSameDay(endDate, today));
                            const isHighlighted =
                                selectingIndex !== 1 &&
                                (isSameDay(startDate, today) ||
                                    isTodayBetweenStartAndEnd);

                            const isSelectionHighlighted =
                                isSelecting &&
                                selectionEnd !== undefined &&
                                ((isAfter(today, startDate) &&
                                        isBefore(today, selectionEnd)) ||
                                    isSameDay(selectionEnd, today) ||
                                    isSameDay(selectionStart, today));

                            // if you are selecting the To date, disable
                            // dates that come before the From date,
                            // you can't select a range from the future to the past
                            const isDisabled =
                                selectingIndex > 0 &&
                                isBefore(today, startDate);

                            if (!shouldRenderSpacer) {
                                return (
                                    <Button
                                        key={`${weekIndex}-${day}`}
                                        buttonStyle={
                                            isSelectionHighlighted
                                                ? ButtonStyle.LightBlue
                                                : isHighlighted
                                                    ? ButtonStyle.Blue
                                                    : isThisDayCurrentDay
                                                        ? ButtonStyle.LightBlue
                                                        : ButtonStyle.TransparentBlack
                                        }
                                        disabled={isDisabled}
                                        onClick={() => onDayClick(today)}
                                        onMouseEnter={() => onDayHover(today)}
                                        className="day"
                                    >
                                        {formatLocale(today, "d", {
                                            locale: dateFnsLocale,
                                        })}
                                    </Button>
                                );
                            } else {
                                return (
                                    <div
                                        className="day-spacer"
                                        key={`${weekIndex}-${day}`}
                                    />
                                );
                            }
                        })}
                    </div>
                );
            })}
        </div>
    );
});

interface DateRangePickerMoveHeaderProps {
    onPrevious: () => void;
    onNext: () => void;
    onMonthClick?: () => void;
    onYearClick?: () => void;
    shownDate: Date;
}

function DateRangePickerMoveHeader({
                                       onYearClick,
                                       onMonthClick,
                                       onPrevious,
                                       onNext,
                                       shownDate,
                                   }: DateRangePickerMoveHeaderProps) {
    return (
        <div className="move-header">
            <div className="left">
                <div className="shown-date">
                    <span onClick={onMonthClick}>
                        {formatLocale(shownDate, "MMMM")}
                    </span>
                    &nbsp;
                    <span onClick={onYearClick}>
                        {formatLocale(shownDate, "yyyy")}
                    </span>
                </div>
            </div>
            <div className="right">
                <GIcon onClick={onPrevious} icon="chevron_left"/>
                <GIcon onClick={onNext} icon="chevron_right"/>
            </div>
        </div>
    );
}

function DateRangePickerWeekdaysHeader() {
    return (
        <div className="weekday-names-header">
            {Utils.genArrayFromTo(0, 6, 1, true).map((day) => {
                let dayDate = new Date();
                dayDate = setDay(dayDate, day);

                return (
                    <div className="weekday-name" key={day}>
                        {formatLocale(dayDate, "EEEEEE")}
                    </div>
                );
            })}
        </div>
    );
}

function DateRangePicker<T extends HTMLElement>({
                                                    isOpen = false,
                                                    className,
                                                    style,
                                                    children,
                                                    mode = DatePickerMode.Day,
                                                    date,
                                                    onDateChange,
                                                    onOpenChange,
                                                    anchorRef,
                                                    noPicker = false,
                                                }: DateRangePickerProps<T>) {
    const [open, setOpen] = useState(isOpen);
    const [selection, setSelection] = useState(() => {
        return clone(date);
    });
    const [selectingIndex, setSelectingIndex] = useState<number>(0);
    const [shownDate, setShownDate] = useState<Date>(date[0]);
    const [currentMode, setCurrentMode] = useState<DatePickerMode>(mode);
    const [bottomAndLeft, setBottomAndLeft] = useState<{
        bottom: number;
        left: number;
    }>({ bottom: 0, left: 0 });
    const { width, height } = useWindowSize();
    const pickerRef = useRef<HTMLDivElement | null>(null);
    const numDates = date.length;

    const onSetOpen = useCallback(
        (newOpen: boolean) => {
            if (!newOpen) {
                setCurrentMode(mode);
            }

            setOpen(newOpen);
            if (onOpenChange) {
                onOpenChange(newOpen);
            }
        },
        [onOpenChange, mode],
    );

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const changeSelectingIndex = useCallback(
        (requestedIndex: number) => {
            if (requestedIndex < 0) {
                setSelectingIndex(0);
            } else if (requestedIndex > numDates - 1) {
                setSelectingIndex(0);
                setOpen(false);
            } else {
                setSelectingIndex(requestedIndex);
            }
        },
        [numDates],
    );

    const onPickDecadeYear = useCallback(
        (newDate: Date) => {
            if (mode === DatePickerMode.Year) {
                onDateChange(0, newDate);
                onSetOpen(false);
                return;
            }

            setShownDate(newDate);
            setCurrentMode(DatePickerMode.Month);
        },
        [mode, onDateChange, onSetOpen],
    );

    const onPickYearMonth = useCallback(
        (newDate: Date) => {
            if (mode === DatePickerMode.Month) {
                onDateChange(0, newDate);
                onSetOpen(false);
                return;
            }

            setShownDate(newDate);
            setCurrentMode(DatePickerMode.Day);
        },
        [mode, onDateChange, onSetOpen],
    );

    const onClickOutside = () => {
        onSetOpen(false);
    };

    useOnClickOutside(pickerRef, onClickOutside);

    const updatePickerPosition = useCallback(() => {
        if (open) {
            const anchorPosition =
                anchorRef.current?.getBoundingClientRect() ?? null;

            const pickerRect = pickerRef.current
                ?.querySelector(".picker")
                ?.getBoundingClientRect();

            if (anchorPosition && pickerRect) {
                let bottom = (anchorPosition?.bottom ?? -10000) + 8;
                let left = anchorPosition?.left ?? -10000;

                const pickerW = pickerRect.width;
                const pickerH = pickerRect.height;

                const leftAndWidth = left + pickerW;
                const bottomAndHeight = bottom + pickerH;

                if (left + pickerW > width) {
                    // we are overflowing width
                    const overflowAmount = width - leftAndWidth;
                    left += overflowAmount;
                }

                if (bottom + pickerH > height) {
                    // we are overflowing height
                    const overflowAmount = height - bottomAndHeight;
                    bottom += overflowAmount;
                }

                setBottomAndLeft({ bottom, left });
            }
        }
    }, [anchorRef, pickerRef, open, width, height]);

    useMutationObserver(pickerRef, updatePickerPosition, {
        attributes: true,
        attributeFilter: ["style"],
    });

    useEffect(updatePickerPosition, [updatePickerPosition]);

    return (
        <div
            onClick={() => onSetOpen(true)}
            className={classNames("date-range-picker", className)}
        >
            {children}
            <AnimatedHider ref={pickerRef} open={open && !noPicker}>
                <div
                    style={Object.assign(
                        {
                            top: bottomAndLeft.bottom,
                            left: bottomAndLeft.left,
                        },
                        style,
                    )}
                    className="picker"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    {currentMode === DatePickerMode.Day && (
                        <>
                            <DateRangePickerMoveHeader
                                shownDate={shownDate}
                                onMonthClick={() =>
                                    setCurrentMode(DatePickerMode.Month)
                                }
                                onYearClick={() =>
                                    setCurrentMode(DatePickerMode.Year)
                                }
                                onPrevious={() => {
                                    setShownDate(subMonths(shownDate, 1));
                                }}
                                onNext={() => {
                                    setShownDate(addMonths(shownDate, 1));
                                }}
                            />
                            <DateRangePickerWeekdaysHeader/>
                            <DateRangePickerMonthDays
                                shownMonth={shownDate}
                                selectingIndex={selectingIndex}
                                setSelectingIndex={changeSelectingIndex}
                                selection={selection}
                                setSelection={setSelection}
                                onDateChange={onDateChange}
                                date={date}
                            />
                        </>
                    )}
                    {currentMode === DatePickerMode.Month && (
                        <>
                            <DateRangePickerMoveHeader
                                shownDate={shownDate}
                                onMonthClick={() =>
                                    setCurrentMode(DatePickerMode.Month)
                                }
                                onYearClick={() =>
                                    setCurrentMode(DatePickerMode.Year)
                                }
                                onPrevious={() => {
                                    setShownDate(subYears(shownDate, 1));
                                }}
                                onNext={() => {
                                    setShownDate(addYears(shownDate, 1));
                                }}
                            />
                            <DateRangePickerYearMonths
                                onPickMonth={onPickYearMonth}
                                date={date}
                                shownYear={shownDate}
                            />
                        </>
                    )}
                    {currentMode === DatePickerMode.Year && (
                        <>
                            <DateRangePickerMoveHeader
                                shownDate={shownDate}
                                onMonthClick={() =>
                                    setCurrentMode(DatePickerMode.Month)
                                }
                                onYearClick={() =>
                                    setCurrentMode(DatePickerMode.Year)
                                }
                                onPrevious={() => {
                                    setShownDate(subYears(shownDate, 10));
                                }}
                                onNext={() => {
                                    setShownDate(addYears(shownDate, 10));
                                }}
                            />
                            <DateRangePickerDecadeYears
                                onPickYear={onPickDecadeYear}
                                date={date}
                                shownDecade={shownDate}
                            />
                        </>
                    )}
                </div>
            </AnimatedHider>
        </div>
    );
}

export default DateRangePicker;
