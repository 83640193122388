import "./message_bar_attachments.scss";
import { useAppSelector } from "../../../../../store/hooks";
import MessageBarAttachment from "./MessageBarAttachment";
import { messengerAttachmentsSelectorForEntity } from "../../../../../store/slices/messengerSlice";

export interface MessageBarAttachmentsProps {
    leadId: string,
}

function MessageBarAttachments({ leadId, ...rest }: MessageBarAttachmentsProps) {
    const attachments = useAppSelector(state => messengerAttachmentsSelectorForEntity(state, leadId)) ?? [];

    if (attachments.length === 0) {
        return null;
    }

    return (
        <div className="message-bar-attachments">
            {attachments.map((attachment, index) => {
                return (
                    <MessageBarAttachment key={index}
                                          index={index}
                                          leadId={leadId}
                                          attachment={attachment}/>
                );
            })}
        </div>
    );
}

export default MessageBarAttachments;
