import React, { useCallback } from 'react';
import './manage_topic.scss';
import { useAppSelector } from 'src/store/hooks';
import { Topic } from 'src/entities/Topic/Topic';
import { IconArchive, IconEdit } from '@tabler/icons';
import ColorfulIconButton from '../../../../components/Ui/ColorfulIconButton/ColorfulIconButton';
import classNames from 'classnames';
import { topicsAsyncActions, topicSelectors } from 'src/store/slices/topicsSlice';
import { useTranslation } from 'react-i18next';
import { FormDisplayMode } from 'src/utils/type_utils';
import { Box, Tab, Tabs } from '@mui/material';
import TopicGeneralInfoTab from './Tabs/TopicGeneralInfoTab';
import TopicPredefinedResponsesTab from './Tabs/TopicPredefinedResponsesTab';
import { useDispatch } from 'react-redux';

interface ManageTopicProps {
    onClose: () => void,
    formDisplayMode: FormDisplayMode,
    setDisplayMode: (mode: FormDisplayMode) => void,
    onUnarchiveClick?: (id: string) => void
}

function ManageTopic({ onClose, formDisplayMode, setDisplayMode, onUnarchiveClick }: ManageTopicProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [tab, setTab] = React.useState(0);

    const isArchived = onUnarchiveClick;

    const isEntityEditMode = formDisplayMode === FormDisplayMode.Edit || formDisplayMode === FormDisplayMode.View;
    const isViewMode = formDisplayMode === FormDisplayMode.View;

    const selectedTopic: Topic | null = useAppSelector(topicSelectors.selectedTopic);

    const toggleReadonlyMode = useCallback(() => {
        if (isViewMode) {
            setDisplayMode(FormDisplayMode.Edit);
        } else {
            setDisplayMode(FormDisplayMode.View);
        }
    }, [isViewMode, setDisplayMode]);

    const handleArchiveAction = () => {
        if (!selectedTopic) {
            return;
        }
        if (!window.confirm('Da li ste sigurni?')) {
            return;
        }
        dispatch(topicsAsyncActions.deleteEntity(selectedTopic.id));
    };

    return (
        <div
            className={classNames('manage-entity-common manage-topic', isViewMode && 'readonly')}>
            <div className="header">
                <span
                    className="header-title">{isViewMode ? t('topic_information') : (isEntityEditMode ? t('editing_topic') : t('adding_topic'))}</span>
                {isEntityEditMode && (
                    <div className={'header-btn-container'}>
                        {
                            isArchived ? (
                                <ColorfulIconButton
                                    onClick={() => selectedTopic && onUnarchiveClick(selectedTopic.id)}
                                    data-tip={t('unarchive')}>
                                    <IconArchive/>
                                </ColorfulIconButton>
                            ) : (
                                <>
                                    {isViewMode && <ColorfulIconButton onClick={toggleReadonlyMode}
                                                                       data-tip={t('edit')}>
                                        <IconEdit/>
                                    </ColorfulIconButton>
                                    }
                                    {isEntityEditMode && (
                                        <ColorfulIconButton
                                            onClick={() => handleArchiveAction()}
                                            data-tip={t('archive')}>
                                            <IconArchive/>
                                        </ColorfulIconButton>
                                    )}
                                </>
                            )
                        }
                    </div>
                )}
            </div>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
                        <Tab label="Generalno"/>
                        <Tab label="Predefinisani odgovori"/>
                    </Tabs>
                </Box>
                {tab === 0 && <TopicGeneralInfoTab onClose={onClose} formDisplayMode={formDisplayMode}
                                                   setDisplayMode={setDisplayMode}/>}

                {tab === 1 && selectedTopic !== null && <TopicPredefinedResponsesTab topicId={selectedTopic.id}/>}
            </Box>
        </div>
    );
}

export default ManageTopic;
