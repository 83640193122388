import { AsyncAction, BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { APIEntity } from "src/api/api";
import { RootState } from "../store";
import { createSelector, PayloadAction } from "@reduxjs/toolkit";
import {
    CreateMessageTemplate,
    IMessageTemplate,
} from "src/entities/messageTemplateCategory/IMessageTemplateCategory";
import { messageTemplateCategoriesActions } from "./messageTemplateCategoriesSlice";
import { MessageTemplateForm } from "../../entities/messageTemplateCategory/MessageTemplateCategoryForm";
import { MediaObjectApi } from "../../api/mediaObject";
import { ReadFile } from "../../utils/utils";
import { cloneDeep } from "lodash";

interface MessageTemplatesState extends BaseEntityReducerState<IMessageTemplate> {
    editingId: string | null,
}

const initialState: MessageTemplatesState = {
    entities: {},
    perPage: 15,
    currentPage: 1,
    editingId: null,
    fetchAsync: false,
    lastPage: 1,
    selectedEntities: [],
    totalItems: 0,
    hasMore: true,
    addEditAsync: false,
    deleteAsync: false,
    searchFields: [],
};

const slice = createEntityReducer({} as IMessageTemplate, APIEntity.MessageTemplates, initialState, {
    setEditingId: (state, { payload }: PayloadAction<string | null>) => {
        state.editingId = payload;
    },
}, undefined, {} as MessageTemplateForm);

function createMessageTemplate(form: MessageTemplateForm, attachment: ReadFile | null): AsyncAction {
    return async (dispatch) => {
        const clonedForm = cloneDeep(form);

        if (attachment) {
            const r = await MediaObjectApi.createMediaObject(attachment).catch(() => null);
            if (r) {
                clonedForm.attachment = r.id;
            }
        }

        dispatch(messageTemplatesAsyncActions.createEntity(clonedForm));
    };
}


function updateMessageTemplate(id: string, form: MessageTemplateForm, attachment: ReadFile | null): AsyncAction {
    return async (dispatch) => {
        const clonedForm = cloneDeep(form);

        if (attachment) {
            const r = await MediaObjectApi.createMediaObject(attachment).catch(() => null);
            if (r) {
                clonedForm.attachment = r.id;
            }
        }

        dispatch(messageTemplatesAsyncActions.updateEntityAction({
            entityId: id,
            entityForm: clonedForm,
        }));
    };
}

const messageTemplatesListSelector = (state: RootState) => state.messageTemplates.entities;

const selectedTemplatesSelector = (state: RootState) => state.messageTemplates.selectedEntities;

export const messageTemplatesList = createSelector([messageTemplatesListSelector], (entities) => {
    return Object.values(entities);
});

export const selectedMessageTemplatesList = createSelector([messageTemplatesList, selectedTemplatesSelector], (list, selected) => {
    return list.filter(ent => selected.includes(ent.id));
});

export function addNewMessageTemplate(form: CreateMessageTemplate): AsyncAction<Promise<void>> {
    return async (dispatch) => {
        const r = await dispatch(messageTemplatesAsyncActions.createEntity(form));
        if (r !== null) {
            dispatch(messageTemplateCategoriesActions.addMessageTemplateForCategory(r));
        }
    };
}

export function deleteMessageTemplate(template: IMessageTemplate): AsyncAction<Promise<void>> {
    return async (dispatch) => {
        await dispatch(messageTemplatesAsyncActions.deleteEntity(template.id));
        dispatch(messageTemplateCategoriesActions.removeMessageTemplateFromCategory(template));
    };
}

const messageTemplateCategoryMap = createSelector([messageTemplatesList], (templates) => {
    const tempMessageTemplateCategoryMap: Record<string, IMessageTemplate[]> = {};

    templates.forEach(template => {
        if (!tempMessageTemplateCategoryMap[template.category.id]) {
            tempMessageTemplateCategoryMap[template.category.id] = [];
        }

        tempMessageTemplateCategoryMap[template.category.id].push(template);
    });
    return tempMessageTemplateCategoryMap;
});

export const { slice: messageTemplatesSlice, asyncActions } = slice;

export const messageTemplatesAsyncActions = {
    ...asyncActions,
    addNewMessageTemplate,
    deleteMessageTemplate,
    createMessageTemplate,
    updateMessageTemplate,
};

export const messageTemplatesSelectors = {
    messageTemplatesList,
    selectedTemplates: selectedTemplatesSelector,
    messageTemplateCategoryMap,
    selectedMessageTemplatesList,
};

export const messageTemplatesActions = messageTemplatesSlice.actions;
export const messageTemplatesReducer = messageTemplatesSlice.reducer;
