import React, { useCallback, useEffect, useRef, useState } from "react";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import "./header.scss";
import Button, { ButtonStyle } from "src/components/Ui/Button/Button";
import { useTranslation } from "react-i18next";
import { changeDateFnsLocaleBasedOnLang } from "src/utils/utils";
import { StorageKeys } from "src/utils/storage_keys";
import Dropdown, { DropdownAnchorEdge } from "../Dropdown/Dropdown";
import DropdownItem from "../Dropdown/DropdownItem";
import { API } from "../../../api/api";
import ImpersonationButton from "./ImpersonationModal/ImpersonationButton/ImpersonationButton";
import ImpersonationModal from "./ImpersonationModal/ImpersonationModal";
import { useAppSelector } from "../../../store/hooks";
import { loginSelectors } from "../../../store/slices/loginSlice";
import { UserUtils } from "../../../entities/User/User";

const Header = () => {
    const { i18n, t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [impersonationModalOpen, setImpersonationModalOpen] = useState(false);
    const user = useAppSelector(loginSelectors.user);
    const dropdownAnchorRef = useRef<HTMLDivElement>(null);

    const isSuperAdmin = user && UserUtils.isSuperAdmin(user);

    const changeLanguage = useCallback(() => {
        const nextLang = i18n.language === "en" ? "bs" : "en";

        localStorage.setItem(StorageKeys.Language, nextLang);
        i18n.changeLanguage(nextLang);
        changeDateFnsLocaleBasedOnLang(nextLang);
    }, [i18n]);

    useEffect(() => {
        // const lang = localStorage.getItem(StorageKeys.Language) ?? "bs";
        // i18n.changeLanguage(lang);
        // changeDateFnsLocaleBasedOnLang(lang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleDropdown = useCallback(() => {
        setDropdownOpen(!dropdownOpen);
    }, [dropdownOpen]);

    return (
        <div className="header-container">
            <div className="main-header">
                <div className="left">
                    {!isSuperAdmin && (
                        <div>{user?.topic?.name}</div>
                    )}
                    <Breadcrumbs/>
                </div>
                <div className="right">
                    {/*<Button*/}
                    {/*    className="language-button"*/}
                    {/*    buttonStyle={ButtonStyle.LightBlue}*/}
                    {/*    onClick={changeLanguage}*/}
                    {/*    style={{ marginRight: "10px" }}*/}
                    {/*>*/}
                    {/*    {i18n.language === "en" ? "Bs" : "En"}*/}
                    {/*</Button>*/}


                    <div ref={dropdownAnchorRef}>
                        <ImpersonationButton
                            onClick={toggleDropdown}
                            dropdownOpen={dropdownOpen}/>
                    </div>
                    <Dropdown innerClassName="user-dropdown"
                              anchorEdge={DropdownAnchorEdge.BottomRight}
                              open={dropdownOpen}
                              setOpen={setDropdownOpen}
                              anchorRef={dropdownAnchorRef}>
                        {isSuperAdmin && (
                            <DropdownItem className="impersonate-button"
                                          onClick={() => {
                                              setImpersonationModalOpen(true);
                                          }}>
                                {t("impersonate")}
                            </DropdownItem>
                        )}
                        <DropdownItem className="logout"
                                      onClick={() => {
                                          API.logOut();
                                      }}>
                            {t("logout")}
                        </DropdownItem>
                    </Dropdown>
                </div>
            </div>
            {isSuperAdmin && (
                <ImpersonationModal open={impersonationModalOpen}
                                    setOpen={setImpersonationModalOpen}/>
            )}
        </div>
    );
};

export default Header;
