import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RawHydraResponse, RawHydraResponseIntoPaginationResponse } from "src/api/paginationResponse";
import {
    Message,
    MessageWithLeadId,
} from "src/entities/message/Message";
import { AsyncAction } from "../helpers/entityReducer";
import { PayloadWithChannelEntityId, SocketActions } from "../helpers/socketActions";
import { RootState } from "../store";
import { ReadFile } from "../../utils/utils";
import i18next from "i18next";
import { SwalUtils } from "../../utils/swal_utils";
import { MessagesApi, SendMessageFail } from "../../api/messages";
import { LeadUtils } from "../../entities/Lead/Lead";

export enum FetchMessagesRet {
    Success,
    FetchedAll,
    Err,
}

export enum SendMessageRet {
    Success,
    Error,
}

let fetchMessagePageAbortControllers: Record<string, AbortController> = {};

export const messagesSelector = (state: RootState) => state.messenger.messages;

export const messagesFetchAsyncSelector = (state: RootState) =>
    state.messenger.fetchAsync;

export const messengerAttachmentsSelectorForEntity = (
    state: RootState,
    id: string,
) => state.messenger.attachments[id];

interface MessengerState {
    attachments: Record<string, ReadFile[]>;
    messages: Message[];
    perPage: number;
    fetchAsync: boolean;
    fetchMoreAsync: boolean;
    lastPage: number;
    hasMore: boolean;
    clientId: string | null;
    sendAsync: boolean;
    messageText: Record<string, string>;
}

const initialState: MessengerState = {
    attachments: {},
    messages: [],
    perPage: 15,
    fetchAsync: false,
    fetchMoreAsync: false,
    lastPage: 1,
    hasMore: true,
    clientId: null,
    sendAsync: false,
    messageText: {},
};

export interface SuccessFetchMessagePageAction {
    messages: Message[];
    hasMore: boolean;
    clientId: string,
}

export const messengerSlice = createSlice({
    name: "messenger",
    initialState,
    reducers: {
        setSendAsync(state, { payload: async }: PayloadAction<boolean>) {
            state.sendAsync = async;
        },
        setFetchAsync(state, { payload: async }: PayloadAction<boolean>) {
            state.fetchAsync = async;
        },
        setFetchMoreAsync(state, { payload: async }: PayloadAction<boolean>) {
            state.fetchMoreAsync = async;
        },
        resetState(state) {
            return {
                ...initialState,
                messageText: state.messageText,
                attachments: state.attachments,
            };
        },
        setClientId(
            state,
            { payload: clientId }: PayloadAction<string>,
        ) {
            state.clientId = clientId;
            if (state.attachments[clientId] === undefined) {
                state.attachments[clientId] = [];
            }

            if (state.messageText[clientId] === undefined) {
                state.messageText[clientId] = "";
            }
        },
        setAttachments(
            state,
            {
                payload: { files, id },
            }: PayloadAction<{ files: ReadFile[]; id: string }>,
        ) {
            state.attachments[id] = files;
        },
        removeAttachment(
            state,
            {
                payload: { index, id },
            }: PayloadAction<{ index: number; id: string }>,
        ) {
            const entityAttachments = state.attachments[id];
            if (entityAttachments) {
                if (
                    index !== -1 &&
                    index < entityAttachments.length &&
                    index >= 0
                ) {
                    state.attachments[id].splice(index, 1);
                }
            }
        },
        addAttachment(
            state,
            {
                payload: { file, id },
            }: PayloadAction<{ file: ReadFile; id: string }>,
        ) {
            if (state.attachments[id]) {
                state.attachments[id].push(file);
            } else {
                state.attachments[id] = [file];
            }
        },
        addMessage(state, { payload: message }: PayloadAction<Message>) {
            if (!state.messages.find((msg) => msg.id === message.id)) {
                state.messages.push(message);
            }
        },
        successFetchMessagePage(
            state,
            {
                payload: { hasMore, messages, clientId },
            }: PayloadAction<SuccessFetchMessagePageAction>,
        ) {
            if (clientId === state.clientId) {
                state.messages.unshift(...messages);
                state.hasMore = hasMore;
            }
        },
        setMessageText(
            state,
            {
                payload: { id, text },
            }: PayloadAction<{ text: string; id: string }>,
        ) {
            state.messageText[id] = text;
        },
        appendMessageText(
            state,
            {
                payload: { id, text },
            }: PayloadAction<{ text: string; id: string }>,
        ) {
            if (state.messageText[id]) {
                state.messageText[id] += " " + text;
            } else {
                state.messageText[id] = text;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                SocketActions.CompanyInboundMessage,
                (state, { payload: { payload: message } }) => {
                    if (message.lead.id === state.clientId) {
                        state.messages.push(message);
                    }
                },
            )
            .addCase(
                SocketActions.CompanyMessageDeliveryStatusUpdated,
                (state, { payload: { payload: { lead, deliveryStatus } } }) => {
                    if (lead === state.clientId) {
                        for (let message of state.messages) {
                            if (message.deliveryStatus !== deliveryStatus) {
                                message.deliveryStatus = deliveryStatus;
                            }
                        }
                    }
                },
            );
    },
});

function handleOutboundMessageEvent(
    payload: PayloadWithChannelEntityId<MessageWithLeadId>,
): AsyncAction {
    return async (dispatch, getState) => {
        let clientId = getState().messenger.clientId;
        const { payload: message } = payload;

        if (clientId && clientId === message.lead.id) {
            dispatch(messengerActions.addMessage(message));
        }
    };
}

export function sendMessage(
    clientId: string,
    text: string,
    { viberId, smsNumber }: { viberId: string | null; smsNumber: string | null },
): AsyncAction<Promise<SendMessageRet>> {
    return async (dispatch, getState) => {
        dispatch(messengerSlice.actions.setSendAsync(true));

        const attachments =
            getState().messenger.attachments[clientId] ?? [];

        let channel = LeadUtils.getChannel({ viberId, smsNumber });

        if (!channel) {
            SwalUtils.showErrorSwalToast(i18next.t("communication_doesnt_support_any_supported_channels"));
            dispatch(messengerSlice.actions.setSendAsync(false));
            return SendMessageRet.Error;
        }

        const r: false | Message | null = await MessagesApi.sendMessage(
            clientId,
            text,
            attachments,
            channel,
        )
            .then((r) => {
                if (r === SendMessageFail.FileSizeTooBig) {
                    SwalUtils.showErrorSwalToast(
                        i18next.t("err_total_attachment_size"),
                    );
                    return false;
                }

                if (r) {
                    return r.json();
                } else {
                    return false;
                }
            })
            .catch((res) => {
                if (res.response && res.response.status === 413) {
                    SwalUtils.showErrorSwalToast(
                        i18next.t("err_total_attachment_size"),
                    );
                }

                return false;
            });

        if (!r) {
            dispatch(messengerSlice.actions.setSendAsync(false));
            return SendMessageRet.Error;
        } else {
            dispatch(messengerSlice.actions.addMessage(r));
            dispatch(
                messengerSlice.actions.setAttachments({
                    files: [],
                    id: clientId,
                }),
            );
            dispatch(messengerSlice.actions.setSendAsync(false));
            dispatch(
                messengerActions.setMessageText({
                    id: clientId,
                    text: "",
                }),
            );
        }

        return SendMessageRet.Success;
    };
}

export function fetchMessagesPage(
    clientId: string,
): AsyncAction<Promise<FetchMessagesRet>> {
    return async (dispatch, getState) => {
        const {
            messenger: { clientId: stateClientId },
        } = getState();

        let asyncAction = messengerSlice.actions.setFetchMoreAsync;

        Object.entries(fetchMessagePageAbortControllers).forEach(([key, controller]) => {
            if (controller) {
                controller.abort();
            }
        });

        if (stateClientId !== clientId) {
            asyncAction = messengerSlice.actions.setFetchAsync;

            dispatch(messengerSlice.actions.resetState());
            dispatch(
                messengerSlice.actions.setClientId(clientId),
            );
        }

        const {
            messenger: { perPage, messages, hasMore },
        } = getState();

        if (hasMore) {
            dispatch(asyncAction(true));

            const lastMessageDate =
                messages[0]?.createdAt ?? new Date().toISOString();

            const abortControllerKey = `${clientId}-${lastMessageDate}`;

            const abortController = new AbortController();

            fetchMessagePageAbortControllers[abortControllerKey] = abortController;

            // typedef this since TS will resolve it to
            // boolean | null | Message[]
            // but this can never return true since we return
            // a static from the catch
            // if we don't do this below we would have to check if r === true or r === Message[]
            const r: false | null | RawHydraResponse<Message> =
                await MessagesApi.fetchMessages(
                    clientId,
                    lastMessageDate,
                    perPage,
                    abortController.signal,
                ).catch(() => false);

            delete fetchMessagePageAbortControllers[abortControllerKey];

            if (!r) {
                dispatch(asyncAction(false));
                return FetchMessagesRet.Err;
            }

            const { hasMore, data: newMessages } =
                RawHydraResponseIntoPaginationResponse<Message>(r);

            dispatch(
                messengerSlice.actions.successFetchMessagePage({
                    hasMore,
                    messages: newMessages.reverse(),
                    clientId,
                }),
            );

            dispatch(asyncAction(false));

            return FetchMessagesRet.Success;
        }

        return FetchMessagesRet.FetchedAll;
    };
}

export const messengerActions = messengerSlice.actions;
export const messengerAsyncActions = {
    fetchMessagesPage,
    sendMessage,
    handleOutboundMessageEvent,
};
export const messengerReducer = messengerSlice.reducer;
