import classNames from "classnames";
import * as React from "react";
import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import "./button.scss";
import Spinner from "../Spinner/Spinner";

export enum ButtonStyle {
    LightBlue = "lightblue",
    TransparentBlack = "transparent-black",
    Blue = "blue",
    Red = "red",
    Orange = "orange",
    Text = "text",
    White50 = "white50"
}

export enum ButtonType {
    Button = "button",
    Submit = "submit",
}

export interface ButtonProps {
    readonly onClick?: MouseEventHandler<HTMLButtonElement>,
    readonly onMouseEnter?: MouseEventHandler<HTMLButtonElement>,
    readonly onMouseLeave?: MouseEventHandler<HTMLButtonElement>,
    readonly children?: ReactNode,
    readonly buttonStyle?: ButtonStyle,
    readonly style?: CSSProperties,
    readonly type?: ButtonType,
    readonly className?: string,
    readonly inversed?: boolean,
    readonly active?: boolean,
    readonly loading?: boolean,
    readonly disabled?: boolean,
    readonly spinnerColor?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
                                                                     onClick,
                                                                     onMouseEnter,
                                                                     onMouseLeave,
                                                                     children,
                                                                     style = {},
                                                                     className,
                                                                     buttonStyle = ButtonStyle.Blue,
                                                                     type = ButtonType.Button,
                                                                     inversed = false,
                                                                     loading = false,
                                                                     disabled = false,
                                                                     active = false,
                                                                     spinnerColor
                                                                 }: ButtonProps, ref) => {
    return (
        <button className={classNames("mpdz", className, buttonStyle, inversed && "inversed", active && "active")}
                style={style}
                ref={ref}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                type={type}
                disabled={disabled || loading}>
            {children}
            {loading && <Spinner invert={!inversed} size={14} color={spinnerColor}/>}
        </button>
    );
});

export default Button;
