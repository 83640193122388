import { Router } from './router/router';
import './i18n/config';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router';
import Communication from './pages/Communication/Communication';
import Login from './pages/Login/Login';
import Spinner from './components/Ui/Spinner/Spinner';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { useEffect, useState } from 'react';
import { useSocket } from './components/SocketIOProvider/SocketIOProvider';
import { loginAsyncActions } from './store/slices/loginSlice';
import { StorageKeys } from './utils/storage_keys';
import Header from './components/Ui/Header/Header';
import { pageAnimationVariants } from './components/Ui/AnimatedHider/AnimatedHider';
import { AnimatePresence, motion } from 'framer-motion';
import Menu from './components/Ui/Menu/Menu';
import Topics from './pages/Topics/Topics';
import Questionnaire from './pages/Questionnaire/Questionnaire';
import Users from './pages/Users/Users';
import Reports from './pages/Reports/Reports';
import { DISABLE_SOCKET_IO } from './utils/consts';
import ViberKeyboards from './pages/ViberKeyboards/ViberKeyboards';

function App() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const user = useAppSelector((state) => state.login.user);
    const [async, setAsync] = useState(true);
    const socket = useSocket();

    const renderLogin = !Boolean(user);

    useEffect(() => {
        const token = localStorage.getItem(StorageKeys.Token);

        if (user) {
            if (token && !DISABLE_SOCKET_IO) {
                socket.connect();
            }
            setAsync(false);
            return;
        }

        if (!token) {
            setAsync(false);
        }

        if (token && !user) {
            if (token && !DISABLE_SOCKET_IO) {
                socket.connect();
            }

            dispatch(loginAsyncActions.getMe());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user]);

    if (async) {
        return (
            <div className="App">
                <div className="app-spinner-container">
                    <Spinner/>
                </div>
            </div>
        );
    }

    if (renderLogin) {
        return <Login/>;
    }


    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageAnimationVariants}
            className="App">
            <Menu/>
            <div className="app-content-menu-container">
                <Header/>
                <div className="app-content">
                    <AnimatePresence exitBeforeEnter initial={false}>
                        <Routes location={location} key={location.pathname}>
                            <Route path={'/'} element={<Communication/>}/>
                            <Route path={Router.RoutePaths.Communication} element={<Communication/>}/>
                            <Route path={Router.RoutePaths.Topics} element={<Topics/>}/>
                            <Route path={Router.RoutePaths.Reports} element={<Reports/>}/>
                            <Route path={Router.RoutePaths.Questionnaires} element={<Questionnaire/>}/>
                            <Route path={Router.RoutePaths.Login} element={<Login/>}/>
                            <Route path={Router.RoutePaths.Users} element={<Users/>}/>
                            <Route path={Router.RoutePaths.ViberKeyboards} element={<ViberKeyboards/>}/>
                            <Route
                                path={'*'}
                                element={<div>Ruta nije pronađena</div>}
                            />
                        </Routes>
                    </AnimatePresence>
                </div>
            </div>
            <div id="modal-node"/>
        </motion.div>
    );
}

export default App;
