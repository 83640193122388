import { Utils } from "src/utils/utils";
import { SelectFieldOption } from "../../components/Ui/SelectField/SelectField";

export enum UserRole {
    ROLE_USER = "ROLE_USER",
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
}

export interface User {
    id: string,
    email: string,
    firstName: string,
    lastName: string | null,
    phone: string | null,
    internalId: string | null,
    roles: Array<UserRole>,
    isArchived: boolean,
    position?: string,
    topic?: UserTopic
    // awayState: AwayState,
}

export interface UserTopic {
    id: string,
    createdAt: string,
    name: string,
    ocsSmsNumber: string | null,
    ocsViberToken: string | null,
}

export interface UsersQuery {
    roles?: string;
    search?: string;
}

export class UserUtils {

    public static userRoleOptions: SelectFieldOption[] = [
        {
            label: "User",
            value: UserRole.ROLE_USER,
        },
        {
            label: "Admin",
            value: UserRole.ROLE_ADMIN,
        },
        {
            label: "Super Admin",
            value: UserRole.ROLE_SUPER_ADMIN,
        },
    ];

    public static mergeName = (user: User): string => {
        return [user.firstName, user.lastName].filter(part => part).join(" ");
    };

    public static isUser(user: User) {
        return user.roles.includes(UserRole.ROLE_USER);
    }

    public static isAdmin(user: User) {
        return user.roles.includes(UserRole.ROLE_ADMIN);
    }

    public static isSuperAdmin(user: User) {
        return user.roles.includes(UserRole.ROLE_SUPER_ADMIN);
    }

    public static getUserRole = (user: User): UserRole => {
        if (this.isSuperAdmin(user)) return UserRole.ROLE_SUPER_ADMIN;
        if (this.isAdmin(user)) return UserRole.ROLE_ADMIN;
        return UserRole.ROLE_USER;
    };

    public static isGrantedSuperAdminLevel(user: User): boolean {
        return [UserRole.ROLE_SUPER_ADMIN].includes(UserUtils.getUserRole(user));
    }

    public static getUserRoleText(user: User): string {
        const userRole = this.getUserRole(user);
        return this.userRoleOptions.find(o => o.value === userRole)?.label ?? "";
    };

    public static searchUsers(users: User[], searchVal: string): User[] {
        return users.filter(u => Utils.searchByName(u, searchVal));
    }

    // public static transformUserToForm = (User: User): IUserForm => {
    //     const userForm: IUserForm = cloneDeep(pick(User, UserUtils.getUserFormKeys()));
    //     userForm.user_role = UserUtils.getUserRole(User.roles);
    //     return userForm;
    // }
}
