export const IS_DEV = process.env.NODE_ENV === "development";
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const DISABLE_SOCKET_IO = process.env.REACT_APP_DISABLE_SOCKET_IO === "true";

export const ACCEPT_ATTACHMENTS_STR = ".jpg, .png, .jpeg, .pdf, .doc, .docx, video/mp4, video/quicktime";

export const ACCEPT_ATTACHMENTS = {
    "image/png": ['.png'],
    "image/jpeg": [".jpg", ".jpeg"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "video/mp4": [".mp4"],
    "video/quicktime": [".mov"],
};

export const ACCEPT_ATTACHMENTS_REDUCED = {
    "image/png": ['.png'],
    "image/jpeg": [".jpg", ".jpeg"],
    "application/pdf": [".pdf"],
    "video/mp4": [".mp4"],
};

export enum Errors {
    AGENT_MUST_BE_ASSIGNED_TO_HEALTH_CENTER_FOR_SMS_COMMUNICATION = "AGENT_MUST_BE_ASSIGNED_TO_HEALTH_CENTER_FOR_SMS_COMMUNICATION",
}
