import { ReadFile } from "../utils";
import { useCallback, useState } from "react";
import { cloneDeep } from "lodash";

type UseMediaUploader = {
    attachments: ReadFile[],
    addAttachment: (att: ReadFile) => void,
    removeAttachment: (idx: number) => void,
    setAttachments: (att: ReadFile[]) => void,
}

function useMediaUploader(initial: ReadFile[] = []): UseMediaUploader {
    const [attachments, setAttachments] = useState<ReadFile[]>(initial);

    const addAttachment = useCallback((attachment: ReadFile) => {
        setAttachments(prevAttachments => {
            const clone = cloneDeep(prevAttachments);
            clone.push(attachment);
            return clone;
        });
    }, []);

    const removeAttachment = useCallback((idx: number) => {
        const clone = cloneDeep(attachments);
        clone.splice(idx, 1);
        setAttachments(clone);
    }, [attachments]);

    return {attachments, addAttachment, removeAttachment, setAttachments};
}

export default useMediaUploader;
