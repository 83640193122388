import InputField from '../../../../../components/Ui/InputField/InputField';
import TextareaField from '../../../../../components/Ui/TextareaField/TextareaField';
import Checkbox from '../../../../../components/Ui/Checkbox/Checkbox';
import AsyncContainer from '../../../../../components/Ui/AsyncContainer/AsyncContainer';
import SelectField from '../../../../../components/Ui/SelectField/SelectField';
import { cloneDeep, isEmpty } from 'lodash';
import Button, { ButtonStyle } from '../../../../../components/Ui/Button/Button';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import useForm from '../../../../../utils/hooks/useForm';
import { ITopicForm, ITopicFormErrors, TopicFormUtils } from '../../../../../entities/Topic/TopicForm';
import { FormDisplayMode } from '../../../../../utils/type_utils';
import { questionnairesAsyncActions, questionnairesSelectors } from '../../../../../store/slices/questionnariesSlice';
import { Topic } from '../../../../../entities/Topic/Topic';
import { topicsAsyncActions, topicSelectors } from '../../../../../store/slices/topicsSlice';
import { SwalUtils } from '../../../../../utils/swal_utils';

interface TopicGeneralInfoTabProps {
    onClose: () => void,
    formDisplayMode: FormDisplayMode,
    setDisplayMode: (mode: FormDisplayMode) => void,
    onUnarchiveClick?: (id: string) => void
}

export default function TopicGeneralInfoTab({ formDisplayMode, setDisplayMode, onClose }: TopicGeneralInfoTabProps) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { form, updateForm, getFormDiff, setForm, setOriginalForm } = useForm<ITopicForm>({});
    const [formErrors, setFormErrors] = useState<ITopicFormErrors>({});

    const isEntityEditMode = formDisplayMode === FormDisplayMode.Edit || formDisplayMode === FormDisplayMode.View;
    const isViewMode = formDisplayMode === FormDisplayMode.View;
    const questionnaireOptions = useAppSelector(questionnairesSelectors.questionnaireOptions);
    const questionnaireAsync = useAppSelector(state => state.questionnaires.fetchAsync);

    const selectedQuestionnaireOption = useMemo(() => {
        return questionnaireOptions.find(o => o.value === form.welcomeQuestionnaire) ?? null;
    }, [form.welcomeQuestionnaire, questionnaireOptions]);

    const selectedTopic: Topic | null = useAppSelector(topicSelectors.selectedTopic);

    useEffect(() => {
        if (selectedTopic) {
            const originalForm = TopicFormUtils.createForm(selectedTopic);
            setOriginalForm(originalForm);
            setForm(cloneDeep(originalForm));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTopic]);

    useEffect(() => {
        if (questionnaireOptions.length === 0) {
            dispatch(questionnairesAsyncActions.fetchAllEntities());
        }
    }, []);

    const async = useAppSelector(state => {
        const { addEditAsync, deleteAsync } = state.topics;
        return addEditAsync || deleteAsync;
    });

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const field = e.target.name as keyof ITopicForm;
        updateForm({ [field]: e.target.value });
    }, [updateForm]);

    const isFormValid = useCallback((form: ITopicForm): boolean => {
        const errors = TopicFormUtils.validate(form);
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }, [formDisplayMode]);

    const onSubmit = useCallback(async () => {
        setFormErrors({});

        const errors = TopicFormUtils.validate(form);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (isEntityEditMode && selectedTopic) {
            const diff = getFormDiff();

            if (isEmpty(diff)) {
                SwalUtils.showWarningSwalToast(t('no_changes'));
                return;
            }

            if (!isFormValid(diff)) {
                return;
            }

            if (form.welcomeQuestionnaire === null) {
                diff.welcomeQuestionnaire = null;
            }

            const r = await dispatch(topicsAsyncActions.updateEntityAction({
                entityId: selectedTopic.id,
                entityForm: diff,
            }));

            if (r) {
                SwalUtils.showSuccessSwalToast();
                setDisplayMode(FormDisplayMode.View);
            }
        } else {
            const r = await dispatch(topicsAsyncActions.createEntity(form));
            if (r) {
                SwalUtils.showSuccessSwalToast();
            }
            onClose();
        }
    }, [isFormValid, getFormDiff, isEntityEditMode, selectedTopic, form, setFormErrors]);

    return (
        <div className="entity-details-container">
            <div className="entity-details">
                <InputField onChange={handleChange}
                            value={form.name}
                            error={formErrors.name}
                            readonly={isViewMode}
                            label={t('topic')}
                            name="name"/>
                <InputField onChange={handleChange}
                            value={form.ocsViberToken}
                            readonly={isViewMode}
                            label={t('viber_token')}
                            error={formErrors.ocsViberToken}
                            name="ocsViberToken"/>
                <InputField onChange={handleChange}
                            value={form.ocsViberBotPicture ?? ''}
                            className="mt-20"
                            readonly={isViewMode}
                            label={t('viber_bot_picture')}
                            name={'ocsViberBotPicture'}
                            error={formErrors.ocsViberBotPicture}/>

                <InputField onChange={handleChange}
                            value={form.onConversationStartedButtonText ?? ''}
                            className="mt-20"
                            readonly={isViewMode}
                            label={t('on_conversation_started_button_text')}
                            name={'onConversationStartedButtonText'}
                            error={formErrors.onConversationStartedButtonText}/>


                <TextareaField onChange={handleChange}
                               value={form.autoreminderMessage ?? ''}
                               className="mt-20"
                               readonly={isViewMode}
                               label={t('autoreminder_message')}
                               name={'autoreminderMessage'}
                               error={formErrors.autoreminderMessage}/>

                <TextareaField onChange={handleChange}
                               value={form.onConversationStartedMessage ?? ''}
                               className="mt-20"
                               readonly={isViewMode}
                               label={t('on_conversation_started_message')}
                               name={'onConversationStartedMessage'}
                               error={formErrors.onConversationStartedMessage}/>

                <Checkbox checked={form.isWelcomeQuestionnaireEnabled ?? false}
                          className={'mb-16 mt-16'}
                          onChange={(e) => {
                              if (isViewMode) {
                                  return;
                              }

                              updateForm({ isWelcomeQuestionnaireEnabled: e });
                          }}
                          label={t('is_welcome_questionnaire_enabled')}/>

                <AsyncContainer async={questionnaireAsync}>
                    <SelectField value={selectedQuestionnaireOption}
                                 label={t('welcome_questionnaire')}
                                 options={questionnaireOptions}
                                 readonly={isViewMode}
                                 clearable={true}
                                 onChange={option => {
                                     if (option === null) {
                                         const clonedForm = cloneDeep(form);
                                         clonedForm.welcomeQuestionnaire = null;
                                         setForm(clonedForm);
                                     } else {
                                         updateForm({
                                             welcomeQuestionnaire: option?.value,
                                         });
                                     }
                                 }}/>
                </AsyncContainer>
            </div>
            <div className="footer">
                {!isViewMode ? (
                    <>
                        <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                                onClick={onClose}>
                            {t('cancel')}
                        </Button>
                        <Button className="submit-btn text-uppercase footer-button"
                                buttonStyle={ButtonStyle.Blue}
                                loading={async}
                                onClick={onSubmit}>
                            {isEntityEditMode ? t('save') : t('add')}
                        </Button>
                    </>
                ) : (
                    <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                            onClick={onClose}>
                        {t('close')}
                    </Button>
                )}
            </div>
        </div>
    );
}
