import { IconSend } from "@tabler/icons";
import ColorfulIconButton, {
    ColorfulIconButtonVariant,
} from "src/components/Ui/ColorfulIconButton/ColorfulIconButton";
import Spinner from "src/components/Ui/Spinner/Spinner";
import { Lead } from "src/entities/Lead/Lead";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    messengerActions,
    messengerAsyncActions,
    messengerAttachmentsSelectorForEntity,
    SendMessageRet,
} from "src/store/slices/messengerSlice";
import MessageBarActionDropup from "./MessageBarActionDropup/MessageBarActionDropup";
import "./messenger_message_bar.scss";
import MessageBarAttachments from "./MessageBarAttachments/MessageBarAttachments";
import TextareaAutosize from "react-textarea-autosize";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export interface MessengerMessageBarProps {
    lead: Lead;
    onClickOpenTemplates: () => void,
}

export function MessengerMessageBar({
                                        lead,
                                        onClickOpenTemplates,
                                        ...rest
                                    }: MessengerMessageBarProps) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const leadId = lead.id;

    const messageText = useAppSelector(state => {
        if (leadId) {
            return state.messenger.messageText[leadId] ?? "";
        } else {
            return "";
        }
    });

    const sendAsync = useAppSelector((state) => state.messenger.sendAsync);
    const attachments = useAppSelector(state => messengerAttachmentsSelectorForEntity(state, leadId)) ?? [];
    const hasAttachments = attachments.length > 0;

    const messageSendDisabled = (messageText.length === 0 || sendAsync) && !hasAttachments;

    const updateMessageText = useCallback((text: string) => {
        dispatch(messengerActions.setMessageText({ text, id: leadId }));
    }, [dispatch, leadId]);

    async function onSendMessage() {
        const r = await dispatch(
            messengerAsyncActions.sendMessage(
                lead.id,
                messageText,
                {
                    viberId: lead.viber.id,
                    smsNumber: lead.sms.number,
                },
            ),
        );
    }

    return (
        <div className="messenger-message-bar">
            <MessageBarAttachments leadId={leadId}/>

            <div className="message-bar-bottom">
                <form
                    className="message-bar-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSendMessage();
                    }}
                >
                    <TextareaAutosize
                        placeholder="Upišite poruku..."
                        className="message-input-field"
                        value={messageText}
                        minRows={1}
                        onKeyDown={event => {
                            if (!(event.shiftKey || event.ctrlKey) && event.key === "Enter") {
                                event.preventDefault();
                                onSendMessage();
                            }
                        }}
                        maxRows={6}
                        onChange={(e) => updateMessageText(e.target.value)}
                    />
                </form>

                <MessageBarActionDropup leadId={leadId}
                                        onClickOpenTemplates={onClickOpenTemplates}/>

                <ColorfulIconButton
                    disabled={messageSendDisabled}
                    onClick={onSendMessage}
                    variant={ColorfulIconButtonVariant.BLUE_WHITE}>
                    {sendAsync ? <Spinner small={true}/> : <IconSend/>}
                </ColorfulIconButton>
            </div>
        </div>
    );
}

export default MessengerMessageBar;
