import { Message, MessageChannel } from "src/entities/message/Message";
import { FullName, FullNameWithViber } from "../../utils/utils";
import { UserTopic } from "../User/User";

export interface LeadQuery {
    search?: string;
    isArchived?: boolean;
    user?: string | null;
}

export interface LeadViberInfo {
    id: string | null,
    name: string | null,
}

export interface LeadSmsInfo {
    id: string | null,
    number: string | null,
}

export enum OptStatus {
    IN = "IN",
    OUT = "OUT",
    UNKNOWN = "UNKNOWN"
}

export interface LeadReadState {
    isRead: boolean,
    userId: string,
}

export interface Lead {
    id: string;
    lastMessage: Omit<Message, "agent"> | null,
    createdAt: string,
    firstName: string,
    phoneticFirstName: string | null,
    lastName: string,
    phone: string,
    address: string,
    viber: LeadViberInfo,
    sms: LeadSmsInfo,
    isArchived: boolean,
    isVerified: boolean,
    optStatus: OptStatus,
    internalId: string,
    readStates: LeadReadState[],
    bumpedAt: string,
    note: string | null,
    topic: UserTopic,
}

export class LeadUtils {
    public static hasMainName(comm: FullName): boolean {
        if (comm.firstName === "UNKNOWN") return false;

        return comm.firstName !== "" || comm.lastName !== "";
    }

    public static getDerivedName(comm: FullNameWithViber): string {
        return comm.viber?.name ?? "NN";
    }

    public static getChannel({ viberId, smsNumber }: { viberId: string | null; smsNumber: string | null }) {
        if (viberId) {
            return MessageChannel.VIBER;
        } else if (smsNumber) {
            return MessageChannel.SMS;
        } else {
            return null;
        }
    }

    public static isRead(lead: Lead, userId?: string): boolean {
        if (!userId) {
            return false;
        }

        const state = lead.readStates.find(state => state.userId === userId);

        if (!state) {
            return false;
        } else {
            return state.isRead;
        }
    }
}
