import React, { useCallback, useEffect, useState } from "react";
import SelectField, { SelectFieldOption } from "../../SelectField/SelectField";
import Button, { ButtonStyle } from "../../Button/Button";
import Modal from "../../Modal/Modal";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import "./impersonation_modal.scss";
import { useTranslation } from "react-i18next";
import { usersAsyncActions, userSelectors } from "../../../../store/slices/usersSlice";
import AsyncContainer from "../../AsyncContainer/AsyncContainer";
import { loginAsyncActions } from "../../../../store/slices/loginSlice";

export interface ImpersonationModalProps {
    open: boolean,
    setOpen: (val: boolean) => void,
}

const ImpersonationModal = ({ open, setOpen }: ImpersonationModalProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [selectedUser, setSelectedUser] = useState<SelectFieldOption | null>(null);
    const user = useAppSelector(userSelectors.selectedUser);
    const async = useAppSelector(state => state.users.fetchAsync);
    const impersonateOptions = useAppSelector(userSelectors.userImpersonateOptions);
    const users = useAppSelector(userSelectors.usersList);

    useEffect(() => {
        if (impersonateOptions.length === 0) {
            dispatch(usersAsyncActions.fetchAllEntities());
        }
    }, [dispatch]);

    // Apply 'impersonation'
    const onSubmit = useCallback(async () => {
        // close modal on confirm
        const impUser = users.find(u => u.id === selectedUser?.value);

        dispatch(loginAsyncActions.impersonateUser(impUser ?? null));

        setOpen(false);
    }, [dispatch, users, selectedUser]);

    return (
        <Modal open={open}
               setOpen={setOpen}>
            <div className="impersonation-modal-container">
                <div className="title text-regular text-18">{t("view_as")}:</div>
                <AsyncContainer async={async}>
                    <SelectField options={impersonateOptions}
                                 value={selectedUser}
                                 placeholder={t("user")}
                                 className="hc-picker"
                                 menuOverModal={true}
                                 onChange={(v) => setSelectedUser(v)}/>

                    <div className="footer">
                        <Button buttonStyle={ButtonStyle.Text}
                                onClick={() => setOpen(false)}>
                            <span
                                className="text-medium text-14 text-color-3 text-uppercase">{t("cancel")}</span>
                        </Button>
                        <Button buttonStyle={ButtonStyle.Blue}
                                onClick={onSubmit}>
                            <span className="text-medium text-14 text-uppercase">{t("confirm")}</span>
                        </Button>
                    </div>
                </AsyncContainer>
            </div>
        </Modal>
    );
};

export default ImpersonationModal;
