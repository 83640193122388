import React from "react";
import "./tabs.scss";
import classnames from "classnames";

export interface CustomTab<T> {
    label: string,
    value: T,
    disabled?: boolean
}

interface CustomTabsProps<T> {
    tabs: Array<CustomTab<T>>
    activeTab: T;
    onSwitch: (value: T) => void,
}

function CustomTabs<T>({ tabs, activeTab, onSwitch }: CustomTabsProps<T>) {
    return (
        <div className={classnames("tabs-container", `active-${activeTab}`)}>
            <div className={classnames(`tabs active-${activeTab}`)}>
                {tabs.map((tab, index) => (
                    <div className={classnames(`tab ${tab.value}-tab`, {
                        "active": activeTab === tab.value,
                        "disabled": tab.disabled
                    })}
                         key={index}
                         onClick={() => onSwitch(tab.value)}>
                        {tab.label}
                    </div>
                ))}
            </div>

            <div className="bottom-radius-helper"/>
            <div className="bottom-left-radius-helper"/>
            <div className="bottom-right-radius-helper"/>
        </div>
    );
}

export default CustomTabs;
