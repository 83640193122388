import React, { PropsWithChildren } from "react";
import Spinner from "src/components/Ui/Spinner/Spinner";

interface AsyncContainerProps {
    async: boolean;
    size?: number
}

function AsyncContainer({ async, children, size }: PropsWithChildren<AsyncContainerProps>) {

    if (async) {
        return <Spinner size={size}/>;
    }

    return (
        <>
            {children}
        </>
    );
}

export default AsyncContainer;