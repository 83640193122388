import { cloneDeep, merge, pick } from "lodash";
import { User, UserRole, UserUtils } from "src/entities/User/User";
import i18n from "../../i18n/config";
import { Utils } from "../../utils/utils";
import { differenceWith, isEqual, isObject, transform } from "lodash";

export interface IUserForm {
    email?: string,
    fullName?: string,
    firstName?: string,
    lastName?: string,
    phone?: string,
    user_role?: UserRole,
    password?: string
    confirmPassword?: string,
    company?: string,
    topic?: string,
}

export type IUserFormErrors = Partial<Record<keyof IUserForm, string>>

export class UserFormUtils {
    static createForm(user?: User): IUserForm {
        if (!user) {         // empty form
            return {
                phone: "",
                fullName: "",
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
            };
        }

        const keys: Array<keyof Omit<User, "Topic">> = ["firstName", "lastName", "email", "phone", "internalId"];

        return {
            ...pick(user, keys) as IUserForm,
            user_role: UserUtils.getUserRole(user),
            fullName: Utils.mergeName(user),
            topic: user.topic?.id,
        };
    }

    static prepareForm(userForm: IUserForm) {
        if (userForm.fullName) {
            const { firstName, lastName } = Utils.convertFullNameToFirstAndLast(userForm.fullName);
            userForm.firstName = firstName ?? "";
            userForm.lastName = lastName ?? "";
        }
    }

    static updateForm(userForm: IUserForm, data: IUserForm) {
        return merge(cloneDeep(userForm), data);
    }

    static validatePassword({
                                password,
                                confirmPassword
                            }: { password: string, confirmPassword: string | undefined }): string | undefined {
        const errors: IUserFormErrors = {};

        if (password.length < 8) {
            errors.confirmPassword = i18n.t("password_is_too_short_min_8_characters");
        } else if (password !== confirmPassword) {
            errors.confirmPassword = i18n.t("passwords_dont_match");
        }

        return errors.confirmPassword;
    }

    static validate(form: IUserForm): IUserFormErrors {
        const errors: IUserFormErrors = {};

        const { firstName, lastName } = Utils.convertFullNameToFirstAndLast(form.fullName ?? "");

        if (!firstName || !lastName) {
            errors.fullName = i18n.t("full_name_is_required");
        }

        if (!form.user_role) {
            errors.user_role = "role_error";
        }

        if (!form.email) {
            errors.email = i18n.t("email_is_required");
        } else if (!form.email.includes("@")) {
            errors.email = i18n.t("invalid_email");
        }

        return errors;
    }

}
