import { cloneDeep, merge, pick } from "lodash";
import { SelectFieldOption } from "src/components/Ui/SelectField/SelectField";
import { Utils } from "src/utils/utils";
import i18n from "../../i18n/config";
import { Lead, LeadViberInfo } from "./Lead";

export interface ILeadForm {
    fullName?: string,
    firstName?: string,
    phoneticFirstName?: string | null,
    lastName?: string,
    phone?: string,
    address?: string,
    viber?: LeadViberInfo | null,
}

export type ILeadFormErrors = Partial<Record<keyof ILeadForm, string>>

export class LeadFormUtils {

    static prepareForSend(form: ILeadForm): ILeadForm {
        const newForm = cloneDeep(form);

        if (newForm.fullName) {
            const { firstName, lastName } = Utils.convertFullNameToFirstAndLast(newForm.fullName);
            newForm.firstName = firstName ?? '';
            newForm.lastName = lastName ?? '';
        }

        // clear whitespace from phone
        newForm.phone = newForm.phone?.replaceAll(' ', '');

        return newForm;
    }

    static validate(form: ILeadForm): ILeadFormErrors {
        const errors: ILeadFormErrors = {};

        const { firstName, lastName } = Utils.convertFullNameToFirstAndLast(form.fullName ?? '');

        if (!firstName || !lastName) {
            errors.fullName = i18n.t("full_name_is_required");
        }
        
        return errors;
    }

    static createForm(lead: Lead): ILeadForm {
        const keys: Array<keyof Lead> = ["viber", "phoneticFirstName", "firstName", "lastName", "address", "phone"];
        const form = {
            ...pick(lead, keys) as ILeadForm,
            fullName: Utils.mergeName(lead),
        };

        return form;
    }

    static updateForm(hcForm: ILeadForm, data: ILeadForm) {
        return merge(cloneDeep(hcForm), data);
    }

    static getOptionsFromList = (leads: Lead[]): SelectFieldOption[] => {
        return leads.map(p => ({
            value: p.id,
            label: `${Utils.mergeName(p)} / ${p.phone}`,
        }));
    };
}
