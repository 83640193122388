import { useEffect } from "react";
import { Message } from "src/entities/message/Message";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    messagesFetchAsyncSelector,
    messagesSelector,
    messengerActions,
    messengerAsyncActions,
} from "src/store/slices/messengerSlice";
import { leadsAsyncActions } from "../../../store/slices/leadSlice";
import { Lead, LeadUtils } from "../../../entities/Lead/Lead";
import { loginSelectors } from "../../../store/slices/loginSlice";

export function useMessages(lead: Lead | null): [Message[], boolean] {
    const clientId = lead?.id;
    const user = useAppSelector(loginSelectors.effectiveUser);
    const messages = useAppSelector(messagesSelector);
    const fetchAsync = useAppSelector(messagesFetchAsyncSelector);
    const dispatch = useAppDispatch();

    const userId = user?.id;

    const isRead = lead !== null ? LeadUtils.isRead(lead, userId) : false;

    useEffect(() => {
        if (clientId) {
            dispatch(messengerAsyncActions.fetchMessagesPage(clientId));
        }

        return () => {
            dispatch(messengerActions.resetState());
        };
    }, [clientId, dispatch]);

    useEffect(() => {
        if (clientId && !isRead && userId) {
            dispatch(leadsAsyncActions.setHasUnreadMessages(clientId, userId, false));
        }
    }, [dispatch, clientId, isRead, userId]);

    return [messages, fetchAsync];
}
