import React, { CSSProperties, useEffect, useState } from "react";
import classNames from "classnames";
import Spinner from "../Spinner/Spinner";
import { BaseEntity } from "../../../store/helpers/entityReducer";
import { Waypoint } from "react-waypoint";
import "./lazy_load_list.scss";
import { useTranslation } from "react-i18next";

interface InjectedLazyLoadListProps<T extends BaseEntity> {
    item: T,
}

interface LazyLoadListProps<T extends BaseEntity> {
    data: T[],

    children(props: InjectedLazyLoadListProps<T>): JSX.Element,

    className?: string,
    style?: CSSProperties,
    onLoadNext: (args: Waypoint.CallbackArgs) => void,
    async: boolean,
    autoUpdateIntervalMilis?: number,

}

export function LazyLoadList<T extends BaseEntity>({
                                                       autoUpdateIntervalMilis,
                                                       async = false,
                                                       onLoadNext,
                                                       style,
                                                       className,
                                                       data,
                                                       children,
                                                   }: LazyLoadListProps<T>) {
    const [forceUpdate, setForceUpdate] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        let updateInt: number | null = null;
        if (autoUpdateIntervalMilis) {
            setInterval(() => {
                setForceUpdate(forceUpdate + 1);
            }, autoUpdateIntervalMilis);
        }

        return () => {
            if (updateInt) {
                clearTimeout(updateInt);
            }
        };
    }, [autoUpdateIntervalMilis, setForceUpdate, forceUpdate]);

    return (
        <div style={style} className={classNames(className, "lazy-load-list")}>
            <div className="inner">
                {data.length > 0 && (
                    <>
                        {data.map((item) => {
                            return children({ item });
                        })}

                        <Waypoint onEnter={onLoadNext}
                                  key={data.length}>
                            <div className="lazy-load-list-async-container-and-waypoint">
                                {async && <Spinner size={35}/>}
                            </div>
                        </Waypoint>
                    </>
                )}
                {(!data.length && !async) && (
                    <div className="no-results">
                        {t("no_results_found")}.
                    </div>
                )}
            </div>
        </div>
    );

}

export default LazyLoadList;
