import React, { FunctionComponent, useMemo } from 'react';
import { RouteName, Router } from 'src/router/router';
import './menu.scss';
import MenuItem from './MenuItem/MenuItem';
import { IconHome, TablerIconProps } from '@tabler/icons';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import { loginSelectors } from 'src/store/slices/loginSlice';
import { UserRole, UserUtils } from 'src/entities/User/User';

export interface RawMenuItem {
    routeName: RouteName;
    routePath: string;
    title: string;
    icon: FunctionComponent<TablerIconProps>;
    subRoutes?: RawMenuItem[];
    allowedRoles: UserRole[];
}

const Menu = () => {
    const { t } = useTranslation();

    const menuItems: RawMenuItem[] = useMemo(() => [{
        routeName: RouteName.Communication,
        routePath: Router.RoutePaths.Communication,
        title: t("home"),
        icon: IconHome,
        allowedRoles: [UserRole.ROLE_USER, UserRole.ROLE_ADMIN, UserRole.ROLE_SUPER_ADMIN],
    }, {
        routeName: RouteName.Topics,
        routePath: Router.RoutePaths.Topics,
        title: t("topics"),
        icon: IconHome,
        allowedRoles: [UserRole.ROLE_SUPER_ADMIN],
    }, {
        routeName: RouteName.Questionnaires,
        routePath: Router.RoutePaths.Questionnaires,
        title: t("questionnaires"),
        icon: IconHome,
        allowedRoles: [UserRole.ROLE_SUPER_ADMIN],
    },{
        routeName: RouteName.ViberKeyboards,
        routePath: Router.RoutePaths.ViberKeyboards,
        title: t("viber_keyboards"),
        icon: IconHome,
        allowedRoles: [UserRole.ROLE_SUPER_ADMIN],
    }, {
        routeName: RouteName.Users,
        routePath: Router.RoutePaths.Users,
        title: t("users"),
        icon: IconHome,
        allowedRoles: [UserRole.ROLE_SUPER_ADMIN],
    }, {
        routeName: RouteName.Reports,
        routePath: Router.RoutePaths.Reports,
        title: t("reports"),
        icon: IconHome,
        allowedRoles: [UserRole.ROLE_SUPER_ADMIN],
    }], [t]);

    const user = useAppSelector(loginSelectors.effectiveUser);
    const userRole = user ? UserUtils.getUserRole(user) : null;

    const filteredMenuItems = useMemo(() => {
        const rootItems = userRole ? menuItems.filter(menuItem => menuItem.allowedRoles.includes(userRole)) : [];
        rootItems.forEach(item => {
            item.subRoutes = userRole ? item.subRoutes?.filter(menuItem => menuItem.allowedRoles.includes(userRole)) : [];
        });
        return rootItems;
    }, [menuItems, userRole]);

    const navigate = useNavigate();
    const location = useLocation();

    const match = Router.routes.find((r) => matchPath({
        path: r.path,
    }, location.pathname)) ?? null;

    if (match === null) return null;

    const route = Router.routes.find(({ path }) => {
        return match.path === path;
    });

    return (
        <div className="main-menu">
            <div className="main-logo-container">
                <svg version="1.1"
                     fill="#C72028"
                     className="ml-10"
                     id="Layer_1"
                     x="0px" y="0px"
                     viewBox="0 0 284.8 98.6">
                    <g>
                        <path d="M44.4,78.8c6.3-6.9,14.8-11.6,24.2-13.2c1.4-0.2,2.8-0.3,4.2-0.2c8,0.4,15.2,3.4,20.5,8.2L42,98.4c-0.9-2.6-1.2-5.5-1-8.6
                            C41.2,85.8,42.5,82.2,44.4,78.8"/>
                        <path d="M33.1,40.8l-0.5-11.7L20.3,5.3C12.2,10.9,6,19,2.9,28.8c-3.8,12-2.1,24.2,3.9,34.4l22.1-27.2
                            C30.4,37.5,31.7,39.1,33.1,40.8 M29.1,40L3.6,71.3l26.7,26.9c6.4-7.1,10.4-16.2,11-26.3C41.9,59.8,37.2,48.4,29.1,40 M43.1,75.8
                            c7.6-7.7,18-12.8,29.4-13.8c6.1-28.5-5.6-62.6-39.2-61.7L35,42.5c5.9,8.5,9,18.9,8.4,29.9C43.4,73.5,43.3,74.7,43.1,75.8
                             M75.4,62.2c0.5-3.1,0.9-6.1,1-9.3c0.9-16.6-3.4-31.5-10.7-40.2l5.1-5.4c8.3,8.4,13.2,20,12.6,32.3C83,48.1,80.1,55.8,75.4,62.2"/>
                        <path d="M98.3,61.6H121v0.5c0,2.4,0.5,4.1,1.5,5.2c1,1.2,2.5,1.7,4.5,1.7c1.8,0,3.2-0.4,4.1-1.3c1.1-0.9,1.6-2.2,1.6-3.7
                            c0-1.2-0.2-2.2-0.6-2.9c-0.4-0.9-0.9-1.5-1.7-1.8c-1.2-0.7-3.7-1.5-7.5-2.6c-3.8-1.1-6.8-1.9-8.9-2.7c-5.3-1.8-9.2-4.3-11.7-7.4
                            c-2.4-3-3.7-6.8-3.7-11.4c0-6.8,2.6-12.2,7.6-16c5-3.8,12.2-5.7,21.1-5.7c8.3,0,14.7,1.6,19.1,5c4.3,3.2,6.5,7.9,6.5,14.1V34h-20.9
                            c-0.2-2.1-0.8-3.6-1.7-4.7c-0.8-1-2.2-1.4-4-1.4c-1.5,0-2.7,0.3-3.6,1.2c-1,0.9-1.4,1.9-1.4,3.4c0,2.5,2.5,4.4,7.6,5.8l4.4,1.2
                            c8.5,2.5,14.2,5.3,17.3,8.4c3.1,3.1,4.5,7.4,4.5,12.9c0,7.4-2.6,13.1-7.8,17.4c-5.2,4.3-12.3,6.5-21.2,6.5c-9.1,0-16.1-1.7-21-5.4
                            c-4.9-3.6-7.4-8.7-7.4-15.3v-1.5C98.2,62.2,98.3,61.8,98.3,61.6"/>
                        <path d="M214,23.1c-5.6-5.6-14.2-8.4-25.9-8.4h-28.2v69.2h28.4c5.7,0,10.7-0.8,14.9-2.3c4.3-1.4,7.8-3.7,10.7-6.5
                            c5.7-5.8,8.6-14.6,8.6-26.1C222.5,37.4,219.7,28.7,214,23.1 M194.6,63.5c-1.8,2.9-4.7,4.3-8.9,4.3h-1.5V30.3h2.3
                            c3.9,0,6.6,1.4,8.3,4.1c1.6,2.8,2.5,7.6,2.5,14.3C197.3,55.7,196.4,60.5,194.6,63.5"/>
                        <path d="M277.4,20.3c-4.4-3.8-11.2-5.6-20.2-5.6h-30.6v69.1h23.7V60.3h7.7c8.5,0,14.9-1.9,19.3-5.8c4.4-4,6.6-9.7,6.6-17.3
                            C284,29.6,281.8,23.9,277.4,20.3 M258.1,42.7c-1.3,1.3-3.2,1.9-5.7,1.9h-1.2l-0.8-0.1V30.1h1.4c2.9,0,4.9,0.5,6.3,1.7
                            c1.3,1.1,2,2.8,2,5.2C260.1,39.5,259.4,41.4,258.1,42.7"/>
                    </g>
                </svg>

            </div>
            <div className="menu-items">
                {filteredMenuItems.map((item) => {
                    const subroutes = (item.subRoutes ?? []);

                    let isSubrouteSelected = false;

                    for (const subroute of subroutes) {
                        if (route?.name === subroute.routeName) {
                            isSubrouteSelected = true;
                            break;
                        }
                    }

                    return (
                        <MenuItem
                            onClick={() => navigate(item.routePath)}
                            isSubroute={false}
                            key={item.title}
                            icon={item.icon}
                            currentRoute={route}
                            subRoutes={item.subRoutes}
                            isSelected={(route?.name === item.routeName || isSubrouteSelected)}
                            title={item.title}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Menu;
