import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { API } from "../../api/api";
import { ReadFile, Utils } from "src/utils/utils";

export interface MediaObject {
    id: string,
    createdAt: string,
    contentUrl: string,
    mimeType: string,
    thumbnail: Thumbnail | null,
    originalFileName: string,
    size: number,
}

export interface Thumbnail {
    thumbnailFilePath: string | null,
    thumbnailUrl: string | null
}

export enum MediaObjectType {
    Media = "media",
    Files = "files"
}

export class MediaObjectUtils {

    public static toReadFile(obj: MediaObject): Promise<ReadFile | null> {
        return fetch(MediaObjectUtils.getUrl(obj))
            .then(r => r.blob())
            .then(blob => {
                return Utils.readFile(new File([blob], obj.originalFileName, { type: obj.mimeType }));
            })
            .catch(() => null);
    }

    public static getUrl(obj: MediaObject): string {
        return `${API.storageEndpoint}${obj.contentUrl}`;
    }

    public static getThumbUrl(obj: MediaObject): string | null {
        return obj.thumbnail?.thumbnailUrl ? `${API.storageEndpoint}${obj.thumbnail.thumbnailUrl}` : null;
    }

    public static hasThumb(obj: MediaObject): boolean {
        return Boolean(obj.thumbnail?.thumbnailUrl);
    }

    public static isFile(obj: Pick<MediaObject, "mimeType">): boolean {
        const mime = obj.mimeType;
        if (!mime) return false;
        return !(mime.startsWith("image") || mime.startsWith("video"));
    }

    public static getExtension(obj: Pick<MediaObject, "contentUrl"> | { name: string }): string {
        let data = "";
        if ("contentUrl" in obj) {
            return obj.contentUrl;
        } else if ("name" in obj) {
            return obj.name;
        }

        return data.split(".").pop() ?? "";
    }

    public static getFilename(obj: MediaObject): string {
        return obj.contentUrl.split("/").pop() ?? "";
    }

    public static getFileIconName(obj: Pick<MediaObject, "contentUrl"> | { name: string }): IconProp {
        switch (MediaObjectUtils.getExtension(obj)) {
            case "pdf":
                return {
                    iconName: "file-pdf",
                    prefix: "fas",
                };
            case "doc":
            case "docx":
                return {
                    iconName: "file-word",
                    prefix: "fas",
                };
            default:
                return {
                    iconName: "file",
                    prefix: "fas",
                };
        }
    }

    public static getFileSize(obj: MediaObject) {
        const bytes = obj.size;
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    }

    public static attachmentToFile(attachment: ReadFile): Promise<File | null> {
        return fetch(attachment.file).then(r => r.blob()).then(blob => new File([blob], attachment.name, { type: attachment.mimeType }))
            .catch(() => null);
    }

    static mediaObjectToAttachment(mediaObject: MediaObject): ReadFile {
        return {
            name: mediaObject.originalFileName,
            mimeType: mediaObject.mimeType,
            file: this.getUrl(mediaObject),
        };
    }

    static getMediaToDelete(originalMediaObjects: MediaObject[], attachments: ReadFile[]): string[] {
        const mediaIds: string[] = [];

        const attachmentFileUrls = attachments.map(a => a.file);

        originalMediaObjects.forEach(mo => {
            if (!attachmentFileUrls.some(url => url.includes(mo.contentUrl))) {
                mediaIds.push(mo.id);
            }
        });

        return mediaIds;
    }

    static getNewAttachmentsToUpload(attachments: ReadFile[], originalMediaObjects: MediaObject[]) {
        const mediaObjectsUrls = originalMediaObjects.map(mo => mo.contentUrl);
        return attachments.filter(att => !mediaObjectsUrls.some(url => url.includes(att.file)));
    }
}
