import { API, ApiCallParams, ApiMethod } from "./api";
import { ReadFile, Utils } from "../utils/utils";
import { Message, MessageChannel } from "src/entities/message/Message";
import { RawHydraResponse } from "./paginationResponse";

export enum SendMessageFail {
    FileSizeTooBig,
}

export class MessagesApi {
    static fetchMessages = async (customerId: string, lastMessageCreatedAt: string, perPage: number, signal: AbortSignal): Promise<RawHydraResponse<Message> | null> => {
        let url = new URL(API.messages);

        url.searchParams.append("pagination", "true");
        url.searchParams.append("per_page", perPage.toString());
        url.searchParams.append("lead", customerId);
        url.searchParams.append("createdAt[strictly_before]", lastMessageCreatedAt);

        return await API.jsonApiCall({
            signal,
            url: url.toString(),
            method: ApiMethod.Get,
            headers: {
                accept: "application/ld+json",
            },
        });
    };

    static sendMessage = async (communicationId: string, content: string, attachments: ReadFile[], channel: MessageChannel): Promise<Response | SendMessageFail | null> => {
        const params: ApiCallParams = {
            url: `${API.messages}/send`,
            method: ApiMethod.Post,
            manualUnknownErrorHandling: true,
        };

        if (attachments.length > 0) {
            const data = new FormData();
            data.append("lead_id", communicationId);
            data.append("source", "SYSTEM");
            data.append("channel", channel);
            data.append("content", content);

            let totalFileSize = 0;

            attachments.forEach(file => {
                data.append(`file[]`, Utils.dataURItoBlob(file.file), file.name);
                totalFileSize += file.file.length;
            });

            if (totalFileSize >= 20447232) {
                return SendMessageFail.FileSizeTooBig;
            }

            params.body = data;
        } else {
            params.jsonData = {
                lead_id: communicationId,
                // TODO most za preci
                source: "SYSTEM",
                channel,
                content,
            } as Record<string, unknown>;
        }

        return await API.apiCall(params) ?? null;
    };
}
