import { MediaObject, MediaObjectUtils } from "src/entities/Mediaobject/MediaObject";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface MessengerMessageAttachmentRendererProps {
    attachments: MediaObject[];
}

function MessengerMessageAttachmentRenderer({ attachments }: MessengerMessageAttachmentRendererProps) {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const [fileAttachments, mediaAttachments]: [MediaObject[], MediaObject[]] = attachments.reduce<[MediaObject[], MediaObject[]]>((memo, attachment) => {
        const idx = MediaObjectUtils.isFile(attachment) ? 0 : 1;
        memo[idx].push(attachment);
        return memo;
    }, [[], []]);

    const medias = mediaAttachments.map(attachment => {
        return MediaObjectUtils.getUrl(attachment);
    });

    const openAttachment = (attachment: MediaObject) => {
        window.open(MediaObjectUtils.getUrl(attachment), "_blank");
    };

    return (
        <>
            <div className="message-attachment">
                {mediaAttachments.length > 0 && (
                    <div className="media-attachment" key={mediaAttachments[0].id} onClick={() => setIsLightboxOpen(true)}>
                        <img
                            src={MediaObjectUtils.getThumbUrl(mediaAttachments[0]) ?? MediaObjectUtils.getUrl(mediaAttachments[0])}
                            alt="messenger-attachment"/>

                        {mediaAttachments.length > 1 && (
                            <div className="message-attachment-count-text">
                                +{attachments.length - 1}
                            </div>
                        )}

                        {isLightboxOpen && (
                            <Lightbox
                                mainSrc={medias[photoIndex]}
                                nextSrc={medias[(photoIndex + 1) % medias.length]}
                                prevSrc={medias[(photoIndex + medias.length - 1) % medias.length]}
                                onCloseRequest={() => setIsLightboxOpen(false)}
                                onMovePrevRequest={() => setPhotoIndex((photoIndex + medias.length - 1) % medias.length)}
                                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % medias.length)}
                            />
                        )}
                    </div>
                )}

                {fileAttachments.length > 0 && (
                    <div className="file-attachments">
                        {fileAttachments.map(file => {
                            return (
                                <div className="file-attachment"
                                     key={file.id}
                                     onClick={() => openAttachment(file)}>
                                    <FontAwesomeIcon
                                        icon={MediaObjectUtils.getFileIconName(file)}
                                        size={"2x"}/>
                                    <span
                                        className="filename">{MediaObjectUtils.getFilename(file)}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

        </>
    );
}

export default MessengerMessageAttachmentRenderer;
