import { FormDisplayMode } from "src/utils/type_utils";
import Messenger from "../Messenger/Messenger";
import "./lead_panel.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    leadsActions, leadsAsyncActions,
    leadsSelectors,
} from "src/store/slices/leadSlice";
import CustomTabs, { CustomTab } from "src/components/Ui/CustomTabs/CustomTabs";
import ColorfulIconButton, { ColorfulIconButtonVariant } from "src/components/Ui/ColorfulIconButton/ColorfulIconButton";
import { IconArchive, IconEdit } from "@tabler/icons";
import PredefinedMessages from "src/pages/Communication/LeadPanel/PredefinedMessages/PredefinedMessages";
import ReactTooltip from "react-tooltip";
import Button, { ButtonStyle } from "src/components/Ui/Button/Button";
import LeadGallery from "./components/LeadGallery/LeadGallery";
import LeadFiles from "./components/LeadFiles/LeadFiles";
import LeadInfo from "./components/LeadInfo/LeadInfo";
import { ILeadForm, LeadFormUtils } from "../../../entities/Lead/LeadForm";

export interface LeadPanelProps {
    multiSelectOverride?: boolean;
}

enum RightPanel {
    Lead = "Lead",
    Templates = "Templates",
    None = "None"
}

enum CommunicationTab {
    Info = "info",
    Gallery = "gallery",
    Files = "files"
}

function LeadPanel({ multiSelectOverride, ...props }: LeadPanelProps) {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const communicationTabs: Array<CustomTab<CommunicationTab>> = [
        {
            value: CommunicationTab.Info,
            label: t("info"),
        },
        // {
        //     value: CommunicationTab.Gallery,
        //     label: t("gallery"),
        // }, {
        //     value: CommunicationTab.Files,
        //     label: t("files"),
        // },
    ];

    const [activeRightPanel, setActiveRightPanel] = useState<RightPanel>(RightPanel.Lead);
    const [displayMode, setDisplayMode] = useState<FormDisplayMode>(FormDisplayMode.View);
    const [activeCommunicationTab, setActiveCommunicationTab] = useState<CommunicationTab>(CommunicationTab.Info);
    const [leadForm, setLeadForm] = useState<ILeadForm>({});

    const async = useAppSelector(state => {
        const { addEditAsync } = state.leads;
        return addEditAsync;
    });

    const selectedLead = useAppSelector(leadsSelectors.selectedLead);

    const selectedEntities = useAppSelector(
        (state) => state.leads.selectedEntities,
    );

    const isMultiselectActive = selectedEntities.length > 1 || multiSelectOverride;

    const isSearchActive = useAppSelector(state => Boolean(state.leads.query?.search?.length));

    const onClickOpenTemplates = () => {
        setActiveRightPanel(RightPanel.Templates);
    };

    const leadViewMode = displayMode === FormDisplayMode.View;

    const renderHeader = () => {
        if (activeCommunicationTab === CommunicationTab.Info) {
            return <>
                {leadViewMode ? t("lead_information") : t("editing_lead_information")}
                <div className="manage-panel-header-icons">
                    {leadViewMode && (
                        <ColorfulIconButton variant={ColorfulIconButtonVariant.TRANSPARENT}
                                            onClick={() => setDisplayMode(FormDisplayMode.Edit)}
                                            data-tip={t("edit")}>
                            <IconEdit/>
                        </ColorfulIconButton>
                    )}

                </div>
            </>;
        } else if (activeCommunicationTab === CommunicationTab.Gallery) {
            return <div>{t("gallery_shared_images")}</div>;
        } else {
            return <div>{t("gallery_shared_files")}</div>;
        }
    };

    const onCloseForm = useCallback(() => {
        if (displayMode === FormDisplayMode.Edit) {         // If Edit mode is active, just switch back to View mode
            setDisplayMode(FormDisplayMode.View);
        }
    }, [displayMode]);

    const onSaveLeadData = useCallback(() => {
        if (selectedLead) {
            dispatch(leadsAsyncActions.updateEntityAction({
                entityId: selectedLead.id,
                entityForm: LeadFormUtils.prepareForSend(leadForm),
            }));
            setDisplayMode(FormDisplayMode.View);
        }
    }, [selectedLead, dispatch, leadForm]);

    const renderTabContent = () => {
        if (activeCommunicationTab === CommunicationTab.Info) {
            if (selectedLead?.id) {
                return <LeadInfo leadForm={leadForm}
                                 setLeadForm={setLeadForm}
                                 formDisplayMode={displayMode}
                                 leadEntity={selectedLead}
                                 onClose={onCloseForm}
                                 onSubmit={onSaveLeadData}/>;
            }
        } else if (activeCommunicationTab === CommunicationTab.Gallery) {
            if (selectedLead?.id) {
                return <LeadGallery leadId={selectedLead?.id}/>;
            }
        } else {
            if (selectedLead?.id) {
                return <LeadFiles leadId={selectedLead?.id}/>;
            }
        }
    };

    useEffect(() => {
        if (selectedLead) {
            setLeadForm(LeadFormUtils.createForm(selectedLead));
            setDisplayMode(FormDisplayMode.View);
        }
    }, [selectedLead]);

    if (selectedLead === null) {
        return (
            <div className="lead-panel empty">
                {isMultiselectActive ? (
                    <div className="flex-row">
                        <Button className="submit-btn text-uppercase footer-button"
                                buttonStyle={ButtonStyle.Text}
                                loading={async}
                                onClick={() => dispatch(leadsActions.unselectAll())}>
                            {t("cancel")}
                        </Button>
                        <Button className="submit-btn text-uppercase footer-button"
                                buttonStyle={ButtonStyle.Blue}
                                loading={async}
                            /* onClick={onSetAllLeadsInactive}*/>
                            {t("archive")}
                        </Button>
                    </div>
                ) : (
                    !isSearchActive && <p>{t("please_select_a_lead")}.</p>
                )}
            </div>
        );
    }

    return (
        <div
            className="lead-panel">
            <Messenger onClickOpenTemplates={onClickOpenTemplates}/>
            {activeRightPanel === RightPanel.Lead ? (
                <div className="flex-column lead-details">
                    <CustomTabs tabs={communicationTabs} activeTab={activeCommunicationTab}
                                onSwitch={setActiveCommunicationTab}/>
                    <div className="manage-entity-common">
                        <div className="header">
                            {renderHeader()}
                        </div>
                        {renderTabContent()}
                    </div>
                </div>
            ) : (
                <PredefinedMessages leadId={selectedLead.id}
                                    onClose={() => setActiveRightPanel(RightPanel.Lead)}/>
            )}
            <ReactTooltip place={"bottom"}/>
        </div>
    );
}

export default LeadPanel;
