import { IconX } from "@tabler/icons";
import { AnimatePresence } from "framer-motion";
import { useCallback, useRef } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { faFile, faFilePdf, faFileWord } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ReadFile } from "../../../utils/utils";
import ColorfulIconButton, { ColorfulIconButtonVariant } from "../ColorfulIconButton/ColorfulIconButton";
import useHover from "../../../utils/hooks/useHover";
import { MediaObject, MediaObjectUtils } from "../../../entities/Mediaobject/MediaObject";

export interface MediaUploaderAttachmentProps {
    attachment: ReadFile | MediaObject,
    readonly?: boolean,
    index: number,
    onRemoveAttachment: (index: number) => void,
}

function isMediaObject(object: ReadFile | MediaObject): object is MediaObject {
    return (object as MediaObject).contentUrl !== undefined;
}

function isFile(file: ReadFile | MediaObject): boolean {
    const mime = file.mimeType;
    if (!mime) return false;
    return !(mime.startsWith("image") || mime.startsWith("video"));
}

function getExtension(obj: ReadFile | MediaObject): string {
    let data = "";

    if ("name" in obj) {
        data = obj.name;
    } else if ("originalFileName" in obj) {
        data = obj.originalFileName;
    }

    return data.split(".").pop() ?? "";
}

function getFileIconName(obj: ReadFile | MediaObject): IconDefinition {
    switch (getExtension(obj)) {
        case "pdf":
            return faFilePdf;
        case "doc":
        case "docx":
            return faFileWord;
        default:
            return faFile;
    }
}

function MediaUploaderAttachment({
                                     onRemoveAttachment,
                                     index,
                                     readonly = false,
                                     attachment,
                                     ...rest
                                 }: MediaUploaderAttachmentProps) {
    const attachmentRef = useRef<HTMLDivElement>(null);
    const hover = useHover(attachmentRef);

    const removeAttachment = useCallback(() => {
        onRemoveAttachment(index);
    }, [index, onRemoveAttachment]);

    return (
        <div className="attachment-container"
             data-tip={isMediaObject(attachment) ? attachment.originalFileName : attachment.name}>
            <div className="attachment" ref={attachmentRef}>
                <AnimatePresence>
                    {(hover && !readonly) && (
                        <motion.div initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                            <ColorfulIconButton variant={ColorfulIconButtonVariant.RED}
                                                onClick={removeAttachment}
                                                className="remove-attachment-btn">
                                <IconX/>
                            </ColorfulIconButton>
                        </motion.div>
                    )}
                </AnimatePresence>

                {isFile(attachment) ? (
                    <div className="file-attachment"
                         key={isMediaObject(attachment) ? attachment.id : attachment.name}>
                        <FontAwesomeIcon
                            icon={getFileIconName(attachment)}/>
                        <span
                            className="filename">{isMediaObject(attachment) ? attachment.originalFileName : attachment.name}</span>
                    </div>
                ) : (
                    <img
                        src={isMediaObject(attachment) ? MediaObjectUtils.getThumbUrl(attachment) ?? MediaObjectUtils.getUrl(attachment) : attachment.file}
                        alt="attachment"/>
                )}

                <div className="image-tint"/>
            </div>
        </div>
    );
}

export default MediaUploaderAttachment;
