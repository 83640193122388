function genFormAnimation(flexFactor: number) {
    return {
        initial: {
            minWidth: "0px",
            overflow: "hidden",
            flex: 0,
            marginLeft: "0rem",
        },
        in: {
            flex: flexFactor,
            marginLeft: "1.875rem",
            transitionEnd: {
                overflow: "unset",
            },
        },
        out: {
            minWidth: "0px",
            overflow: "hidden",
            flex: 0,
            marginLeft: "0rem",
        },
    };
}

function genFormAnimationNoMargin(flexFactor: number) {
    return {
        initial: {
            minWidth: "0px",
            overflow: "hidden",
            flex: 0,
        },
        in: {
            flex: flexFactor,
            transitionEnd: {
                minWidth: "inherit",
                overflow: "unset",
            },
        },
        out: {
            minWidth: "0px",
            overflow: "hidden",
            flex: 0,
        },
    };
}

export const transition350easeInOut = { duration: 0.3, ease: "easeInOut" };

export function collapseFromRightMaxWidthAnimation(actualMaxWidth: string) {
    return {
        initial: {
            overflow: "hidden",
            minWidth: '0px',
            maxWidth: '0px',
        },
        in: {
            maxWidth: actualMaxWidth,
        },
        out: {
            minWidth: '0px',
            overflow: "hidden",
            maxWidth: actualMaxWidth,
        },
    };
}
export function collapseFromRightMinWidthAnimation(actualMinWidth: string) {
    return {
        initial: {
            overflow: "hidden",
            minWidth: '0px',
            maxWidth: '0px',
        },
        in: {
            minWidth: actualMinWidth,
        },
        out: {
            minWidth: '0px',
            overflow: "hidden",
            maxWidth: '0px',
        },
    };
}

export const form2Animation = genFormAnimation(0.65);
export const form3Animation = genFormAnimation(0.55);
export const form4Animation = genFormAnimation(0.9);
export const form5Animation = genFormAnimation(2.1);
export const form6Animation = genFormAnimation(6);
export const form10Animation = genFormAnimation(10);

export const form10AnimationNoMargin = genFormAnimationNoMargin(10);
export const form2AnimationNoMargin = genFormAnimationNoMargin(2);
