import { Lead, LeadUtils } from "src/entities/Lead/Lead";
import { Utils } from "src/utils/utils";
import MessengerHeaderActions from "./MessengerHeaderActions/MessengerHeaderActions";
import "./messenger_header.scss";
import classNames from "classnames";

export interface MessengerHeaderProps {
    lead: Lead,
}

export function MessengerHeader({ lead, ...rest }: MessengerHeaderProps) {
    const hasMainName = LeadUtils.hasMainName(lead);

    return (
        <div className="messenger-header">
            <div className={classNames({ "text-italic": !hasMainName}, "lead-name")}>
                {hasMainName ? (
                    Utils.mergeName(lead ?? {})
                ) : (
                    LeadUtils.getDerivedName(lead)
                )}
            </div>

            <MessengerHeaderActions leadId={lead.id}/>
        </div>
    );
}

export default MessengerHeader;
