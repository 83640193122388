import { Questionnaire } from "src/entities/questionnaire/Questionnaire";
import { BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { APIEntity } from "src/api/api";
import { RootState } from "../store";
import { IQuestionnaireForm } from "src/entities/questionnaire/QuestionnaireForm";
import { createSelector } from "@reduxjs/toolkit";
import i18next from "i18next";

interface QuestionnairesState extends BaseEntityReducerState<Questionnaire> {

}

const initialState: QuestionnairesState = {
    entities: {},
    perPage: 10,
    currentPage: 1,
    fetchAsync: false,
    lastPage: 1,
    totalItems: 0,
    hasMore: true,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    searchFields: [],
};

const slice = createEntityReducer({} as Questionnaire, APIEntity.Questionnaire, initialState, {}, undefined, {} as IQuestionnaireForm);

export const {
    slice: questionnairesSlice,
    asyncActions: questionnairesAsyncActions,
} = slice;

const questionnairesListSelector = (state: RootState) => state.questionnaires.entities;

export const questionnairesList = createSelector([questionnairesListSelector], (entities) => {
    return Object.values(entities);
});

function selectedQuestionnaire(state: RootState): Questionnaire | null {
    return state.questionnaires.selectedEntities.length === 1 ? (state.questionnaires.entities[state.questionnaires.selectedEntities[0]] ?? null) : null;
}

export const questionnaireOptions = createSelector([questionnairesListSelector], (entities) => {
    return Object.values(entities).map(ent => ({ label: ent.key, value: ent.id }));
});

export const questionnairesSelectors = {
    questionnairesList,
    selectedQuestionnaire,
    questionnaireOptions,
};
export const questionnairesActions = questionnairesSlice.actions;
export const questionnairesReducer = questionnairesSlice.reducer;
