import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import InputField, { InputType } from "src/components/Ui/InputField/InputField";
import { ILeadForm, ILeadFormErrors, LeadFormUtils } from "src/entities/Lead/LeadForm";
import { useTranslation } from "react-i18next";
import { FormDisplayMode } from "src/utils/type_utils";
import { Lead, LeadUtils } from "src/entities/Lead/Lead";
import Button, { ButtonStyle } from "src/components/Ui/Button/Button";
import classNames from "classnames";

interface LeadInfoProps {
    leadForm: ILeadForm,
    setLeadForm: (form: ILeadForm) => void,
    formDisplayMode: FormDisplayMode,
    leadEntity: Lead | null,
    onClose: () => void,
    onSubmit: () => void,
    saveAsync?: boolean,
}

function LeadInfo({
                      formDisplayMode,
                      leadForm,
                      setLeadForm,
                      leadEntity,
                      onClose,
                      onSubmit,
                      saveAsync,
                  }: LeadInfoProps) {

    const { t } = useTranslation();

    const [formErrors, setFormErrors] = useState<ILeadFormErrors>({});


    const updateForm = useCallback((data: ILeadForm) => {
        setLeadForm(LeadFormUtils.updateForm(leadForm, data));
    }, [leadForm, setLeadForm]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const field = e.target.name as keyof ILeadForm;
        updateForm({ [field]: e.target.value });
    }, [updateForm]);

    const isViewMode = formDisplayMode === FormDisplayMode.View;
    const isEditMode = formDisplayMode === FormDisplayMode.Edit;

    useEffect(() => {
        setFormErrors({});
    }, [leadEntity]);

    const handleClose = useCallback(() => {
        setFormErrors({});
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(() => {
        setFormErrors({});

        const preparedForm = LeadFormUtils.prepareForSend(leadForm);

        const errors = LeadFormUtils.validate(preparedForm);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        onSubmit();
    }, [onSubmit, leadForm]);

    if (!leadEntity) {
        return null;
    }

    const hasMainName = LeadUtils.hasMainName(leadEntity);

    return (
        <div className="entity-details-container">
            <div className="entity-details">
                <InputField
                    label={t("first_and_last_name")}
                    readonly={isViewMode}
                    className={classNames({ "input-italic": isViewMode && !hasMainName })}
                    name="fullName"
                    error={formErrors?.fullName}
                    showErrorText={true}
                    value={isViewMode ? hasMainName ? leadForm.fullName : LeadUtils.getDerivedName(leadEntity) : leadForm.fullName}
                    onChange={handleChange}/>

                <InputField onChange={handleChange}
                            label={t("phonetic_first_name")}
                            readonly={isViewMode}
                            value={leadForm.phoneticFirstName ?? ""}
                            name={"phoneticFirstName"}/>

                <InputField onChange={handleChange}
                            label={t("address")}
                            readonly={isViewMode}
                            value={leadForm.address ?? ""}
                            name={"address"}/>

                <InputField type={InputType.Phone}
                            value={leadForm.phone ?? ""}
                            label={t("telephone_number")}
                            readonly={isViewMode}
                            error={formErrors?.phone}
                            name={"phone"}
                            onChange={handleChange}
                />
            </div>
            <div className="footer">
                {!isViewMode ? (
                    <>
                        <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                                onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button className="submit-btn text-uppercase footer-button"
                                buttonStyle={ButtonStyle.Blue}
                                loading={saveAsync}
                                onClick={handleSubmit}>
                            {isEditMode ? t("save") : t("add")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button className="text-uppercase footer-button text-color-3 mr-auto"
                                buttonStyle={ButtonStyle.Text}
                                onClick={handleClose}>
                            {t("close")}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
}

export default LeadInfo;
