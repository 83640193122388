import { dateFnsLocale } from "./utils";
import { format } from "date-fns";

// when they add global locales, remove this
export function formatLocale(
    date: Date | number,
    dateFormat: string,
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    },
): string {
    return format(
        date,
        dateFormat,
        Object.assign({}, options, { locale: dateFnsLocale }),
    );
}
