import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    LeadQuery,
} from "src/entities/Lead/Lead";
import "./communication.scss";
import classNames from "classnames";
import { pageAnimationVariants } from "src/components/Ui/AnimatedHider/AnimatedHider";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import {
    leadsActions,
    leadsAsyncActions,
    leadsList,
} from "src/store/slices/leadSlice";
import FramerFormAnimationWrapper from "src/components/Ui/FramerFormAnimationWrapper/FramerFormAnimationWrapper";
import {
    form2AnimationNoMargin,
} from "src/components/Ui/AnimationVariants";
import LeadPanel from "./LeadPanel/LeadPanel";
import SearchInput from "src/components/Ui/SearchInput/SearchInput";
import { useDebounce } from "rooks";
import LazyLoadList from "../../components/Ui/LazyLoadList/LazyLoadList";
import { Waypoint } from "react-waypoint";
import LeadListItem from "./LeadListItem";
import { loginSelectors } from "../../store/slices/loginSlice";
import ColorfulIconButton, {
    ColorfulIconButtonVariant,
} from "../../components/Ui/ColorfulIconButton/ColorfulIconButton";
import { IconFilter } from "@tabler/icons";
import Dropdown, { DropdownAnchorEdge } from "../../components/Ui/Dropdown/Dropdown";
import DropdownItem from "../../components/Ui/Dropdown/DropdownItem";
import Checkbox from "../../components/Ui/Checkbox/Checkbox";
import Button from "../../components/Ui/Button/Button";

export interface CommunicationProps {
}

interface FiltersDropDownProps {
    updateQuery: (query: LeadQuery) => void;
}

function FiltersDropDown({ updateQuery }: FiltersDropDownProps) {
    const { t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filterArchivedUsers, setFilterArchivedUsers] = useState(false);
    const [originalFilterArchivedUsers, setOriginalFilterArchivedUsers] = useState(false);

    const queryIsArchived = useAppSelector((state) => state.leads.query?.isArchived);

    const dropdownAnchorRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = useCallback(() => {
        if (!dropdownOpen) {
            setOriginalFilterArchivedUsers(filterArchivedUsers);
        } else {
            setFilterArchivedUsers(originalFilterArchivedUsers);
        }

        setDropdownOpen(!dropdownOpen);
    }, [dropdownOpen, filterArchivedUsers, originalFilterArchivedUsers]);

    const applyFilters = useCallback(() => {
        updateQuery({ isArchived: filterArchivedUsers });

        setOriginalFilterArchivedUsers(filterArchivedUsers);

        setDropdownOpen(false);
    }, [filterArchivedUsers, updateQuery]);

    useEffect(() => {
        if (queryIsArchived) {
            setFilterArchivedUsers(queryIsArchived);
            setOriginalFilterArchivedUsers(queryIsArchived);
        }
    }, [queryIsArchived]);

    return (
        <>
            <div ref={dropdownAnchorRef}>
                <ColorfulIconButton className={classNames("filter-icon", { "active": filterArchivedUsers })}
                                    onClick={toggleDropdown}
                                    variant={ColorfulIconButtonVariant.TRANSPARENT}>
                    <IconFilter size={16}/>
                </ColorfulIconButton>
            </div>
            <Dropdown innerClassName="filters-dropdown"
                      anchorEdge={DropdownAnchorEdge.BottomLeft}
                      open={dropdownOpen}
                      closeOnInnerClick={false}
                      setOpen={() => {
                          setDropdownOpen(!dropdownOpen);
                          setFilterArchivedUsers(originalFilterArchivedUsers);
                      }}
                      anchorRef={dropdownAnchorRef}>
                <div className="filters-title">{t("filters")}</div>
                <DropdownItem>
                    <Checkbox checked={filterArchivedUsers}
                              onChange={setFilterArchivedUsers}
                              label={t("archived")}/>
                </DropdownItem>
                <Button className="apply-filters-btn"
                        onClick={applyFilters}>
                    {t("apply")}
                </Button>
            </Dropdown>
        </>
    );
}

function Communication({ ...props }: CommunicationProps) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const leads = useAppSelector(leadsList);

    const effectiveUser = useAppSelector(loginSelectors.effectiveUser);

    const fetchAsync = useAppSelector((state) => state.leads.fetchAsync);
    const currentPage = useAppSelector((state) => state.leads.currentPage);
    const totalItems = useAppSelector((state) => state.leads.totalItems);
    const hasMore = useAppSelector((state) => state.leads.hasMore);

    const selectedEntities = useAppSelector(
        (state) => state.leads.selectedEntities,
    );

    const [multiSelectModeOverride, setMultiSelectModeOverride] = useState(false);

    const query = useAppSelector(state => state.leads.query);

    const fetchFirstPage = useCallback(() => {
        dispatch(leadsAsyncActions.fetchEntityPage(1));
    }, [dispatch]);

    const debouncedFetch = useDebounce(fetchFirstPage, 500);

    const updateQuery = useCallback((query: LeadQuery) => {
        dispatch(leadsActions.updateQuery(query));
        if (!query.search) {
            fetchFirstPage();
        } else {
            debouncedFetch();
        }
    }, [dispatch, fetchFirstPage, debouncedFetch]);

    useEffect(() => {
        dispatch(leadsAsyncActions.fetchEntityPage(1));

        return () => {
            dispatch(leadsActions.unselectAll());
        };
    }, [dispatch, effectiveUser]);

    const onUnselectAll = useCallback(() => {
        dispatch(leadsActions.unselectAll());
    }, [dispatch]);

    const onSelectAll = useCallback(() => {
        dispatch(leadsActions.selectAll());
    }, [dispatch]);

    const onSelectEntity = useCallback(
        (id: string) => {
            setMultiSelectModeOverride(false);
            dispatch(leadsActions.singleSelectOrDeselectEntity(id));
        },
        [dispatch],
    );

    const onLoadNextPage = useCallback((args: Waypoint.CallbackArgs) => {
        if (fetchAsync || !hasMore) return;

        dispatch(leadsAsyncActions.fetchEntityPage(currentPage + 1));
    }, [dispatch, hasMore, currentPage, fetchAsync]);

    const isMultiselectChecked = selectedEntities.length > 1;

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageAnimationVariants}
            className={classNames("lead-communication page flex-column", { "panel-open": true })}>
            <div className="flex-row flex-1 lead-container">
                <div className="flex-1 lead-list">
                    <div className={classNames("header", { multiselect: isMultiselectChecked })}>

                        {isMultiselectChecked ? (
                            <div className={"text-medium text-white text-14"}>
                                {t("selected")}: {selectedEntities.length}
                            </div>
                        ) : (
                            <div className="flex-row align-center flex-1">
                                <SearchInput search={query?.search ?? ""}
                                             className={classNames({ "in-header": true })}
                                             onClear={() => updateQuery({ search: "" })}
                                             onSearch={input => updateQuery({ search: input })}
                                             fullBorder={true}
                                             showIcon={false}
                                />
                                <FiltersDropDown updateQuery={updateQuery}/>
                            </div>
                        )}
                    </div>
                    <LazyLoadList data={leads}
                                  autoUpdateIntervalMilis={1000 * 60}
                                  onLoadNext={onLoadNextPage}
                                  async={fetchAsync}>
                        {({ item }) => {
                            return (
                                <LeadListItem key={item.id}
                                    // onMultiselect={onMultiselectEntity}
                                              isSelected={selectedEntities?.includes(item.id)}
                                              onRowClick={onSelectEntity}
                                              multiSelectOverride={multiSelectModeOverride}
                                              lead={item}/>
                            );
                        }}
                    </LazyLoadList>
                    <div className="footer">
                        {leads.length} {t("pagination_of")} {totalItems}
                    </div>
                </div>
                <FramerFormAnimationWrapper
                    variants={form2AnimationNoMargin}
                    open={true}
                    className="form no-shadow"
                >
                    <LeadPanel multiSelectOverride={multiSelectModeOverride}/>
                </FramerFormAnimationWrapper>
            </div>
        </motion.div>
    );
}

export default Communication;
