import { API, ApiMethod } from "./api";
import { StorageKeys } from "../utils/storage_keys";
import { User } from "src/entities/User/User";
import { IUserForm } from "src/entities/User/UserForm";

export interface LoginResponse {
    token: string;
    refresh_token: string;
}

export class LoginApi {
    public static doLogin = async (email: string, password: string): Promise<LoginResponse | void> => {
        return API.jsonApiCall<LoginResponse>({
            url: API.login,
            method: ApiMethod.Post,
            noAuth: true,
            jsonData: {
                email,
                password,
            },
        }).then(r => {
            if (r != null) {
                localStorage.setItem(StorageKeys.Token, r.token);
                localStorage.setItem(StorageKeys.RefreshToken, r.refresh_token);
            }
        });
    };

    public static doMe = async (impersonateEmail: string | null = null): Promise<User | null> => {
        let url = new URL(API.me);

        if (impersonateEmail) {
            url.searchParams.append("impersonate", impersonateEmail);
        }

        return API.jsonApiCall<User>({
            url: url.toString(),
            method: ApiMethod.Get,
            allowImpersonate: false,
        });
    };

    public static editUser = async (userId: string, data: IUserForm): Promise<User | null> => {
        return await API.jsonApiCall<User>({
            url: API.users + `/${userId}`,
            method: ApiMethod.Put,
            jsonData: data as unknown as Record<string, unknown>,
        });
    };

    public static changeUserPassword = async (userId: string, password: string): Promise<User> => {
        return API.apiCall({
            url: API.users + `/${userId}/change_password`,
            method: ApiMethod.Put,
            jsonData: {
                password,
            },
        }).then(r => {
            return r?.json();
        });
    };
}
