import { User } from "src/entities/User/User";
import { MediaObject } from "../Mediaobject/MediaObject";

export interface IMessageTemplate {
    id: string,
    category: {
        id: string,
        name: string
    },
    text: string,
    user?: User,
    isPrivate: boolean,
    attachment: MediaObject | null,
}

export interface CreateMessageTemplate {
    text: string,
    category: string,
}

export interface IMessageTemplateCategory {
    id: string,
    name: string,
    messageTemplates: IMessageTemplate[],
    isPrivate: boolean,
    user?: User
}

export class MessageTemplateCategoryUtils {

    public static searchMessageTemplateCategory(messageTemplateCategories: IMessageTemplateCategory[], searchVal: string): IMessageTemplateCategory[] {
        if (!searchVal) {
            return messageTemplateCategories;
        } else {
            return messageTemplateCategories.filter(predefinedMessage => {
                    return predefinedMessage.messageTemplates.some(messageTemplate => messageTemplate.text.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase()));
                },
            );
        }
    }
}
