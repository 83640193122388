import "./spinner.scss";
import ClipLoader from "react-spinners/ClipLoader";

export interface SpinnerProps {
    readonly invert?: boolean,
    readonly color?: string,
    readonly small?: boolean,
    readonly size?: number,
}

const Spinner = ({ color, size, small = false, invert = false }: SpinnerProps) => {
    return (
        <div className="spinner-container">
            <ClipLoader color={color ?? (invert ? "#FFFFFF" : "#4D7CFE")} loading={true}
                        size={size ?? (small ? 20 : 60)}/>
        </div>
    );
};

export default Spinner;
