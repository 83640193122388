import { RefObject, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent

export function useOnClickOutside<T extends HTMLElement = HTMLElement, T2 extends HTMLElement = HTMLElement, T3 extends HTMLElement = HTMLElement, T4 extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: AnyEvent) => void,
    secondRef?: RefObject<T2>,
    thirdRef?: RefObject<T3>,
    fourthRef?: RefObject<T4>,
): void {
    useEffect(
        () => {
            const listener = (event: AnyEvent) => {
                const el = ref?.current;
                const secondEl = secondRef?.current;
                const thirdEl = thirdRef?.current;
                const fourthEl = fourthRef?.current;

                // Do nothing if clicking ref's element or descendent elements
                if (!el || el.contains(event.target as Node)) {
                    return;
                }

                if (secondEl?.contains(event.target as Node)) {
                    return;
                }

                if (thirdEl?.contains(event.target as Node)) {
                    return;
                }

                if (fourthEl?.contains(event.target as Node)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);

            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, secondRef, thirdRef, fourthRef, handler],
    );
}
