import React, { useEffect, useRef, useState } from "react";
import "./lead_gallery.scss";
import MediaObjectCard
    from "src/pages/Communication/LeadPanel/components/LeadGallery/GalleryCard/MediaObjectCard";
import { MediaObjectUtils } from "src/entities/Mediaobject/MediaObject";
import Lightbox from "react-image-lightbox";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AsyncContainer from "src/components/Ui/AsyncContainer/AsyncContainer";
import { Waypoint } from "react-waypoint";
import Spinner from "src/components/Ui/Spinner/Spinner";
import { MediaObject } from "../../../../../entities/Mediaobject/MediaObject";

export interface LeadGalleryProps {
    leadId: string;
}

const LeadGallery = ({ leadId }: LeadGalleryProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // const mediaObjects = useAppSelector(state => state.messenger.media);
    // const mediaAsync = useAppSelector(state => state.messenger.fetchMediaAsync);
    // const fetchMoreMediaAsync = useAppSelector(state => state.messenger.fetchMoreMediaAsync);

    // TODO This
    const mediaObjects: MediaObject[] = [];
    const mediaAsync = false;
    const fetchMoreMediaAsync = false;

    const mediaListRef = useRef<HTMLDivElement>(null);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const medias = mediaObjects.map(mediaObject => {
        return MediaObjectUtils.getUrl(mediaObject);
    });
    const loadingMedia = mediaAsync || fetchMoreMediaAsync;

    useEffect(() => {
        if (mediaListRef.current) {
            mediaListRef.current.scrollTop = 0;
        }
        loadMedia();
        //eslint-disable-next-line
    }, [leadId]);

    const loadMedia = async () => {
        if (loadingMedia) return;

        // await dispatch(
            // messengerAsyncActions.fetchMediaObjectsPageForLead(leadId, MediaObjectType.Media),
        // );
    };

    return (
        <AsyncContainer async={mediaAsync}>
            <div className="lead-gallery" ref={mediaListRef}>
                {(mediaObjects.length === 0 && !loadingMedia) ? (
                    <div className="flex-1 flex-center-both text-color-3">{t("gallery_is_empty")}</div>
                ) : (
                    mediaObjects.map((mediaObject, index) => {
                        return <MediaObjectCard key={mediaObject.id}
                                                mediaObject={mediaObject}
                                                onClick={() => {
                                                    setIsLightboxOpen(true);
                                                    setPhotoIndex(index);
                                                }}/>;
                    })
                )}

                <Waypoint onEnter={loadMedia}>
                    <div className="media-waypoint">
                        {loadingMedia && <Spinner size={35}/>}
                    </div>
                </Waypoint>

                {isLightboxOpen && (
                    <Lightbox
                        mainSrc={medias[photoIndex]}
                        nextSrc={medias[(photoIndex + 1) % medias.length]}
                        prevSrc={medias[(photoIndex + medias.length - 1) % medias.length]}
                        onCloseRequest={() => setIsLightboxOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + medias.length - 1) % medias.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % medias.length)}
                    />
                )}
            </div>
        </AsyncContainer>
    );
};

export default LeadGallery;
