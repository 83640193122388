import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridSlots,
    GridToolbarContainer,
    ValueOptions,
} from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { API, ApiMethod } from '../../../../../api/api';

interface PredefinedResponse {
    '@id': string;
    id: number;
    triggerEvent: string;
    trigger: string | null;
    response: string;
    responseType: string;
    keyboard: null | string;
    isNew: boolean;
}

interface ViberKeyboard {
    id: number;
    name: string;
    config: string;
}

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    topicId: string;
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel, topicId } = props;

    const handleClick = () => {
        const id = Math.random();
        setRows((oldRows) => [...oldRows, {
            id,
            triggerEvent: 'message_received',
            trigger: '',
            responseType: '',
            response: '',
            keyboard: null,
            isNew: true,
            topic: `/api/topics/${topicId}`,
        }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'trigger' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}

export default function TopicPredefinedResponsesTab({ topicId }: { topicId: string }) {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<PredefinedResponse[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [keyboards, setKeyboards] = useState<ViberKeyboard[]>([]);

    useEffect(() => {
        setLoading(true);
        const promises = [
            API.jsonApiCall<PredefinedResponse[]>({
                url: `${API.predefinedResponses}?topic=${topicId}`,
            }).then((r) => r && setRows(r)),
            API.jsonApiCall<ViberKeyboard[]>({
                url: API.viberKeyboards,
            }).then((r) => r && setKeyboards(r)),
        ];
        Promise.all(promises)
            .finally(() => {
                setLoading(false);
            });
    }, [topicId]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        if (!window.confirm('Da li ste sigurni?')) {
            return;
        }
        setLoading(true);
        API.apiCall({
            url: `${API.predefinedResponses}/${id}`,
            method: ApiMethod.Delete,
        })
            .then(() => {
                setRows(rows.filter((row) => row.id !== id));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow && typeof editedRow['@id'] === 'undefined') {
            setRows(rows.filter((row) => row.id !== id));
        } else {
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });
        }
    };

    const processRowUpdate = async (newRow: GridRowModel<PredefinedResponse>, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false } as Partial<PredefinedResponse>;

        delete updatedRow.id;
        delete updatedRow.isNew;

        if (!updatedRow.keyboard) {
            updatedRow.keyboard = null;
        }

        return await Promise.resolve<PredefinedResponse>((() => {
            setLoading(true);
            const endpoint = newRow.isNew
                ? API.predefinedResponses
                : `${API.predefinedResponses}/${oldRow.id}`;
            const method = newRow.isNew ? ApiMethod.Post : ApiMethod.Put;

            return API.strictJsonApiCall<PredefinedResponse>({
                url: endpoint,
                method,
                jsonData: updatedRow,
            })
                .then((response) => {
                    setRows(rows.map((row) => (row.id === oldRow.id ? response : row)));
                    return response;
                })
                // @ts-ignore
                .finally((response) => {
                    setLoading(false);
                    return response ?? updatedRow;
                });
        })());
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {
            field: 'triggerEvent',
            headerName: 'Trigger Event',
            type: 'singleSelect',
            valueOptions: ['message_received', 'message_sent', 'subscribed', 'unsubscribed', 'conversation_started', 'error'],
            width: 180,
            editable: true,
        },
        {
            field: 'trigger',
            headerName: 'Trigger',
            width: 220,
            editable: true,
        },
        {
            field: 'responseType',
            headerName: 'Response Type',
            type: 'singleSelect',
            valueOptions: ['text', 'rich_media_message'],
            width: 180,
            editable: true,
        },
        {
            field: 'response',
            headerName: 'Response',
            flex: 1,
            editable: true,
        },
        {
            field: 'keyboard',
            headerName: 'Keyboard',
            type: 'singleSelect',
            valueOptions: [{
                label: 'None',
                value: '',
            }, ...keyboards.map(x => ({ value: `/api/viber_keyboards/${x.id}`, label: x.name }))],
            flex: 1,
            getOptionValue: (x: ValueOptions) => typeof x === 'object' ? (x.value || '') : x,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                flex: 1,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel, topicId },
                }}
            />
        </Box>
    );
}
