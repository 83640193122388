import classNames from "classnames";
import React, { CSSProperties, MouseEventHandler, useRef } from "react";
import "./colorful_icon_button.scss";
import useHover from "../../../utils/hooks/useHover";

export enum ColorfulIconButtonVariant {
    BLUE = "blue",
    BLUE_WHITE = "blue-white",
    RED = "red",
    GREEN = "green",
    ORANGE = "orange",
    TRANSPARENT = "transparent",
    WHITE = "white",
}

export interface ColorfulIconButtonProps {
    readonly onClick?: MouseEventHandler<HTMLButtonElement>;
    readonly variant?: ColorfulIconButtonVariant;
    readonly hoverVariant?: ColorfulIconButtonVariant;
    readonly className?: string;
    readonly style?: CSSProperties;
    readonly disabled?: boolean;
    readonly children?:
        | React.ReactNode
        | React.ReactElement
        | React.ComponentType<any>;
}

const ColorfulIconButton = ({
                                style,
                                disabled,
                                onClick = () => {
                                },
                                variant = ColorfulIconButtonVariant.BLUE,
                                hoverVariant,
                                children,
                                className,
                                ...props
                            }: ColorfulIconButtonProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    const hover = useHover(ref);

    const isHoverVariantActive = hover && hoverVariant !== undefined;

    return (
        <button
            ref={ref}
            {...props}
            disabled={disabled}
            style={style}
            className={classNames(
                "colorful-icon-button",
                className,
                {
                    [`variant-${variant}`]: !isHoverVariantActive,
                    [`variant-${hoverVariant}`]: isHoverVariantActive,
                }
            )}
            onClick={(e) => {
                e.stopPropagation();
                onClick(e);
            }}
        >
            {children}
        </button>
    );
};

export default ColorfulIconButton;
