import "./messenger_message.scss";
import {
    Message,
    MessageContentType,
    MessageDeliveryStatus,
    MessageDirection,
    MessageUtils,
} from "src/entities/message/Message";
import classNames from "classnames";
import { IconCheck, IconChecks } from "@tabler/icons";
import { formatLocale } from "../../../../../utils/date_fns_format_locale";
import MessengerMessageAttachmentRenderer from "./MessengerMessageAttachmentRenderer";
import { useCallback } from "react";
import { Utils } from "../../../../../utils/utils";

export interface MessengerMessageDeliveryStatusProps {
    deliveryStatus: MessageDeliveryStatus;
}

export function MessengerMessageDeliveryStatus({
                                                   deliveryStatus,
                                               }: MessengerMessageDeliveryStatusProps) {
    switch (deliveryStatus) {
        case MessageDeliveryStatus.RECEIVED:
            return <span/>;
        case MessageDeliveryStatus.SENT:
            return <IconCheck/>;
        case MessageDeliveryStatus.DELIVERED:
        case MessageDeliveryStatus.SEEN:
            return <IconChecks/>;
        default:
            return <span>Unknown Status ${deliveryStatus}</span>;
    }
}

export interface MessengerMessageProps {
    message: Message;
    patientName: string;
}

export function MessengerMessage({
                                     patientName,
                                     message,
                                 }: MessengerMessageProps) {

    const hasAttachment = MessageUtils.hasAttachment(message);

    const renderMessageContent = useCallback(() => {

        if (!message.content) {
            return <span></span>;
        }

        if (message.contentType === MessageContentType.QUESTIONNAIRE) {
            if (message.direction === MessageDirection.INBOUND) {
                const qResults = Utils.parseJSON(message.content);

                if (!qResults) {
                    return <span></span>;
                }

                const entries: Array<{ label: string, value: string }> = Object.entries(qResults).map(([key, value]) => {
                    return {
                        label: Utils.keyToTitleCase(key),
                        value: value as string,
                    };
                });

                return (
                    <div className="message-content">
                        <div className="title"/>
                        <div className="entries">
                            {entries.map(e => (
                                <div className="message-entry" key={e.label}>
                                    <span className="label">{e.label.toString()}:</span>
                                    <span className="value">{e.value.toString()}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            }
        } else if ([MessageContentType.DISCLAIMER, MessageContentType.OPT_OUT, MessageContentType.OPT_IN].includes(message.contentType)) {
            return (
                <div className="message-content">
                    <span className="title">{message.content}</span>
                </div>
            );
        }

        // STANDARD MESSAGE
        return (
            <div className="message-content">{message.content}</div>
        );

    }, [message.content, message.contentType, message.direction]);

    // const displayAsSystemMessage = [MessageContentType.QUESTIONNAIRE, MessageContentType.DISCLAIMER, MessageContentType.OPT_IN, MessageContentType.OPT_OUT].includes(message.contentType);

    if (message.contentType === MessageContentType.QUESTIONNAIRE) {
        return null;
    }

    return (
        <div
            className={classNames("message", {
                inbound: message.direction === MessageDirection.INBOUND,
                outbound: message.direction === MessageDirection.OUTBOUND,
                // questionnaire: message.contentType === MessageContentType.QUESTIONNAIRE,
                disclaimer: message.contentType === MessageContentType.DISCLAIMER,
                'opt-in': message.contentType === MessageContentType.OPT_IN,
                'opt-out': message.contentType === MessageContentType.OPT_OUT,
            })}
        >
            <div className="bubble">
                {hasAttachment && (
                    <MessengerMessageAttachmentRenderer attachments={message.attachments}/>
                )}

                {(message.content?.length || !hasAttachment) && (
                    renderMessageContent()
                )}
            </div>
            <div className="message-bottom-content">
                <div className="message-created-at">
                    {formatLocale(new Date(message.createdAt), "HH:mm")}
                </div>
                <div className={classNames("message-status", `status-${message.deliveryStatus}`)}>
                    <MessengerMessageDeliveryStatus
                        deliveryStatus={message.deliveryStatus}
                    />
                </div>
            </div>
        </div>
    );
}

export default MessengerMessage;
