import i18n from "i18next";
import translationEn from "./en/translation.json";
import translationBs from "./bs/translation.json";
import { initReactI18next } from "react-i18next";

export type Exact<T, SHAPE> = T extends SHAPE ? (Exclude<keyof T, keyof SHAPE> extends never ? T : never) : never;

export const isExact =
    <ExpectedShape>() =>
        <ActualShape>(x: Exact<ActualShape, ExpectedShape>): ExpectedShape => {
            return x;
        };


const hasSameKeysAsBs = isExact<typeof translationBs>();

hasSameKeysAsBs(translationEn);

export const resources = {
    en: {
        translation: translationEn,
    },
    bs: {
        translation: translationBs
    }
} as const;

i18n.use(initReactI18next).init({
    lng: "bs",
    fallbackLng: "bs",
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
});

export default i18n;
