import React, { useCallback, useRef, useState } from "react";
import "./questionnaire-card.scss";
import classNames from "classnames";
import LinesEllipsis from "react-lines-ellipsis";
import { IconCaretDown, IconCaretUp, IconChevronDown, IconCirclePlus, IconPencil } from "@tabler/icons";
import { AnimatePresence, motion } from "framer-motion";
import useHover from "src/utils/hooks/useHover";
import {
    Answer,
    PathIndex,
    Question,
    QuestionnaireFormMode,
    QuestionnaireUtils,
} from "src/entities/questionnaire/Questionnaire";
import { WithClassname } from "src/utils/type_utils";
import ReactTooltip from "react-tooltip";
import { useAppSelector } from "src/store/hooks";
import { questionnairesSelectors } from "src/store/slices/questionnariesSlice";
import { useTranslation } from "react-i18next";

export interface QuestionnaireCardProp {
    pathIndexes: Array<PathIndex>;
    entity: Question | Answer;
    formDisplayMode: QuestionnaireFormMode;
    setDisplayMode: (mode: QuestionnaireFormMode) => void;
    setSelectedEntity: (question: Question | Answer) => void;
    setSelectedEntityPathIndexes: (pathIndexes: PathIndex[]) => void;
    displayIcons?: boolean;
    onMove: (direction: "up" | "down", pathIndexes: Array<PathIndex>) => void;
}

const QuestionnaireCard = ({
                               pathIndexes,
                               entity,
                               formDisplayMode,
                               setDisplayMode,
                               className,
                               setSelectedEntityPathIndexes,
                               displayIcons = true,
                               setSelectedEntity,
                               onMove,
                           }: WithClassname<QuestionnaireCardProp>) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isQuestionTextClamped, setIsQuestionTextClamped] = useState(false);
    const selectedQuestionnaireConfig = useAppSelector(questionnairesSelectors.selectedQuestionnaire)?.config;

    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    const isTextualAnswer = typeof entity === "string";
    const isAnswer = pathIndexes[pathIndexes.length - 1].array === "answers" || isTextualAnswer;

    const entityText = isTextualAnswer ? entity : entity.attachment?.originalFileName ?? entity.text;
    const canBeExpanded = !isTextualAnswer && (Boolean(entity.followupQuestions?.length) || Boolean(entity.answers?.length));

    const parentQuestion = selectedQuestionnaireConfig && QuestionnaireUtils.resolveQPathIndexesToQuestion(selectedQuestionnaireConfig, pathIndexes.slice(0, -1));
    const parentArrayLength = isAnswer ? parentQuestion?.answers?.length : parentQuestion?.followupQuestions?.length;

    const lastPathIndex = pathIndexes[pathIndexes.length - 1].index;
    const isIconMoveUpDisabled = lastPathIndex === 0;
    const isIconMoveDownDisabled = parentQuestion ? (parentArrayLength === lastPathIndex + 1) : (selectedQuestionnaireConfig && selectedQuestionnaireConfig.length === lastPathIndex + 1);

    const handleReflow = (rleState: { clamped: boolean, text: string }) => {
        setIsQuestionTextClamped(rleState.clamped);
    };

    const onSelectEntity = useCallback(() => {
        setSelectedEntityPathIndexes(pathIndexes);
        setSelectedEntity(entity);
        setDisplayMode(QuestionnaireFormMode.View);
    }, [pathIndexes, entity, setDisplayMode, setSelectedEntity, setSelectedEntityPathIndexes]);

    return (
        <div
            className={classNames("questionnaire-card", className, `type-${entity.answerType ? "answer" : entity.questionType}`)}
            ref={hoverRef}>
            <div className={classNames("card-header", { expanded: isExpanded })}
                 onClick={onSelectEntity}>
                <div className="flex-column mr-10">
                    <IconCaretUp size={18}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     onMove("up", pathIndexes);
                                 }}
                                 className={classNames("move-icon", { disabled: isIconMoveUpDisabled })}/>
                    <IconCaretDown size={18}
                                   onClick={(e) => {
                                       e.stopPropagation();
                                       onMove("down", pathIndexes);
                                   }}
                                   className={classNames("move-icon", { disabled: isIconMoveDownDisabled })}/>
                </div>
                <LinesEllipsis text={entityText}
                               maxLine="1"
                               ellipsis="..."
                               className="question-text"
                               onReflow={handleReflow}
                               data-for="question-text"
                               data-tip={entityText}
                               data-tip-disable={!isQuestionTextClamped}
                />
                <ReactTooltip id="question-text"/>
                {displayIcons && (
                    <div className="ml-auto flex-row">
                        {/*<AnimatedHider open={isHover} className="mr-16">*/}
                        <IconPencil className="header-icon"
                                    size={18}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayMode(isAnswer ? QuestionnaireFormMode.EditAnswer : QuestionnaireFormMode.EditQuestion);
                                        setSelectedEntity(entity);
                                        setSelectedEntityPathIndexes(pathIndexes);
                                    }}
                                    data-tip={t("edit") + " " + (isAnswer ? t("answer") : t("question"))}/>
                        {!isTextualAnswer && (
                            <IconCirclePlus className="header-icon ml-10"
                                            size={18}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDisplayMode(QuestionnaireFormMode.AddQuestion);
                                                setSelectedEntity(entity);
                                                setSelectedEntityPathIndexes(pathIndexes);
                                            }}
                                            data-tip={t("add") + " " + t("subquestion")}/>
                        )}
                        {canBeExpanded &&
                            <IconChevronDown size={20}
                                             className="card-expanded-indicator ml-10"
                                             onClick={(e) => {
                                                 e.stopPropagation();
                                                 setIsExpanded(!isExpanded);
                                             }}/>}
                    </div>
                )}
            </div>
            <AnimatePresence>
                {canBeExpanded && isExpanded && (
                    <motion.div style={{ overflow: "hidden" }}
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}>
                        {Boolean(entity.followupQuestions?.length) &&
                            entity.followupQuestions?.map((question, index) =>

                                <QuestionnaireCard key={index}
                                                   pathIndexes={[...pathIndexes, { index, array: "followupQuestions" }]}
                                                   entity={question}
                                                   formDisplayMode={formDisplayMode}
                                                   setDisplayMode={setDisplayMode}
                                                   setSelectedEntity={setSelectedEntity}
                                                   setSelectedEntityPathIndexes={setSelectedEntityPathIndexes}
                                                   onMove={onMove}
                                />,
                            )}
                        {Boolean(entity.answers?.length) && (
                            <div className="flex-column ml-10">
                                <span className="mb-8 mt-8">
                                    Answers:
                                </span>
                                {entity.answers?.map((answer, index) => {
                                        return <QuestionnaireCard key={index}
                                                                  pathIndexes={[...pathIndexes, {
                                                                      index,
                                                                      array: "answers",
                                                                  }]}
                                                                  entity={answer}
                                                                  formDisplayMode={formDisplayMode}
                                                                  setDisplayMode={setDisplayMode}
                                                                  setSelectedEntity={setSelectedEntity}
                                                                  setSelectedEntityPathIndexes={setSelectedEntityPathIndexes}
                                                                  onMove={onMove}
                                        />;
                                    },
                                )}
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
            <ReactTooltip place={"bottom"}/>
        </div>
    );
};

export default QuestionnaireCard;
