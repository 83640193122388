import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./questionnaire.scss";
import { pageAnimationVariants } from "src/components/Ui/AnimatedHider/AnimatedHider";
import { motion } from "framer-motion";
import {
    Answer,
    PathIndex,
    Question,
    Questionnaire,
    QuestionnaireFormMode,
    QuestionnaireUtils,
} from "src/entities/questionnaire/Questionnaire";
import QuestionnaireCard from "src/pages/Questionnaire/components/QuestionnaireCard/QuestionnaireCard";
import FramerFormAnimationWrapper from "src/components/Ui/FramerFormAnimationWrapper/FramerFormAnimationWrapper";
import { form2Animation } from "src/components/Ui/AnimationVariants";
import QuestionnaireForm from "src/pages/Questionnaire/components/QuestionnaireForm/QuestionnaireForm";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Button, { ButtonStyle } from "../../components/Ui/Button/Button";
import { useTranslation } from "react-i18next";
import ColorfulIconButton from "../../components/Ui/ColorfulIconButton/ColorfulIconButton";
import { IconCopy } from "@tabler/icons";
import ReactTooltip from "react-tooltip";
import SelectField, { SelectFieldOption } from "../../components/Ui/SelectField/SelectField";
import AsyncContainer from "../../components/Ui/AsyncContainer/AsyncContainer";
import { cloneDeep } from "lodash";
import {
    questionnairesActions,
    questionnairesAsyncActions,
    questionnairesSelectors,
} from "../../store/slices/questionnariesSlice";
import AddQuestionnaireModal from "./AddQuestionnaireModal";

const QuestionnairePage = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const selectedQuestionnaire = useAppSelector(questionnairesSelectors.selectedQuestionnaire);
    const questionnaireOptions = useAppSelector(questionnairesSelectors.questionnaireOptions);

    const fetchAsync = useAppSelector(state => state.questionnaires.fetchAsync);

    const selectedQuestionnaireOption = useMemo(() => {
        return questionnaireOptions.find((o: SelectFieldOption) => o.value === selectedQuestionnaire?.id) ?? null;
    }, [questionnaireOptions, selectedQuestionnaire?.id]);

    const selectQuestionnaire = useCallback((option: SelectFieldOption | null) => {
        if (option) {
            dispatch(questionnairesActions.singleSelectEntity(option.value));
        }
    }, [dispatch]);

    const [displayMode, setDisplayMode] = useState<QuestionnaireFormMode>(QuestionnaireFormMode.None);
    const [selectedEntity, setSelectedEntity] = useState<Question | Answer | null>(null);
    const [selectedQuestionPathIndexes, setSelectedQuestionPathIndexes] = useState<PathIndex[]>([]);

    const isFormOpened = displayMode !== QuestionnaireFormMode.None;

    useEffect(() => {
        dispatch(questionnairesAsyncActions.fetchAllEntities()).then(r => {
            if (r?.length) {
                dispatch(questionnairesActions.singleSelectEntity(r[0].id));
            }
        });
    }, [dispatch]);

    const addRootQuestion = useCallback(() => {
        setDisplayMode(QuestionnaireFormMode.AddQuestion);
        setSelectedEntity(null);
        setSelectedQuestionPathIndexes([]);
    }, []);

    const onCopyToClipboard = useCallback(() => {
        if (selectedQuestionnaire) {
            const json = JSON.stringify(QuestionnaireUtils.formatQuestionnaireConfigForSubmit(selectedQuestionnaire.config));
            navigator.clipboard.writeText(json);
        }
    }, [selectedQuestionnaire]);

    const onNewQuestionnaireAdded = useCallback((questionnaire: Questionnaire) => {
        dispatch(questionnairesActions.singleSelectEntity(questionnaire.id));
    }, [dispatch]);

    const deleteSelectedQuestionnaire = useCallback(async () => {
        if (selectedQuestionnaire) {
            await dispatch(questionnairesAsyncActions.deleteEntity(selectedQuestionnaire.id));

            if (questionnaireOptions.length > 0) {
                dispatch(questionnairesActions.singleSelectEntity(questionnaireOptions[0].value));
            }
        }
    }, [selectedQuestionnaire, questionnaireOptions]);

    const onMoveQuestionnaireCard = useCallback(async (direction: "up" | "down", pathIndexes: Array<PathIndex>) => {
        if (selectedQuestionnaire) {
            const moveUp = direction === "up";
            const isRootQuestion = pathIndexes.length === 1;

            const configClone = cloneDeep(selectedQuestionnaire.config);
            const entityPathIndex = pathIndexes[pathIndexes.length - 1];
            const entityIndex = entityPathIndex.index;
            const entityToMove = QuestionnaireUtils.resolveQPathIndexesToQuestion(configClone, pathIndexes) as Question | Answer;

            if (entityIndex === 0 && moveUp) {
                return;
            }

            if (isRootQuestion) {
                configClone.splice(entityIndex, 1);
                configClone.splice(moveUp ? entityIndex - 1 : entityIndex + 1, 0, entityToMove as Question);
            } else {
                const entity = QuestionnaireUtils.resolveQPathIndexesToQuestion(configClone, pathIndexes.slice(0, -1)) as Question;
                const entityArray = entityPathIndex.array === "answers" ? entity.answers : entity.followupQuestions;

                entityArray?.splice(entityIndex, 1);
                entityArray?.splice(moveUp ? entityIndex - 1 : entityIndex + 1, 0, entityToMove as Question);
            }

            await dispatch(questionnairesAsyncActions.updateEntityAction({
                entityId: selectedQuestionnaire.id,
                entityForm: {
                    config: QuestionnaireUtils.formatQuestionnaireConfigForSubmit(configClone),
                },
            }));
        }

        setDisplayMode(QuestionnaireFormMode.None);
    }, [dispatch, selectedQuestionnaire]);

    return (
        <motion.div initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageAnimationVariants}
                    className="questionnaire page flex-row">
            <div className="flex-column flex-0333">
                <div className="questionnaire-picker mb-16">
                    <AsyncContainer async={fetchAsync} size={20}>
                        <SelectField value={selectedQuestionnaireOption}
                                     className="select-questionnaire mr-10"
                                     options={questionnaireOptions}
                                     onChange={selectQuestionnaire}/>
                    </AsyncContainer>
                    <Button buttonStyle={ButtonStyle.Red}
                            className="mr-10"
                            onClick={deleteSelectedQuestionnaire}>
                        + {t("remove")}
                    </Button>
                    <Button onClick={() => setAddModalOpen(true)}>
                        + {t("add")}
                    </Button>
                </div>
                <div className="questions-wrapper">
                    <div className={"questions-container"}>
                        <div className="questions-header">
                            <ColorfulIconButton onClick={onCopyToClipboard}
                                                data-tip={t("copy_json_to_clipboard")}>
                                <IconCopy/>
                            </ColorfulIconButton>
                        </div>
                        <div className="questions-body">
                            {selectedQuestionnaire && selectedQuestionnaire.config.map((rootQuestion, index) => {
                                    return <QuestionnaireCard key={index}
                                                              pathIndexes={[{ index, array: "followupQuestions" }]}
                                                              entity={rootQuestion}
                                                              formDisplayMode={displayMode}
                                                              setDisplayMode={setDisplayMode}
                                                              className={"root-questionnaire-card"}
                                                              setSelectedEntity={setSelectedEntity}
                                                              setSelectedEntityPathIndexes={setSelectedQuestionPathIndexes}
                                                              onMove={onMoveQuestionnaireCard}
                                    />;
                                },
                            )}
                        </div>
                        <div className="questions-footer">
                            <Button onClick={addRootQuestion}
                                    className="add-root-question-btn"
                                    disabled={!selectedQuestionnaire}>
                                {t("add")} {t("question")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <FramerFormAnimationWrapper open={isFormOpened}
                                        className="form "
                                        variants={form2Animation}>
                <QuestionnaireForm
                    formDisplayMode={displayMode}
                    onClose={() => {
                        setDisplayMode(QuestionnaireFormMode.None);
                    }}
                    setDisplayMode={setDisplayMode}
                    entityPathIndexes={selectedQuestionPathIndexes}
                    setSelectedEntityPathIndexes={setSelectedQuestionPathIndexes}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                />
            </FramerFormAnimationWrapper>
            <ReactTooltip place={"bottom"}/>
            <AddQuestionnaireModal open={addModalOpen}
                                   onQuestionnaireAdded={onNewQuestionnaireAdded}
                                   setOpen={setAddModalOpen}/>
        </motion.div>
    );
};

export default QuestionnairePage;
