import { User } from "../User/User";
import { MediaObject } from "../Mediaobject/MediaObject";
import { IconBrandWhatsapp, IconMessage, IconQuestionMark } from "@tabler/icons";

export enum MessageChannel {
    VIBER = "VIBER",
    SMS = "SMS"
}

export enum MessageDirection {
    INBOUND = "INBOUND",
    OUTBOUND = "OUTBOUND",
}

export enum MessageDeliveryStatus {
    SENT = "SENT",
    DELIVERED = "DELIVERED",
    RECEIVED = "RECEIVED",
    SEEN = "SEEN",
}

export enum MessageContentType {
    STANDARD = "STANDARD",
    QUESTIONNAIRE = "QUESTIONNAIRE",
    DISCLAIMER = "DISCLAIMER",
    OPT_IN = "OPT_IN",
    OPT_OUT = "OPT_OUT"
}

export interface Message {
    id: string;
    createdAt: string;
    channel: MessageChannel.VIBER;
    direction: MessageDirection;
    content: string | null;
    deliveryStatus: MessageDeliveryStatus;
    commServiceId: string | null;
    user: User | null;
    attachments: MediaObject[],
    contentType: MessageContentType
}

export class MessageUtils {
    public static hasAttachment(msg: Message): boolean {
        return msg.attachments.length !== 0;
    }

    public static isSystemMessage(msg: Message): boolean {
        try {
            if (!msg.content) {
                return false;
            }

            JSON.parse(msg.content);

            return true;
        } catch (e) {
            return false;
        }
    }

    public static getChannelIcon(channel: MessageChannel | null) {
        if (channel === MessageChannel.VIBER) {
            return IconBrandWhatsapp;
        } else if (channel === MessageChannel.SMS) {
            return IconMessage;
        } else {
            return IconQuestionMark;
        }
    }
}

export interface MessageWithLeadId extends Message {
    lead: { id: string };
}
