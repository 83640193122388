import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { usersActions, usersAsyncActions, userSelectors } from "src/store/slices/usersSlice";
import CustomTable, {
    CustomTableColumn, CustomTableRowContentOptions,
} from "../../components/Ui/CustomTable/CustomTable";
import { User, UsersQuery, UserUtils } from "src/entities/User/User";
import "./users.scss";
import ManageUser from "./components/ManageUser/ManageUser";
import ColorfulIconButton from "../../components/Ui/ColorfulIconButton/ColorfulIconButton";
import { IconArchive, IconEdit } from "@tabler/icons";
import AlertUtils from "src/components/Ui/Alert/alert_utils";
import EntityToolbar from "src/components/EntityToolbar/EntityToolbar";
import SelectField, { SelectFieldVariant } from "src/components/Ui/SelectField/SelectField";
import AnimatedHider, { pageAnimationVariants } from "../../components/Ui/AnimatedHider/AnimatedHider";
import { useTranslation } from "react-i18next";
import SearchInput from "src/components/Ui/SearchInput/SearchInput";
import { FormDisplayMode } from "src/utils/type_utils";
import { motion } from "framer-motion";
import { form4Animation } from "src/components/Ui/AnimationVariants";
import FramerFormAnimationWrapper from "src/components/Ui/FramerFormAnimationWrapper/FramerFormAnimationWrapper";
import { Utils } from "src/utils/utils";
import { useDebounce } from "rooks";
import ReactTooltip from "react-tooltip";

export interface UsersProps {
}

const Users = (props: UsersProps) => {
    const { t } = useTranslation();

    const [displayMode, setDisplayMode] = useState<FormDisplayMode>(FormDisplayMode.None);

    const dispatch = useAppDispatch();
    const users = useAppSelector(userSelectors.usersList);
    const selectedEntities = useAppSelector(state => state.users.selectedEntities);
    const currentPage = useAppSelector(state => state.users.currentPage);
    const lastPage = useAppSelector(state => state.users.lastPage);
    const usersAsync = useAppSelector(state => {
        const { fetchAsync, deleteAsync, addEditAsync } = state.users;
        return fetchAsync || deleteAsync || addEditAsync;
    });

    const isFormOpened = displayMode !== FormDisplayMode.None;

    const usersQuery = useAppSelector(state => state.users.query);

    const roleFilter = Utils.findSelectOption(UserUtils.userRoleOptions, usersQuery?.roles);

    const fetchFirstPage = useCallback(() => {
        dispatch(usersAsyncActions.fetchEntityPage(1))
    }, [dispatch])

    const debouncedFetch = useDebounce(fetchFirstPage , 500);

    const updateQuery = useCallback((query: UsersQuery) => {
        dispatch(usersActions.updateQuery(query));
        if (!query.search) {
            dispatch(usersAsyncActions.fetchEntityPage(1));
        } else {
            debouncedFetch();
        }
    }, [dispatch, debouncedFetch]);

    useEffect(() => {
        dispatch(usersAsyncActions.fetchEntityPage(1));

        return () => {
            dispatch(usersActions.unselectAll());
        };
    }, [dispatch]);

    const switchPage = useCallback((page) => {
        dispatch(usersActions.unselectAll());
        dispatch(usersAsyncActions.fetchEntityPage(page));
    }, [dispatch]);

    const onSelectAll = () => {
        dispatch(usersActions.selectAll());
    };

    const onUnselectAll = useCallback(() => {
        dispatch(usersActions.unselectAll());
    }, [dispatch]);

    const onMultiselectEntity = (id: string) => {
        setDisplayMode(FormDisplayMode.None);
        dispatch(usersActions.selectOrDeselectEntity(id));
    };

    const onEditUserClick = useCallback(id => {
        dispatch(usersActions.singleSelectEntity(id));
        setDisplayMode(FormDisplayMode.Edit);
    }, [dispatch]);

    const onArchiveAllClick = async () => {
        const r = await AlertUtils.dangerAlert({
            title: t("archiving"),
            message: t("are_you_sure_you_want_to_archive_selected_users") + "?",
            positiveButtonText: t("archive"),
        });

        if (r) {
            for (const selectedUserId of selectedEntities) {
                await dispatch(usersAsyncActions.archiveEntity(selectedUserId));
            }
        }
    };

    const onCloseForm = useCallback(() => {
        setDisplayMode(FormDisplayMode.None);
        dispatch(usersActions.unselectAll());
    }, [dispatch]);

    const onSelectEntity = useCallback((id: string) => {
        if (selectedEntities.find(entityId => entityId === id)) {
            setDisplayMode(FormDisplayMode.None);
        } else {
            setDisplayMode(FormDisplayMode.View);
        }
        dispatch(usersActions.singleSelectOrDeselectEntity(id));
    }, [dispatch, selectedEntities]);

    const onAddEntityClick = useCallback(() => {
        setDisplayMode(FormDisplayMode.Add);
        onUnselectAll();
    }, [onUnselectAll]);


    const renderUserIdEmailColumn = (user: User) => {
        return (
            <>
                <div className="text-14 text-color-1">{user.email}</div>
            </>
        );
    };

    const renderUserNameColumn = (user: User) => {
        return (
            <>
                <div className="text-16 text-color-1">{UserUtils.mergeName(user)}</div>
                <div className="text-14 text-color-3">{UserUtils.getUserRoleText(user)}</div>
            </>
        );
    };

    const renderUserTopicColumn = (user: User) => {
        return user.topic?.name || "";
    };

    const renderUserPhoneColumn = (user: User) => {
        return user.phone || "";
    };

    const renderUserActionsColumn = (user: User, { isMultiselectActive }: CustomTableRowContentOptions) => {
        return (
            <AnimatedHider open={!isMultiselectActive}>
                <div className="table-column-horizontal flex-center-both">
                    <ColorfulIconButton onClick={() => onEditUserClick(user.id)}
                                        data-tip={t("edit")}>
                        <IconEdit size={18}/>
                    </ColorfulIconButton>
                </div>
                <ReactTooltip place={"bottom"}/>
            </AnimatedHider>
        );
    };

    const columns: CustomTableColumn<User>[] = [{
        headerContent: t("email"),
        name: "user_id_email",
        rowContent: renderUserIdEmailColumn,
    }, {
        headerContent: t("first_and_last_name"),
        name: "user_first_last_name",
        rowContent: renderUserNameColumn,
    }, {
        headerContent: t("telephone"),
        minTableWidth: 1000,
        name: "phone",
        rowContent: renderUserPhoneColumn,
    }, {
        headerContent: t("topic"),
        minTableWidth: 1000,
        name: "topic",
        rowContent: renderUserTopicColumn,
    }, {
        headerContent: "",
        name: "actions",
        width: "6.25rem",
        minTableWidth: 1000,
        rowContent: renderUserActionsColumn,
    }];

    return (
        <motion.div initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageAnimationVariants}
                    className="users page flex-column">
            <div className="flex-row flex-1">
                <div className="flex-column flex-1 ">
                    <EntityToolbar onAddEntityClick={onAddEntityClick} isFormOpened={isFormOpened}>
                        <SelectField options={UserUtils.userRoleOptions}
                                     value={roleFilter}
                                     placeholder={t("title")}
                                     className="user-role-picker"
                                     clearable={true}
                                     onChange={option => updateQuery({ roles: option?.value })}
                                     selectFieldStyle={SelectFieldVariant.Dark}/>
                        <SearchInput placeholder={t("search_placeholder")}
                                     className="users-search-input"
                                     search={usersQuery?.search ?? ""}
                                     onClear={() => updateQuery({ search: "" })}
                                     onSearch={input => updateQuery({ search: input })}
                                     fullBorder={true}/>
                    </EntityToolbar>
                    <div className="table">
                        <CustomTable columns={columns}
                                     onSwitchPage={switchPage}
                                     currentPage={currentPage}
                                     lastPage={lastPage}
                                     isFullHeight={true}
                                     onSelectAll={onSelectAll}
                                     onUnselectAll={onUnselectAll}
                                     onMultiselect={!isFormOpened ? onMultiselectEntity : undefined}
                                     isLoading={usersAsync}
                                     hasMore={currentPage < lastPage}
                                     data={users}
                                     selectedItems={selectedEntities}
                                     onRowClick={onSelectEntity}
                                     isSortEnabled={true}
                                     multiselectAction={onArchiveAllClick}
                                     multiselectActionIcon={IconArchive}
                                     multiselectActionName={t("archive")}/>
                    </div>
                </div>
                <FramerFormAnimationWrapper open={isFormOpened}
                                            className="form offset-top"
                                            variants={form4Animation}>
                    <ManageUser formDisplayMode={displayMode}
                                onClose={onCloseForm}
                                setDisplayMode={setDisplayMode}/>
                </FramerFormAnimationWrapper>
            </div>
        </motion.div>
    );
};

export default Users;
