import { BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { APIEntity } from "src/api/api";
import { RootState } from "../store";
import { createSelector, PayloadAction } from "@reduxjs/toolkit";
import {
    IMessageTemplate,
    IMessageTemplateCategory,
} from "src/entities/messageTemplateCategory/IMessageTemplateCategory";
import { IMessageTemplateCategoryForm } from "src/entities/messageTemplateCategory/MessageTemplateCategoryForm";

interface MessageTemplateCategoriesState extends BaseEntityReducerState<IMessageTemplateCategory> {

}

const initialState: MessageTemplateCategoriesState = {
    entities: {},
    perPage: 15,
    currentPage: 1,
    fetchAsync: false,
    lastPage: 1,
    selectedEntities: [],
    totalItems: 0,
    hasMore: true,
    addEditAsync: false,
    deleteAsync: false,
    searchFields: [],
};

const slice = createEntityReducer({} as IMessageTemplateCategory, APIEntity.MessageTemplateCategories, initialState, {
    addMessageTemplateForCategory: (state, { payload }: PayloadAction<IMessageTemplate>) => {
        const category = state.entities[payload.category.id];

        if (category) {
            category.messageTemplates.push(payload);
        }
    },
    removeMessageTemplateFromCategory: (state, { payload }: PayloadAction<IMessageTemplate>) => {
        const category = state.entities[payload.category.id];

        if (category) {
            const templateIdx = category.messageTemplates.findIndex(t => t.id === payload.id);

            if (templateIdx !== -1) {
                category.messageTemplates.splice(templateIdx, 1);
            }
        }
    },
}, undefined, {} as IMessageTemplateCategoryForm);

const messageTemplateCategoriesSelector = (state: RootState) => state.messageTemplateCategories.entities;

export const messageTemplateCategoriesList = createSelector([messageTemplateCategoriesSelector], (entities) => {
    return Object.values(entities);
});

export const { slice: messageTemplateCategoriesSlice, asyncActions } = slice;

export const messageTemplateCategoriesAsyncActions = {
    ...asyncActions,
};

export const messageTemplateCategoriesSelectors = {
    messageTemplateCategoriesList,
};

export const messageTemplateCategoriesActions = messageTemplateCategoriesSlice.actions;
export const messageTemplateCategoriesReducer = messageTemplateCategoriesSlice.reducer;
