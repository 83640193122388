import { API, ApiMethod } from "./api";

export class UsersApi {
    static async dangerouslyDeleteUser(userId: string): Promise<boolean | null> {
        return await API.jsonApiCall({
            url: `${API.super}/users/${userId}`,
            method: ApiMethod.Delete,
            jsonData: {},
        }).then(r => true);
    }
}
