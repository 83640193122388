import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    topicsActions,
    topicsAsyncActions,
    topicSelectors,
} from "src/store/slices/topicsSlice";
import CustomTable, {
    CustomTableColumn,
    CustomTableRowContentOptions,
} from "../../components/Ui/CustomTable/CustomTable";
import { Topic, TopicsQuery } from "src/entities/Topic/Topic";
import "./topics.scss";
import { IconArchive, IconEdit } from "@tabler/icons";
import ColorfulIconButton from "../../components/Ui/ColorfulIconButton/ColorfulIconButton";
import ManageTopic from "./components/ManageTopic/ManageTopic";
import AlertUtils from "../../components/Ui/Alert/alert_utils";
import AnimatedHider from "src/components/Ui/AnimatedHider/AnimatedHider";
import { useTranslation } from "react-i18next";
import { FormDisplayMode } from "src/utils/type_utils";
import FramerFormAnimationWrapper from "src/components/Ui/FramerFormAnimationWrapper/FramerFormAnimationWrapper";
import { form5Animation } from "src/components/Ui/AnimationVariants";
import SearchInput from "src/components/Ui/SearchInput/SearchInput";
import EntityToolbar from "src/components/EntityToolbar/EntityToolbar";
import { useDebounce } from "rooks";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

export interface TopicsProps {
    message: string;
}

const Topics = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [displayMode, setDisplayMode] = useState<FormDisplayMode>(
        FormDisplayMode.None,
    );

    const isFormOpened = displayMode !== FormDisplayMode.None;

    const hcQuery = useAppSelector(state => state.topics.query);

    const fetchFirstPage = useCallback(() => {
        dispatch(topicsAsyncActions.fetchEntityPage(1));
    }, [dispatch]);

    const debouncedFetch = useDebounce(fetchFirstPage, 500);

    const updateQuery = useCallback((query: TopicsQuery) => {
        dispatch(topicsActions.updateQuery(query));
        if (!query.search) {
            dispatch(topicsAsyncActions.fetchEntityPage(1));
        } else {
            debouncedFetch();
        }
    }, [dispatch, debouncedFetch]);

    useEffect(() => {
        dispatch(topicsAsyncActions.fetchEntityPage(1));

        return () => {
            dispatch(topicsActions.unselectAll());
        };
    }, [dispatch]);


    // Selectors

    const topics = useAppSelector(
        topicSelectors.topicsList,
    );
    const selectedEntities = useAppSelector(
        (state) => state.topics.selectedEntities,
    );
    const currentPage = useAppSelector(
        (state) => state.topics.currentPage,
    );
    const lastPage = useAppSelector((state) => state.topics.lastPage);
    const entityAsync = useAppSelector((state) => {
        const { fetchAsync, deleteAsync, addEditAsync } = state.topics;
        return fetchAsync || deleteAsync || addEditAsync;
    });

    // Callbacks

    const switchPage = useCallback((page) => {
        dispatch(topicsActions.unselectAll());
        dispatch(topicsAsyncActions.fetchEntityPage(page));
    }, [dispatch]);

    const onEditEntityClick = (id: string) => {
        dispatch(topicsActions.singleSelectEntity(id));
        setDisplayMode(FormDisplayMode.Edit);
    };

    const onArchiveAllClick = async () => {
        const r = await AlertUtils.dangerAlert({
            title: t("archiving"),
            message:
                t("are_you_sure_you_want_to_archive_selected_topic") +
                "?",
            positiveButtonText: t("archive"),
        });

        if (r) {
            for (const selectedHCId of selectedEntities) {
                await dispatch(
                    topicsAsyncActions.archiveEntity(selectedHCId),
                );
            }
        }
    };

    const onCloseForm = useCallback(() => {
        setDisplayMode(FormDisplayMode.None);
        dispatch(topicsActions.unselectAll());
    }, [dispatch]);

    const onSelectAll = () => {
        dispatch(topicsActions.selectAll());
    };

    const onUnselectAll = useCallback(() => {
        dispatch(topicsActions.unselectAll());
    }, [dispatch]);

    const onMultiselectEntity = (id: string) => {
        setDisplayMode(FormDisplayMode.None);
        dispatch(topicsActions.selectOrDeselectEntity(id));
    };

    const onSelectEntity = useCallback(
        (id: string) => {
            if (selectedEntities.find((entityId) => entityId === id)) {
                setDisplayMode(FormDisplayMode.None);
            } else {
                setDisplayMode(FormDisplayMode.View);
            }
            dispatch(topicsActions.singleSelectOrDeselectEntity(id));
        },
        [dispatch, selectedEntities],
    );

    const onAddEntityClick = useCallback(() => {
        setDisplayMode(FormDisplayMode.Add);
        onUnselectAll();
    }, [onUnselectAll]);

    // Render

    const renderActions = (
        hc: Topic,
        { isMultiselectActive }: CustomTableRowContentOptions,
    ) => {
        return (
            <AnimatedHider open={!isMultiselectActive}>
                <div className="table-column-horizontal flex-center-both">
                    <ColorfulIconButton onClick={() => onEditEntityClick(hc.id)}
                                        data-tip={t("edit")}>
                        <IconEdit size={18}/>
                    </ColorfulIconButton>
                </div>
                <ReactTooltip place={"bottom"}/>
            </AnimatedHider>
        );
    };

    const columns: CustomTableColumn<Topic>[] = [{
        headerContent: t("topic"),
        name: "ime",
        rowContent: (item) => item.name,
    }, {
        headerContent: "",
        name: "akcije",
        minTableWidth: 1000,
        width: "10%",
        rowContent: renderActions,
    }];

    return (
        <div className="topics flex-column page">
            <div className="flex-row flex-1">
                <div className="flex-column flex-1">
                    <EntityToolbar onAddEntityClick={onAddEntityClick} isFormOpened={isFormOpened}>
                        <SearchInput search={hcQuery?.search ?? ""}
                                     className={classNames("search-topic ml-auto", { "mr-10": !isFormOpened })}
                                     onClear={() => updateQuery({ search: "" })}
                                     onSearch={input => updateQuery({ search: input })}
                                     fullBorder={true}/>
                    </EntityToolbar>
                    <div className="table">
                        <CustomTable
                            columns={columns}
                            onSwitchPage={switchPage}
                            currentPage={currentPage}
                            lastPage={lastPage}
                            selectedItems={selectedEntities}
                            onRowClick={onSelectEntity}
                            isFullHeight={true}
                            onSelectAll={onSelectAll}
                            onUnselectAll={onUnselectAll}
                            onMultiselect={
                                !isFormOpened ? onMultiselectEntity : undefined
                            }
                            isLoading={entityAsync}
                            hasMore={currentPage < lastPage}
                            isSortEnabled={true}
                            data={topics}
                            multiselectAction={onArchiveAllClick}
                            multiselectActionName={t("archive")}
                            multiselectActionIcon={IconArchive}
                        />
                    </div>
                </div>
                <FramerFormAnimationWrapper
                    variants={form5Animation}
                    open={isFormOpened}
                    className="form offset-top"
                >
                    <ManageTopic
                        onClose={onCloseForm}
                        formDisplayMode={displayMode}
                        setDisplayMode={setDisplayMode}
                    />
                </FramerFormAnimationWrapper>
            </div>
        </div>
    );
};

export default Topics;
