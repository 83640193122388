import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'sweetalert2/dist/sweetalert2.css';

import 'react-image-lightbox/style.css';
import './assets/index.scss';

import App from './App';
import store from './store/store';
import SocketIOProvider from './components/SocketIOProvider/SocketIOProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SocketIOProvider>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <App/>
                    </ThemeProvider>
                </BrowserRouter>
            </SocketIOProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
