import React, { CSSProperties } from "react";

export type ValueOf<T> = T[keyof T];
export type WithStyle<T> = T & { style?: CSSProperties }
export type WithClassname<T> = T & { className?: string }
export type WithStyleAndClassname<T> = T & WithStyle<T> & WithClassname<T>
export type DateInterval<T = Date> = {
    from: T,
    to: T
}
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = (T | U) extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export enum FormDisplayMode {
    View = "view",
    Add = "add",
    Edit = "edit",
    None = "none"
}

export type ReactChildren = React.ReactNode | React.ReactElement | React.ComponentType<any>;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

type Join<K, P> = K extends string | number ?
    P extends string | number ?
        `${K}${"" extends P ? "" : "."}${P}`
        : never : never;

export type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: K extends string | number ?
        `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never
    }[keyof T] : ""

export type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";
