import Alert, { AlertPopupSize, AlertVariant } from "./alert";
import ReactDOM from "react-dom";
import i18n from "src/i18n/config";

export interface AlertPopupDangerOptions {
    title: string,
    message: string,
    positiveButtonText: string,
    neutralButtonText?: string,
    size?: AlertPopupSize,
    variant?: AlertVariant
}

export class AlertUtils {
    public static async dangerAlert({
                                        neutralButtonText = i18n.t("cancel"),
                                        positiveButtonText,
                                        title,
                                        message,
                                        size = AlertPopupSize.Small,
                                        variant = AlertVariant.RED
                                    }: AlertPopupDangerOptions): Promise<boolean> {

        return new Promise<boolean>(resolve => {
            ReactDOM.render(
                <Alert title={title}
                       variant={variant}
                       neutralButtonText={neutralButtonText}
                       positiveButtonText={positiveButtonText}
                       size={size}
                       onResult={(success) => {
                           resolve(success);

                           setTimeout(() => {
                               ReactDOM.unmountComponentAtNode(document.getElementById("modal-node")!);
                           }, 250);
                       }}
                       message={message}/>,
                document.getElementById("modal-node"),
            );
        });
    }
}

export default AlertUtils;
