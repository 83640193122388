import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import "./manage_user.scss";
import InputField, { InputType } from "../../../../components/Ui/InputField/InputField";
import { IUserForm, IUserFormErrors, UserFormUtils } from "src/entities/User/UserForm";
import Button, { ButtonStyle } from "../../../../components/Ui/Button/Button";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { User, UserRole, UserUtils } from "src/entities/User/User";
import { IconArchive, IconEdit } from "@tabler/icons";
import ColorfulIconButton, {
    ColorfulIconButtonVariant,
} from "../../../../components/Ui/ColorfulIconButton/ColorfulIconButton";
import classNames from "classnames";
import { usersAsyncActions, userSelectors } from "src/store/slices/usersSlice";
import SelectField, { SelectFieldOption } from "src/components/Ui/SelectField/SelectField";
import { useTranslation } from "react-i18next";
import { FormDisplayMode } from "src/utils/type_utils";
import AlertUtils from "src/components/Ui/Alert/alert_utils";
import ReactTooltip from "react-tooltip";
import { cloneDeep, isEqual } from "lodash";
import { SwalUtils } from "../../../../utils/swal_utils";
import { topicsAsyncActions, topicSelectors } from "../../../../store/slices/topicsSlice";
import { Utils } from "../../../../utils/utils";
import { loginSelectors } from "../../../../store/slices/loginSlice";
import { leadsAsyncActions } from "../../../../store/slices/leadSlice";

interface ManageUserProps {
    onClose: () => void,
    formDisplayMode: FormDisplayMode,
    setDisplayMode: (mode: FormDisplayMode) => void,
    onUnarchiveClick?: (id: string) => void
}

function ManageUser({ onClose, formDisplayMode, setDisplayMode, onUnarchiveClick }: ManageUserProps) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const isArchived = onUnarchiveClick;

    const isSuperAdmin = useAppSelector(loginSelectors.isGrantedSuperAdminLevel);

    const [initialUserForm, setInitialUserForm] = useState<IUserForm>(UserFormUtils.createForm());
    const [userForm, setUserForm] = useState<IUserForm>(UserFormUtils.createForm());
    const [selectedUserRole, setSelectedUserRole] = useState<SelectFieldOption | null>(null);
    const [selectedUserTopic, setSelectedUserTopic] = useState<SelectFieldOption | null>(null);
    const [formErrors, setFormErrors] = useState<IUserFormErrors>({});
    const [isEditPassword, setIsEditPassword] = useState(false);

    const isEntityEditMode = formDisplayMode === FormDisplayMode.Edit || formDisplayMode === FormDisplayMode.View;
    const isViewMode = formDisplayMode === FormDisplayMode.View;

    const user = useAppSelector(state => state.login.user);
    const isGrantedSuperAdminLevel = user && UserUtils.isGrantedSuperAdminLevel(user);

    const userRoleOptions = useMemo(() => {
        if (!isGrantedSuperAdminLevel) return UserUtils.userRoleOptions.filter(userRole => userRole.value !== UserRole.ROLE_SUPER_ADMIN);
        return UserUtils.userRoleOptions;
    }, [isGrantedSuperAdminLevel]);

    const userTopicOptions = useAppSelector(topicSelectors.topicOptions);

    // const selectedUser: User | null = useAppSelector(isArchived ? archivedUserSelectors.selectedArchivedUser : userSelectors.selectedUser);

    const selectedUser: User | null = useAppSelector(userSelectors.selectedUser);

    const async = useAppSelector(state => {
        const { addEditAsync, deleteAsync } = state.users;
        return addEditAsync || deleteAsync;
    });

    useEffect(() => {
        if (userTopicOptions.length === 0) {
            dispatch(topicsAsyncActions.fetchAllEntities());
        }
    }, []);

    useEffect(() => {

        setFormErrors({});

        if (selectedUser !== null) {
            const userForm = UserFormUtils.createForm(selectedUser);
            setUserForm(userForm);
            setInitialUserForm(cloneDeep(userForm));
            setSelectedUserRole(UserUtils.userRoleOptions.find(o => o.value === userForm.user_role) ?? null);
            setSelectedUserTopic(Utils.findSelectOption(userTopicOptions, selectedUser.topic?.id) ?? null);
        }
    }, [selectedUser, setDisplayMode]);


    const toggleReadonlyMode = useCallback(() => {
        if (isViewMode) {
            setDisplayMode(FormDisplayMode.Edit);
        } else {
            setDisplayMode(FormDisplayMode.View);
        }
    }, [isViewMode, setDisplayMode]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const field = e.target.name as keyof IUserForm;
        setUserForm(UserFormUtils.updateForm(userForm, { [field]: e.target.value }));
    }, [userForm]);

    const onSubmit = useCallback(async () => {

        setFormErrors({});

        if (selectedUserRole) {
            userForm.user_role = selectedUserRole.value as UserRole;
        }

        if (selectedUserTopic) {
            userForm.topic = selectedUserTopic.value;
        }

        UserFormUtils.prepareForm(userForm);

        const errors = UserFormUtils.validate(userForm);

        if (!isEntityEditMode) {

            if (!userForm.password) {
                errors.password = t("password_is_required");
            }
        }

        if (userForm.password) {
            const err = UserFormUtils.validatePassword({
                password: userForm.password,
                confirmPassword: userForm.confirmPassword,
            });

            if (err) {
                errors.confirmPassword = err;
            }
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (isEntityEditMode && selectedUser) {

            const hasDiff = !isEqual(userForm, initialUserForm);

            if (!hasDiff) {
                SwalUtils.showWarningSwalToast(t("no_changes") + ".");
                return;
            }

            const r = await dispatch(usersAsyncActions.updateEntityAction({
                entityId: selectedUser.id,
                entityForm: userForm,
            }));

            if (r) {
                if (userForm.password) {
                    dispatch(usersAsyncActions.changeUserPassword({
                        userId: selectedUser.id,
                        password: userForm.password,
                    }));
                }

                SwalUtils.showSuccessSwalToast();
                setDisplayMode(FormDisplayMode.View);
            }
        } else {
            const r = await dispatch(usersAsyncActions.createEntity(userForm));
            if (r) {
                SwalUtils.showSuccessSwalToast();
            }
            onClose();
        }
    }, [selectedUserRole, selectedUserTopic, userForm, isEntityEditMode, selectedUser, t, initialUserForm, dispatch, setDisplayMode, onClose]);

    const onDangerouslyDelete = async (id: string) => {
        const r = await AlertUtils.dangerAlert({
            title: t("warning"),
            message: t("warning_this_action_will_permanently_delete_the_user_are_you_sure"),
            positiveButtonText: t("confirm"),
            neutralButtonText: t("cancel"),
        });

        if (r) {
            dispatch(usersAsyncActions.dangerouslyDeleteUser(id));
        }
    };

    const onArchiveClick = async (id: string) => {
        const r = await AlertUtils.dangerAlert({
            title: t("archiving"),
            message: t("are_you_sure_you_want_to_archive_selected_user") + "?",
            positiveButtonText: t("archive"),
        });

        if (r) {
            await dispatch(usersAsyncActions.archiveEntity(id));
            onClose();
        }
    };

    const displayPasswordInputs = formDisplayMode === FormDisplayMode.Add || (!isViewMode && isEditPassword);

    return (
        <div className={classNames("manage-entity-common manage-user", isViewMode && "readonly")}>
            <div className="header">
                <span
                    className="header-title">{isViewMode ? t("staff_information") : (isEntityEditMode ? t("editing_staff") : t("adding_staff"))}</span>
                {isEntityEditMode && (
                    <div className={"header-btn-container"}>
                        {
                            <>
                                {isViewMode && <ColorfulIconButton onClick={toggleReadonlyMode}
                                                                   data-tip={t("edit")}>
                                    <IconEdit/>
                                </ColorfulIconButton>}
                                {isSuperAdmin && (
                                    <ColorfulIconButton variant={ColorfulIconButtonVariant.RED}
                                                        onClick={() => selectedUser && onDangerouslyDelete(selectedUser.id)}
                                                        className="ml-20"
                                                        data-tip={t("delete")}>
                                        <IconArchive/>
                                    </ColorfulIconButton>
                                )}
                            </>
                        }
                    </div>
                )}
            </div>
            <div className="entity-details-container">
                <div className="entity-details">
                    <div className="flex-row">
                        <SelectField options={userRoleOptions}
                                     value={selectedUserRole}
                                     label={t("title")}
                                     readonly={isViewMode}
                                     className={"user-role-picker"}
                                     error={formErrors.user_role}
                                     onChange={setSelectedUserRole}/>
                    </div>
                    <div className="flex-row">
                        <SelectField options={userTopicOptions}
                                     value={selectedUserTopic}
                                     label={t("topic")}
                                     readonly={isViewMode}
                                     className={"user-role-picker"}
                                     error={formErrors.topic}
                                     onChange={setSelectedUserTopic}/>
                    </div>
                    <InputField
                        label={t("first_and_last_name")}
                        readonly={isViewMode}
                        name="fullName"
                        error={formErrors.fullName}
                        showErrorText={true}
                        value={userForm.fullName}
                        onChange={handleChange}/>

                    <div className="flex-row">
                        <InputField onChange={handleChange}
                                    label={"Email"}
                                    readonly={isViewMode}
                                    className="mr-10 flex-1"
                                    value={userForm.email}
                                    error={formErrors.email}
                                    name={"email"}/>
                        <InputField type={InputType.Phone}
                                    label={t("telephone_number")}
                                    onChange={handleChange}
                                    readonly={isViewMode}
                                    value={userForm.phone ?? ""}
                                    error={formErrors.phone}
                                    name={"phone"}
                                    className="flex-1"/>
                    </div>

                    {!displayPasswordInputs && (
                        <Button onClick={() => setIsEditPassword(true)}
                                disabled={isViewMode}>
                            {t("change_password")}
                        </Button>
                    )}

                    {displayPasswordInputs && (
                        <div className="passwords-container">
                            <InputField onChange={handleChange}
                                        className="flex-1"
                                        label={t("password")}
                                        type={InputType.Password}
                                        readonly={isViewMode}
                                        value={userForm.password}
                                        error={formErrors.password}
                                        name={"password"}
                                        showErrorText={true}/>
                            <InputField onChange={handleChange}
                                        className="flex-1 ml-10"
                                        label={t("confirm_password")}
                                        type={InputType.Password}
                                        readonly={isViewMode}
                                        value={userForm.confirmPassword}
                                        error={formErrors.confirmPassword}
                                        name={"confirmPassword"}
                                        showErrorText={true}/>
                        </div>
                    )}
                </div>
                <div className="footer">
                    {!isViewMode ? (
                        <>
                            <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                                    onClick={onClose}>
                                {t("cancel")}
                            </Button>
                            <Button className="submit-btn text-uppercase footer-button"
                                    buttonStyle={ButtonStyle.Blue}
                                    loading={async}
                                    onClick={onSubmit}>
                                {isEntityEditMode ? t("save") : t("add")}
                            </Button>
                        </>
                    ) : (
                        <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                                onClick={onClose}>
                            {t("close")}
                        </Button>
                    )}
                </div>
            </div>
            <ReactTooltip place={"bottom"}/>
        </div>
    );
}

export default ManageUser;
