import "./modal.scss";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import classNames from "classnames";

export interface ModalProps {
    className?: string,
    children: React.ReactNode | React.ReactElement | React.ComponentType<any>,
    open: boolean,
    dismissible?: boolean,
    style?: CSSProperties,
    childStyle?: CSSProperties,
    childClassName?: string,
    setOpen: (open: boolean) => void,
}

const Modal = ({
                   className,
                   dismissible = true,
                   style,
                   childStyle,
                   childClassName,
                   open,
                   setOpen,
                   children,
               }: ModalProps) => {
    const [shown, setShown] = useState(open);

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setShown(open);
            }, 220);
        } else {
            setShown(true);
        }
    }, [open]);

    const onBackdropClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (dismissible) {
            setOpen(false);
            setTimeout(() => {
                setShown(false);
            }, 220);
        }
    }, [dismissible, setOpen]);

    const onModalBodyClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    if (!shown) {
        return null;
    }

    return (
        <div onClick={onBackdropClick}
                    style={style}
                    className={classNames("mpdz-modal", className, { open: open })}>
            <div onClick={onModalBodyClick}
                 style={childStyle}
                 className={classNames(childClassName, { open: open })}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
