import './questionnaire_form.scss';
import '../../../../components/Ui/MediaUploader/media_uploader.scss';
import {
    Answer,
    AnswerType,
    IQuestionForm,
    PathIndex,
    Question,
    Questionnaire,
    QuestionnaireFormMode,
    QuestionnaireUtils,
    QuestionType,
} from 'src/entities/questionnaire/Questionnaire';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ColorfulIconButton, { ColorfulIconButtonVariant } from 'src/components/Ui/ColorfulIconButton/ColorfulIconButton';
import { IconCirclePlus, IconEdit, IconPencil, IconTrash } from '@tabler/icons';
import TextareaField from 'src/components/Ui/TextareaField/TextareaField';
import SelectField, { SelectFieldOption } from 'src/components/Ui/SelectField/SelectField';
import Button, { ButtonStyle } from 'src/components/Ui/Button/Button';
import ReactTooltip from 'react-tooltip';
import { cloneDeep, isEqual, merge } from 'lodash';
import { SwalUtils } from 'src/utils/swal_utils';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import AlertUtils from 'src/components/Ui/Alert/alert_utils';
import { Utils } from '../../../../utils/utils';
import InputField from '../../../../components/Ui/InputField/InputField';
import { questionnairesAsyncActions, questionnairesSelectors } from '../../../../store/slices/questionnariesSlice';
import MediaUploader from '../../../../components/Ui/MediaUploader/MediaUploader';
import useMediaUploader from '../../../../utils/hooks/useMediaUploader';
import { MediaObjectApi } from '../../../../api/mediaObject';
import MediaUploaderAttachment from '../../../../components/Ui/MediaUploader/MediaUploaderAttachment';
import { ACCEPT_ATTACHMENTS_REDUCED } from '../../../../utils/consts';

interface QuestionnaireFormProps {
    onClose: () => void,
    formDisplayMode: QuestionnaireFormMode,
    setDisplayMode: (mode: QuestionnaireFormMode) => void
    selectedEntity: Question | Answer | null,
    entityPathIndexes: PathIndex[]
    setSelectedEntity: (question: Question | Answer) => void;
    setSelectedEntityPathIndexes?: (pathIndexes: PathIndex[]) => void;
}

const QuestionnaireForm = ({
                               onClose,
                               formDisplayMode,
                               setDisplayMode,
                               selectedEntity,
                               entityPathIndexes,
                               setSelectedEntity,
                               setSelectedEntityPathIndexes,
                           }: QuestionnaireFormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { attachments, setAttachments, removeAttachment, addAttachment } = useMediaUploader([]);

    const selectedQuestionnaire: Questionnaire | null = useAppSelector(questionnairesSelectors.selectedQuestionnaire);

    const isViewMode = formDisplayMode === QuestionnaireFormMode.View;

    const editingQuestion = formDisplayMode === QuestionnaireFormMode.EditQuestion;
    const addingQuestion = formDisplayMode === QuestionnaireFormMode.AddQuestion;

    const editingAnswer = formDisplayMode === QuestionnaireFormMode.EditAnswer;
    const addingAnswer = formDisplayMode === QuestionnaireFormMode.AddAnswer;

    const isRootQuestion = entityPathIndexes.length === 0;

    const isSelectedEntityAnswer = !isRootQuestion && (entityPathIndexes[entityPathIndexes.length - 1].array === "answers");

    const addingOrEditingAnswer = addingAnswer || editingAnswer;

    const isEditMode = editingQuestion || editingAnswer;

    const [questionTypeOption, setQuestionTypeOption] = useState<QuestionType | null>(null);
    const [answerTypeOption, setAnswerTypeOption] = useState<AnswerType | null>(null);
    const [initialEntityForm, setInitialEntityForm] = useState<IQuestionForm>(QuestionnaireUtils.createForm());
    const [entityForm, setEntityForm] = useState<IQuestionForm>(QuestionnaireUtils.createForm(selectedEntity));
    const [mediaObjectAsync, setMediaObjectAsync] = useState(false);
    const submitAsync = useAppSelector(state => state.questionnaires.addEditAsync) || mediaObjectAsync;

    const questionTypeOptions = Object.entries(QuestionType).map(([key, label]) => {
        return { label: label, value: key };
    });
    const answerTypeOptions = Object.entries(AnswerType).map(([key, label]) => {
        return { label: label, value: key };
    });

    const selectedQuestionTypeOption = useMemo(() => questionTypeOptions.find(o => questionTypeOption?.toUpperCase() === o?.value) ?? null, [questionTypeOption, questionTypeOptions]);
    const selectedAnswerTypeOption = useMemo(() => answerTypeOptions.find(o => answerTypeOption === o?.value) ?? null, [answerTypeOption, answerTypeOptions]);

    const selectedAnswerTypeIsText = selectedAnswerTypeOption?.label === AnswerType.Text;
    const selectedAnswerTypeIsLink = selectedAnswerTypeOption?.label === AnswerType.Link;

    const entityText = selectedEntity?.text ?? "";

    const formTitle = useMemo(() => {

        let title = entityText ?? "";

        if (!isViewMode) {

            if (entityText) {
                title += " - ";
            }

            if (isEditMode) {
                title += t("edit") + " ";
            } else {
                title += t("add") + " ";
            }

            if (addingOrEditingAnswer) {
                title += t("answer");
            } else {
                title += t("question");
            }
        }

        return title;
    }, [addingOrEditingAnswer, entityText, isEditMode, isViewMode, t]);

    const showAnswerTypeInput = (isViewMode && isSelectedEntityAnswer) || addingOrEditingAnswer;

    const showQuestionTypeInput = !showAnswerTypeInput || (showAnswerTypeInput && !selectedAnswerTypeIsText && !selectedAnswerTypeIsLink);

    const showAnswers = (selectedQuestionTypeOption?.label === QuestionType.SELECT && (editingQuestion || editingAnswer || isViewMode));

    const validationTagsAsOptions: SelectFieldOption[] = useMemo(() => {
        return (entityForm.validationTags ?? []).map(tag => ({
            value: tag,
            label: tag,
        }));
    }, [entityForm.validationTags]);

    useEffect(() => {
        const questionForm = QuestionnaireUtils.createForm(isEditMode || isViewMode ? selectedEntity : null);
        setEntityForm(questionForm);
        setInitialEntityForm(cloneDeep(questionForm));
        if (questionForm.questionType) {
            setQuestionTypeOption(questionForm.questionType);
        } else {
            setQuestionTypeOption(null);
        }

        if (isSelectedEntityAnswer) {
            if (questionForm.answerType) {
                setAnswerTypeOption(questionForm.answerType);
            } else {
                setAnswerTypeOption(null);
            }
        } else if (addingAnswer) {
            setAnswerTypeOption(AnswerType.Text);
        } else {
            setAnswerTypeOption(null);
        }
    }, [addingAnswer, addingQuestion, editingAnswer, editingQuestion, isEditMode, isSelectedEntityAnswer, isViewMode, selectedEntity, setDisplayMode]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [formDisplayMode, selectedEntity]);

    const toggleReadonlyMode = useCallback(() => {
        if (isViewMode) {
            setDisplayMode(isSelectedEntityAnswer ? QuestionnaireFormMode.EditAnswer : QuestionnaireFormMode.EditQuestion);
        } else {
            setDisplayMode(QuestionnaireFormMode.View);
        }
    }, [isSelectedEntityAnswer, isViewMode, setDisplayMode]);

    const handleTextChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const text = e.target.value;
        const field = Utils.toSnakeCase(text);

        setEntityForm(QuestionnaireUtils.updateForm(entityForm, { text, field }));
    }, [entityForm]);

    const handleRichMediaMessageConfigChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEntityForm(QuestionnaireUtils.updateForm(entityForm, { richMediaMessageConfig: e.target.value }));
    }, [entityForm]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const field = e.target.name as keyof IQuestionForm;
        setEntityForm(QuestionnaireUtils.updateForm(entityForm, { [field]: e.target.value }));
    }, [entityForm]);

    const updateForm = useCallback((data: IQuestionForm) => {
        setEntityForm(QuestionnaireUtils.updateForm(entityForm, data));
    }, [entityForm]);

    const onAddValidationTag = useCallback((input: string) => {
        updateForm({ validationTags: [...entityForm.validationTags ?? [], input] });
    }, [entityForm.validationTags, updateForm]);

    const onSubmit = async () => {
        const form = cloneDeep(entityForm);

        if (attachments.length > 0) {
            setMediaObjectAsync(true);
            const mediaObject = await MediaObjectApi.createMediaObject(attachments[0]);
            setMediaObjectAsync(false);

            if (mediaObject) {
                form.attachment = mediaObject;

                setAttachments([]);
            }
        }

        if (initialEntityForm.attachment && !form.attachment) {
            // delete media object
            MediaObjectApi.deleteMediaObject(initialEntityForm.attachment.id);
        }

        const switchingAnswerType = editingAnswer && ((!selectedAnswerTypeIsText) || (selectedAnswerTypeIsText));

        const hasDiff = !isEqual(form, initialEntityForm) || switchingAnswerType;

        if (!hasDiff) {
            SwalUtils.showWarningSwalToast(t("no_changes") + ".");
            return;
        }

        if (selectedQuestionnaire) {
            const configClone = cloneDeep(selectedQuestionnaire.config);
            const entity = QuestionnaireUtils.resolveQPathIndexesToQuestion(configClone, entityPathIndexes) as Question | Answer;

            if (editingQuestion) {
                merge(entity, form);
            } else if (addingQuestion) {
                if (isRootQuestion) {
                    configClone.push(form as Question);
                } else {
                    if (!entity.followupQuestions) {
                        entity.followupQuestions = [];
                    }

                    entity.followupQuestions.push(form as Question);
                }
            } else if (addingAnswer) {
                if (!entity.answers) {
                    entity.answers = [];
                }

                entity.answers.push(form as Question);
            } else if (editingAnswer) {
                merge(entity, form);
            }

            await dispatch(questionnairesAsyncActions.updateEntityAction({
                entityId: selectedQuestionnaire.id,
                entityForm: {
                    config: QuestionnaireUtils.formatQuestionnaireConfigForSubmit(configClone),
                },
            }));

            // Enter edit mode after creating new question
            if (addingQuestion && setSelectedEntityPathIndexes && form.questionType === QuestionType.SELECT) {

                let newEntityPathIndexes: PathIndex[] | null;

                if (isRootQuestion) {
                    newEntityPathIndexes = [{ index: configClone.length - 1, array: "followupQuestions" }];
                } else {
                    newEntityPathIndexes = QuestionnaireUtils.resolveCreatedSelectQuestionOrAnswerIndexes(entity, entityPathIndexes, addingQuestion);
                }

                if (newEntityPathIndexes) {

                    const newEntity = QuestionnaireUtils.resolveQPathIndexesToQuestion(configClone, newEntityPathIndexes);

                    if (newEntity) {
                        setSelectedEntityPathIndexes(newEntityPathIndexes);
                        setSelectedEntity(newEntity);
                        setDisplayMode(QuestionnaireFormMode.EditQuestion);
                        return;
                    }
                }
            }

            // Stay in edit mode after creating new answer
            if (addingAnswer) {
                const updatedEntity = QuestionnaireUtils.resolveQPathIndexesToQuestion(configClone, entityPathIndexes);

                if (updatedEntity) {
                    setSelectedEntity(updatedEntity);
                    setDisplayMode(QuestionnaireFormMode.EditAnswer);
                    return;
                }
            }
        }

        onClose();
    };

    const onDeleteClick = async (itemPathIndexes?: PathIndex[], deletingAnswer = false) => {

        const message = deletingAnswer ?
            t("are_you_sure_you_want_to_delete_selected_answer_with_all_sub_questions") :
            t("are_you_sure_you_want_to_delete_selected_question_with_sub_questions");

        const r = await AlertUtils.dangerAlert({
            title: t("deletion"),
            message: message + "?",
            positiveButtonText: t("delete"),
        });

        if (r) {
            if (selectedQuestionnaire) {
                const pathIndexes = itemPathIndexes ? itemPathIndexes : entityPathIndexes;
                const configClone = cloneDeep(selectedQuestionnaire.config);
                if (pathIndexes.length === 1) {
                    configClone.splice(pathIndexes[0].index, 1);
                } else {
                    QuestionnaireUtils.deleteQuestion(configClone, pathIndexes);
                }

                await dispatch(questionnairesAsyncActions.updateEntityAction({
                    entityId: selectedQuestionnaire.id,
                    entityForm: {
                        config: QuestionnaireUtils.formatQuestionnaireConfigForSubmit(configClone),
                    },
                }));
                setDisplayMode(QuestionnaireFormMode.None);
            }
        }
    };
console.log(entityForm)
    return <div className={classNames("manage-entity-common questionnaire-form", isViewMode && "readonly")}>
        <div className="header">
            <span
                className="header-title">
                    {formTitle}
            </span>
            {(isViewMode || editingQuestion) && (
                <div className={"header-btn-container"}>
                    {
                        <>
                            {isViewMode && (
                                <>
                                    <ColorfulIconButton onClick={toggleReadonlyMode}
                                                        data-for="questionnaire-form"
                                                        data-tip={t("edit") + " " + (isSelectedEntityAnswer ? t("answer") : t("question"))}>
                                        <IconEdit/>
                                    </ColorfulIconButton>

                                    <ColorfulIconButton variant={ColorfulIconButtonVariant.GREEN}
                                                        onClick={() => {
                                                            setDisplayMode(QuestionnaireFormMode.AddQuestion);
                                                        }}
                                                        data-tip={t("add") + " " + t("subquestion")}
                                                        data-for="questionnaire-form"
                                                        className="ml-20">
                                        <IconCirclePlus/>
                                    </ColorfulIconButton>
                                </>
                            )}
                            <ColorfulIconButton variant={ColorfulIconButtonVariant.RED}
                                                onClick={() => {
                                                    onDeleteClick();
                                                }}
                                                className="ml-20"
                                                data-for="questionnaire-form"
                                                data-tip={t("delete") + " " + (isSelectedEntityAnswer ? t("answer") : t("question"))}>
                                <IconTrash/>
                            </ColorfulIconButton>
                        </>
                    }
                </div>
            )}
        </div>
        <div className="entity-details-container">
            <div className="entity-details">
                {showAnswerTypeInput && (
                    <SelectField options={answerTypeOptions}
                                 value={selectedAnswerTypeOption}
                                 label={"Answer type"}
                                 onChange={(e) => {
                                     e && setAnswerTypeOption(e.value as AnswerType);
                                     setQuestionTypeOption(null);
                                     const form = cloneDeep(entityForm);

                                     form.answerType = e?.label;
                                     delete form.questionType;

                                     setEntityForm(form);
                                 }}
                                 readonly={isViewMode}
                                 className="w-50p"/>
                )}
                {showQuestionTypeInput &&
                    <SelectField options={questionTypeOptions}
                                 value={selectedQuestionTypeOption}
                                 label={"Question type"}
                                 onChange={(e) => {
                                     e && setQuestionTypeOption(e.value as QuestionType);
                                     updateForm({ questionType: e?.label });
                                 }}
                                 readonly={isViewMode}
                                 className="w-50p"/>
                }
                {entityForm.attachment && (
                    <MediaUploaderAttachment attachment={entityForm.attachment}
                                             index={0}
                                             readonly={isViewMode}
                                             onRemoveAttachment={() => {
                                                 setEntityForm(QuestionnaireUtils.updateForm(entityForm, { attachment: null }));
                                             }}/>
                )}
                {entityForm.questionType === QuestionType.FILE && !isViewMode && (
                    <MediaUploader addMediaText={t("add_files")}
                                   files={attachments}
                                   multiple={false}
                                   dragFileHereText={""}
                                   acceptAttachments={ACCEPT_ATTACHMENTS_REDUCED}
                                   onAddAttachment={addAttachment}
                                   onRemoveAttachment={removeAttachment}/>
                )}
                {entityForm.questionType === QuestionType.INPUT && (
                    <>
                        <InputField label={"Validation (Regex)"}
                                    value={entityForm?.validationRegex}
                                    onChange={handleChange}
                                    name={"validationRegex"}
                                    readonly={isViewMode}/>
                        <SelectField value={validationTagsAsOptions}
                                     multiple
                                     readonly={isViewMode}
                                     label={"Validation (Tags)"}
                                     onChange={(tags: unknown) => {
                                         delete entityForm.validationTags;
                                         updateForm({
                                             validationTags: (tags as SelectFieldOption[]).map(t => t.value),
                                         });
                                     }}
                                     onCreateOption={onAddValidationTag}/>
                        <InputField label={"Validation (Message)"}
                                    value={entityForm?.validationMessage}
                                    onChange={handleChange}
                                    name={"validationMessage"}
                                    readonly={isViewMode}/>
                    </>
                )}
                {selectedAnswerTypeIsLink &&
                    <InputField label={"Link"}
                                value={entityForm?.link}
                                onChange={handleChange}
                                name={"link"}
                                readonly={isViewMode}/>
                }
                {entityForm.questionType !== QuestionType.FILE && (
                    <TextareaField label={"Text"}
                                   value={entityForm?.text}
                                   onChange={handleTextChange}
                                   rows={5}
                                   name={"text"}
                                   readonly={isViewMode}/>
                )}
                {entityForm.questionType === QuestionType.RICH_MEDIA_MESSAGE && (
                    <TextareaField label={"RichMediaMessage Configuration"}
                                   value={entityForm?.richMediaMessageConfig}
                                   onChange={handleRichMediaMessageConfigChange}
                                   rows={4}
                                   name={"richMediaMessageConfig"}
                                   readonly={isViewMode}/>
                )}
                {showAnswers && (
                    <div className="flex-column ml-10 mt-16">
                        <span className="text-color-1 text-14">Answers:</span>
                        {entityForm.answers?.map((answer, index) => {
                                return <div key={index} className="answer">
                                    <div>
                                        {answer.text}
                                    </div>
                                    {!isViewMode && (<div className="ml-auto">
                                            <IconPencil className="answer-action-icon mr-10"
                                                        size={18}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (setSelectedEntityPathIndexes) {
                                                                setSelectedEntityPathIndexes([...entityPathIndexes, {
                                                                    index,
                                                                    array: "answers",
                                                                }]);
                                                            }
                                                            setSelectedEntity(answer);
                                                            setDisplayMode(QuestionnaireFormMode.EditAnswer);
                                                        }}
                                                        data-tip={t("edit") + " " + t("answer")}/>
                                            <IconTrash className="answer-action-icon red"
                                                       size={18}
                                                       onClick={(e) => {
                                                           e.stopPropagation();
                                                           onDeleteClick([...entityPathIndexes, {
                                                               index,
                                                               array: "answers",
                                                           }], true);
                                                       }}
                                                       data-tip={t("delete") + " " + t("answer")}/>
                                        </div>
                                    )}
                                </div>;
                            },
                        )}
                        {!isViewMode && (
                            <div className="flex-row align-center mt-10">
                                <IconCirclePlus className="item-action"
                                                onClick={() => {
                                                    setDisplayMode(QuestionnaireFormMode.AddAnswer);
                                                }}/>
                                <div className="ml-10 text-color-3 text-14">{t("add_answer")}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="footer">
                {!isViewMode ? (
                    <>
                        <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                                onClick={toggleReadonlyMode}>
                            {t("cancel")}
                        </Button>
                        <Button className="submit-btn text-uppercase footer-button"
                                buttonStyle={ButtonStyle.Blue}
                                loading={submitAsync}
                                onClick={onSubmit}>
                            {isEditMode ? t("save") : t("add")}
                        </Button>
                    </>
                ) : (
                    <Button className="text-uppercase footer-button" buttonStyle={ButtonStyle.Text}
                            onClick={onClose}>
                        {t("close")}
                    </Button>
                )}
            </div>
        </div>
        <ReactTooltip place={"bottom"} id="questionnaire-form"/>
    </div>;
};

export default QuestionnaireForm;
