import { useCallback, useState } from "react";
import { cloneDeep, merge } from "lodash";
import { DeepPartial } from "@reduxjs/toolkit";
import { Utils } from "../utils";

type UseForm<Form> = {
    form: Form,
    setForm: (form: Form) => void,
    updateForm: (data: Form) => void,
    originalForm: Form,
    setOriginalForm: (originalForm: Form) => void,
    getFormDiff: () => DeepPartial<Form>
}

function useForm<Form>(initial: Form): UseForm<Form> {

    const [form, setForm] = useState<Form>(initial);
    const [originalForm, setOriginalForm] = useState<Form>(initial);

    const updateForm = useCallback((data: Form) => {
        setForm(merge(cloneDeep(form), data));
    }, [form]);

    const getFormDiff = useCallback(() => {
        return Utils.getObjectDiff(form as Record<string, unknown>, originalForm as Record<string, unknown>) as DeepPartial<Form>;
    }, [form, originalForm]);

    return { form, setForm, updateForm, originalForm, setOriginalForm, getFormDiff };
}

export default useForm;
