import { MediaObject } from "src/entities/Mediaobject/MediaObject";
import { API, ApiMethod } from "src/api/api";
import { ReadFile, Utils } from "../utils/utils";
import { SendMessageFail } from "./messages";
import { SwalUtils } from "../utils/swal_utils";
import i18next from "i18next";

export enum EntityName {
}

export type MediaPostData = {
    entityId: string,
    entityName: EntityName
}

export class MediaObjectApi {
    static async createMediaObject(file: ReadFile): Promise<MediaObject | null> {

        const data = new FormData();
        data.append(`file`, Utils.dataURItoBlob(file.file), file.name);

        let totalFileSize = file.file.length;

        if (totalFileSize >= 20447232) {
            SwalUtils.showErrorSwalToast(i18next.t("err_total_attachment_size"));
            return null;
        }

        return await API.apiCall({
            url: API.mediaObjects,
            method: ApiMethod.Post,
            body: data,
            manualUnknownErrorHandling: true,
        }).then(r => r?.json())
            .catch((err) => {
                if (err.status === 413) {
                    SwalUtils.showErrorSwalToast(i18next.t("err_total_attachment_size"));
                } else {
                    SwalUtils.showErrorSwalToast("Desila se nepoznata greška");
                }

                return null;
            });
    }

    static getMediaObject(mediaObjectId: string): Promise<MediaObject | null> {
        return API.jsonApiCall({
            url: `${API.mediaObjects}/${mediaObjectId}`,
        });
    }

    static deleteMediaObject(mediaObjectId: string): Promise<null> {
        return API.jsonApiCall({
            url: `${API.mediaObjects}/${mediaObjectId}`,
            method: ApiMethod.Delete,
        });
    }
}
