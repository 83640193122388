import { AnimatePresence, motion, Variants } from "framer-motion";
import { transition350easeInOut } from "../AnimationVariants";
import { ReactChildren } from "../../../utils/type_utils";

export interface FramerFormAnimationWrapperProps {
    variants: Variants;
    open: boolean;
    children: ReactChildren;
    className?: string;
}

function FramerFormAnimationWrapper({
    open,
    children,
    className,
    variants,
}: FramerFormAnimationWrapperProps) {
    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={variants}
                    className={className}
                    transition={transition350easeInOut}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default FramerFormAnimationWrapper;
