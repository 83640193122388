export interface MessageListDateHeaderProps {
    date: string,
}

function MessageListDateHeader({ date }: MessageListDateHeaderProps) {
    return (
        <div className="message-list-date-header">
            <div className="line-left"/>
            <div className="date">{date}</div>
            <div className="line-right"/>
        </div>
    );
}

export default MessageListDateHeader;
