import "./circular_initials.scss";
import { FullNameWithViber, getName } from "../../../utils/utils";
import classNames from "classnames";
import { IconCheck } from "@tabler/icons";
import { MouseEventHandler } from "react";

export interface CircularInitialsProps {
    entity: FullNameWithViber,
    isSelected?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>
}

function CircularInitials({ entity, isSelected = false, onClick }: CircularInitialsProps) {
    const initials = getName(entity).split(" ").map(part => part.charAt(0)).join("").toUpperCase();

    return (
        <div onClick={onClick}
             className={classNames("circular-initials", { selected: isSelected })}>
            {isSelected ? (
                <IconCheck/>
            ) : (
                <span>{initials}</span>
            )}
        </div>
    );
}

export default CircularInitials;
