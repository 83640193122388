import React from "react";
import "./media_object_card.scss";
import { MediaObject, MediaObjectUtils } from "src/entities/Mediaobject/MediaObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface MediaObjectCardProps {
    mediaObject: MediaObject;
    onClick?: () => void;
}

const MediaObjectCard = ({ mediaObject, onClick }: MediaObjectCardProps) => {
    const isFile = MediaObjectUtils.isFile(mediaObject);

    return (
        <div className="media-object-card" onClick={onClick}>
            {isFile ? (
                <div className="file-icon">
                    <FontAwesomeIcon
                        className=""
                        icon={MediaObjectUtils.getFileIconName(mediaObject)}
                        size={"lg"}/>
                </div>
            ) : (
                <img src={MediaObjectUtils.getUrl(mediaObject)}
                     alt="gallery-img"
                     className="gallery-img"/>
            )}
            <div className="flex-column">
                <div className="text-14">{MediaObjectUtils.getFilename(mediaObject)}</div>
                <div className="text-12 text-color-3 mt-8">{MediaObjectUtils.getFileSize(mediaObject)}</div>
            </div>
        </div>
    );
};

export default MediaObjectCard;
