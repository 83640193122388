import { Lead, OptStatus } from "src/entities/Lead/Lead";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import "./messenger.scss";
import MessengerHeader from "./MessengerHeader/MessengerHeader";
import MessengerMessageBar from "./MessengerMessageBar/MessengerMessageBar";
import MessengerMessageList from "./MessengerMessageList/MessengerMessageList";
import { useMessages } from "./useMessages";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { messengerActions } from "../../../store/slices/messengerSlice";
import { Utils } from "../../../utils/utils";
import { ACCEPT_ATTACHMENTS } from "../../../utils/consts";
import { useTranslation } from "react-i18next";
import { leadsSelectors } from "../../../store/slices/leadSlice";

export interface MessengerProps {
    onClickOpenTemplates: () => void;
}

export function Messenger({ onClickOpenTemplates }: MessengerProps) {
    const { t } = useTranslation();
    const lead: Lead | null = useAppSelector(
        leadsSelectors.selectedLead,
    );

    const leadId = lead?.id;
    const dispatch = useAppDispatch();

    const onDrop = useCallback(acceptedFiles => {
        for (const file of acceptedFiles) {
            Utils.readFile(file).then(attachment => {
                if (leadId) {
                    dispatch(messengerActions.addAttachment({ file: attachment, id: leadId }));
                }
            });
        }
    }, [dispatch, leadId]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ACCEPT_ATTACHMENTS,
        noClick: true,
    });
    const [messages, messagesAsync] = useMessages(lead);

    const canSendMessages = lead?.optStatus === OptStatus.IN;

    if (lead === null) {
        return (
            <div>
                {t('please_select_a_patient')}.
            </div>
        );
    }

    return (
        <div className="messenger" {...getRootProps()}>
            <MessengerHeader lead={lead}/>
            <MessengerMessageList
                lead={lead}
                messages={messages}
                messagesAsync={messagesAsync}
            />
            {canSendMessages && (
                <MessengerMessageBar lead={lead} onClickOpenTemplates={onClickOpenTemplates}/>
            )}

            <input {...getInputProps()} />
            <AnimatePresence>
                {isDragActive && (
                    <motion.div initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="messenger-dropzone-overlay">
                        <div className="dropzone-overlay-content">
                            <FontAwesomeIcon size={"6x"} icon={faUpload}/>
                            <div className="drag-file-here-text">Prevucite Fajl Ovdje</div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default Messenger;
