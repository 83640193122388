import { IconDotsVertical } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import Dropdown, { AnimationType, DropdownAnchorEdge } from "src/components/Ui/Dropdown/Dropdown";
import "./message_bar_action_dropup.scss";
import MessengerAttachmentAction from "./Actions/MessengerAttachmentAction";
import DropdownItem from "../../../../../components/Ui/Dropdown/DropdownItem";

export interface MessageBarActionDropupProps {
    onClickOpenTemplates: () => void;
    leadId: string,
}

export function MessageBarActionDropup({
                                           leadId,
                                           onClickOpenTemplates,
                                       }: MessageBarActionDropupProps) {
    const { t } = useTranslation();

    return (
        <Dropdown anchorEdge={DropdownAnchorEdge.TopRight}
                  animationType={AnimationType.Fade}
                  className="message-bar-action-dropup"
                  anchor={<IconDotsVertical/>}>
            <MessengerAttachmentAction leadId={leadId}/>
            <DropdownItem onClick={onClickOpenTemplates}>
                {t("add_a_predefined_message")}
            </DropdownItem>
        </Dropdown>
    );
}

export default MessageBarActionDropup;
