import Modal from "../../components/Ui/Modal/Modal";
import Button, { ButtonStyle } from "../../components/Ui/Button/Button";
import { useTranslation } from "react-i18next";
import './add_questionnaire_modal.scss';
import InputField from "../../components/Ui/InputField/InputField";
import { useCallback, useState } from "react";
import { questionnairesAsyncActions } from "../../store/slices/questionnariesSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Questionnaire } from "../../entities/questionnaire/Questionnaire";

export interface AddQuestionnaireModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    onQuestionnaireAdded: (r: Questionnaire) => void,
}

function AddQuestionnaireModal({ open, setOpen, onQuestionnaireAdded }: AddQuestionnaireModalProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const async = useAppSelector(state => state.questionnaires.addEditAsync);
    const [questionnaireKey, setQuestionnaireKey] = useState("");
    const [questionnaireContent, setQuestionnaireContent] = useState("");

    const onSubmit = useCallback(async () => {
        if (questionnaireKey.length > 0) {
            const r = await dispatch(questionnairesAsyncActions.createEntity({
                key: questionnaireKey,
                config: questionnaireContent.length > 0 ? JSON.parse(questionnaireContent) : undefined,
            }));

            if (r) {
                onQuestionnaireAdded(r);
                setOpen(false);
            }
        }
    }, [dispatch, onQuestionnaireAdded, questionnaireKey, questionnaireContent]);

    return (
        <Modal open={open}
               className="add-questionnaire-modal"
               setOpen={setOpen}>
            <div className="add-questionnaire-inner">
                <div>
                    <InputField onChange={(e) => setQuestionnaireKey(e.target.value)}
                                value={questionnaireKey}
                                className="mb-10"
                                label={t("questionnaire_key")}/>

                    <InputField onChange={(e) => setQuestionnaireContent(e.target.value)}
                                value={questionnaireContent}
                                label={t("questionnaire_content")}/>
                </div>
                <div className="footer">
                    <Button buttonStyle={ButtonStyle.Text}
                            onClick={() => setOpen(false)}>
                        <span className="text-medium text-14-caps-lock text-color-3 text-uppercase">{t("cancel")}</span>
                    </Button>
                    <Button buttonStyle={ButtonStyle.Blue}
                            loading={async}
                            onClick={onSubmit}>
                        <span className="text-medium text-14-caps-lock text-uppercase">{t("confirm")}</span>
                    </Button>
                </div>
            </div>
        </Modal>
    );

}

export default AddQuestionnaireModal;
