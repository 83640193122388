import { Topic, TopicsQuery } from "src/entities/Topic/Topic";
import {
    BaseEntityReducerState,
    createEntityReducer,
} from "../helpers/entityReducer";
import { APIEntity } from "src/api/api";
import { RootState } from "../store";
import { ITopicForm } from "src/entities/Topic/TopicForm";
import { createSelector } from "@reduxjs/toolkit";

interface TopicsState extends BaseEntityReducerState<Topic, TopicsQuery> {
}

const initialState: TopicsState = {
    entities: {},
    query: {},
    perPage: 10,
    currentPage: 1,
    fetchAsync: false,
    lastPage: 1,
    hasMore: true,
    totalItems: 0,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    searchFields: [],
};

const slice = createEntityReducer(
    {} as Topic,
    APIEntity.Topics,
    initialState,
    {},
    undefined,
    {} as ITopicForm,
    {} as TopicsQuery,
);

export const {
    slice: topicsSlice,
    asyncActions: topicsAsyncActions,
} = slice;

const topicsListSelector = (state: RootState) =>
    state.topics.entities;

export const topicsList = createSelector(
    [topicsListSelector],
    (entities) => {
        return Object.values(entities);
    },
);

const selectedTopicsSelector = (state: RootState) =>
    state.topics.selectedEntities;

export const selectedTopic = createSelector(
    [topicsListSelector, selectedTopicsSelector],
    (entities, selectedEntities) => {
        return selectedEntities.length === 1
            ? entities[selectedEntities[0]] ?? null
            : null;
    },
);

export const topicOptions = createSelector(
    [topicsList],
    (entities) => {
        return entities.map((hc) => ({ label: hc.name, value: hc.id }));
    },
);

export const topicSelectors = {
    topicsList,
    selectedTopic,
    topicOptions,
};

export const topicsActions = topicsSlice.actions;
export const topicsReducer = topicsSlice.reducer;
