import { User, UsersQuery, UserUtils } from "src/entities/User/User";
import { AsyncAction, BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { APIEntity } from "src/api/api";
import { RootState } from "../store";
import { IUserForm } from "src/entities/User/UserForm";
import { LoginApi } from "src/api/login";
import { createSelector } from "@reduxjs/toolkit";
import { topicsList } from "./topicsSlice";
import { loginSelectors } from "./loginSlice";
import login from "../../pages/Login/Login";
import { UsersApi } from "../../api/users";

interface UsersState extends BaseEntityReducerState<User, UsersQuery> {

}

function usersList(state: RootState): User[] {
    return Object.values(state.users.entities);
}

function selectedUser(state: RootState): User | null {
    return state.users.selectedEntities.length === 1 ? (state.users.entities[state.users.selectedEntities[0]] ?? null) : null;
}

const initialState: UsersState = {
    entities: {},
    query: {},
    perPage: 15,
    currentPage: 1,
    totalItems: 0,
    fetchAsync: false,
    lastPage: 1,
    hasMore: true,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    searchFields: [],
} as UsersState;

function dangerouslyDeleteUser(userId: string): AsyncAction {
    return async (dispatch) => {
        const r = await UsersApi.dangerouslyDeleteUser(userId).catch(() => null);

        if (r) {
            dispatch(usersActions.removeEntity(userId));
        }
    };
}

const changeUserPassword = ({
                                userId,
                                password,
                            }: { userId: string, password: string }): AsyncAction<Promise<User | null>> => {

    return async (dispatch) => {
        dispatch(usersActions.setAddEditAsync(true));

        try {
            const r = await LoginApi.changeUserPassword(userId, password);

            if (r) {
                dispatch(usersActions.updateEntity({ id: userId, data: r }));
            }

            return r;
        } finally {
            dispatch(usersActions.setAddEditAsync(false));
        }
    };
};

const slice = createEntityReducer({} as User, APIEntity.Users, initialState, {}, undefined, {} as IUserForm, {} as UsersQuery, undefined, { allowImpersonate: false });

const userImpersonateOptions = createSelector(
    [usersList, loginSelectors.user],
    (entities, loginUser) => {

        const opts = entities.map((hc) => {
            return {
                label: UserUtils.mergeName(hc) + ` - ${hc.topic?.name ?? ""}`.trimEnd(),
                value: hc.id,
                isSeparated: loginUser !== null && loginUser.id === hc.id,
            };
        });

        opts.sort((a, b) => {
            if (a.value === loginUser?.id) {
                return 1;
            }

            if (b.value === loginUser?.id) {
                return 1;
            }

            return -1;
        });

        return opts;
    },
);

export const { slice: usersSlice, asyncActions } = slice;

export const usersAsyncActions = {
    ...asyncActions,
    changeUserPassword,
    dangerouslyDeleteUser,
};

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
export const userSelectors = {
    usersList,
    selectedUser,
    userImpersonateOptions,
};
