import { cloneDeep, merge, pick } from 'lodash';
import { MediaObject } from '../Mediaobject/MediaObject';

export type QuestionnaireConfig = Array<Question>;

export type Questionnaire = {
    id: string,
    key: string,
    config: QuestionnaireConfig
};

export enum QuestionnaireFormMode {
    None = "None",
    View = "View",
    AddQuestion = "AddQuestion",
    EditQuestion = "EditQuestion",
    AddAnswer = "AddAnswer",
    EditAnswer = "EditAnswer",
}

export enum QuestionType {
    INPUT = "Input",
    SELECT = "Select",
    HEADER = "Header",
    FILE = "File",
    RICH_MEDIA_MESSAGE = "RichMediaMessage",
}

export enum AnswerType {
    Text = "Text",
    Question = "Question",
    Link = "Link",

}

export interface Question {
    // field u results objektu koji ce biti setovan
    field?: string,
    attachment?: MediaObject | null,
    // ukoliko zelimo da imamo text kao jedno
    // a da se u field setuje nesto drugo
    // imamo ovaj opcionalni value
    // logika je
    // if value !== undefined
    //     results[field] = value;
    // else
    //     results[field] = text;
    value?: string,
    // sta se salje useru
    text: string,
    link?: string,
    validationRegex?: string,
    validationTags?: Array<string>,
    validationMessage?: string,
    // ako je type input, od usera zahtjevamo
    // da ukuca odgovor koji stalvjamo u field.
    // ako je select, saljemo answers Array
    // ako u answersu se nalazi string, i user selectuje
    // taj string se setuje u field
    // ako se nalazi Question objekt, i selektuje
    // Question.text se setuje u field trenutnog objekta
    // i nastavlja se drillanje followupa.
    // kada se zavrsi sav niz followupa koji se mogu
    // nalazit u tim answers, vracamo se na ovo pitanje
    // koje ako ima followup, nastavljamo
    // ako nema
    // vracamo se jedan objekat iznad ovoga i opet
    // nastavljamo followup logiku
    // ako je field header, printaj text i nastavi followup
    // ako nema followup, vrati se jedan iznad
    questionType?: QuestionType,
    answerType?: AnswerType,
    answers?: Array<Question>,
    followupQuestions?: Array<Question>,
}

export type Answer = Question;

export interface IQuestionForm {
    text?: string,
    field?: string,
    link?: string,
    questionType?: QuestionType,
    answerType?: AnswerType,
    attachment?: MediaObject | null,
    answers?: Array<Question>,
    followupQuestions?: Array<Question>,
    value?: string,
    validationRegex?: string
    validationTags?: Array<string>,
    validationMessage?: string
    richMediaMessageConfig?: string;
}

// const exampleJson: Array<Question> = [{
//     text: "Ime i Prezime",
//     field: "first_and_last_name",
//     type: QuestionType.INPUT,
// }, {
//     text: "Godina Rodjenja",
//     field: "age",
//     type: QuestionType.INPUT,
// }, {
//     text: "Spol",
//     field: "gender",
//     type: QuestionType.INPUT,
// }, {
//     text: "Razlog Posjete",
//     field: "reason",
//     type: QuestionType.SELECT,
//     answers: [{
//         text: "COVID",
//         field: "covid",
//         type: QuestionType.INPUT,
//         followupQuestions: [{
//             type: QuestionType.HEADER,
//             text: "Radi trenutne situacije molimo za strpljenje",
//         }, {
//             text: "Odaberite potrebnu uslugu",
//             field: "covid_reason",
//             type: QuestionType.SELECT,
//             answers: [
//                 "Rezultati testiranja",
//                 {
//                     text: "Termin za testiranje",
//                     type: QuestionType.HEADER,
//                     followupQuestions: [{
//                         text: "Odaberite Dom zdravlja kojem pripadate",
//                         field: "health_center",
//                         type: QuestionType.SELECT,
//                         answers: [
//                             "Centar",
//                             "Stup",
//                             "Novi Grad",
//                         ]
//                     }]
//                 },
//                 "Generalni upiti",
//             ],
//             followupQuestions: [{
//                 text: "Da li ste prije bolovali koronu",
//                 field: "had_covid_before",
//                 type: QuestionType.SELECT,
//                 answers: [
//                     "DA",
//                     "NE",
//                 ],
//             }]
//         }],
//     }, {
//         text: "Gripa",
//         field: "flu",
//         type: QuestionType.INPUT,
//         followupQuestions: [{
//             text: "Zbog trenutne situacije sa COVID preporučuje se da uzmete paracetamol i ležite",
//             type: QuestionType.HEADER,
//         }]
//     }, {
//         text: "Povreda",
//         field: "injury",
//         type: QuestionType.INPUT,
//         followupQuestions: [{
//             text: "Na skali od 1-10 koji je nivo hitnosti povrede",
//             field: "emergency_level",
//             type: QuestionType.INPUT,
//         }, {
//             text: "Da li vam je potrebna hitna pomoć",
//             field: "ambulance_required",
//             type: QuestionType.SELECT,
//             answers: [{
//                 text: "DA",
//                 type: QuestionType.HEADER,
//                 followupQuestions: [{
//                     text: "Molimo unesite adresu gdje se nalazite",
//                     field: "ambulance_address",
//                     type: QuestionType.INPUT,
//                 }]
//             }]
//         }]
//     }]
// }];

export interface PathIndex {
    index: number;
    array: "answers" | "followupQuestions";
}

export class QuestionnaireUtils {


    static createForm(entity?: Question | Answer | null): IQuestionForm {

        if (!entity) {
            return {
                text: "",
                field: "",
                link: "",
                followupQuestions: [],
                answers: [],
                attachment: null,
                validationRegex: "",
                validationTags: [],
                validationMessage: "",
            };
        }

        const keys: Array<keyof Question> = ["answerType", "link", "attachment", "text", "field", "followupQuestions", "questionType", "value", "answers", "validationRegex", "validationTags", "validationMessage"];

        return {
            ...pick(entity, keys),
        };
    }

    static updateForm(questionForm: IQuestionForm, data: IQuestionForm) {
        return merge(cloneDeep(questionForm), data);
    }

    static resolveQPathIndexesToQuestion(questionnaire: Array<Question>, pathIndexes: PathIndex[]): Question | null {
        if (!pathIndexes.length) {
            return null;
        }

        let question: Question | null = questionnaire[pathIndexes[0].index];

        pathIndexes.slice(1).forEach(pathIndex => {

            if (!question) {
                return;
            }
            if (pathIndex.array === "followupQuestions") {
                question = question.followupQuestions?.[pathIndex.index] ?? null;
            } else {
                question = question.answers?.[pathIndex.index] as Question ?? null;
            }
        });

        return question;
    }

    static deleteQuestion(questionnaire: Array<Question>, pathIndexes: PathIndex[]) {
        if (!pathIndexes.length) {
            return;
        }
        let question: Question | null = this.resolveQPathIndexesToQuestion(questionnaire, pathIndexes.slice(0, -1));

        if (question) {
            if (pathIndexes[pathIndexes.length - 1].array === "followupQuestions") {
                question.followupQuestions?.splice(pathIndexes[pathIndexes.length - 1].index, 1);
            } else {
                question.answers?.splice(pathIndexes[pathIndexes.length - 1].index, 1);
            }
        }
    }

    static deleteQuestionAnswersOrFollowup(entity: Question | Answer) {
        const followups = entity.followupQuestions;

        // if it's an answer, delete questiontype

        if (entity.answerType) {
            if (entity.answerType !== AnswerType.Question) {
                delete entity.questionType;
            }
        }

        if (entity.answerType !== AnswerType.Link) {
            delete entity.link;
        }

        // Check if question is not select and has answers
        if (entity.questionType !== QuestionType.SELECT && entity.answers?.length) {
            delete entity.answers;
        }

        if (entity.questionType !== QuestionType.FILE) {
            delete entity.attachment;
        }

        if (entity.questionType !== QuestionType.INPUT) {
            delete entity.validationRegex;
            delete entity.validationTags;
            delete entity.validationMessage;
        }

        if (followups) {
            if (followups.length === 0) {
                delete entity.followupQuestions;
            } else {
                for (let followup of followups) {
                    this.deleteQuestionAnswersOrFollowup(followup);

                    if ((followup.followupQuestions?.length ?? 0) === 0) {
                        delete followup.followupQuestions;
                    }

                    if ((followup.answers?.length ?? 0) === 0) {
                        delete followup.answers;
                    }
                }
            }
        }

        const answers = entity.answers;

        if (answers) {
            if (answers.length === 0) {
                delete entity.answers;
            } else {
                for (let answer of answers) {
                    this.deleteQuestionAnswersOrFollowup(answer);

                    if ((answer.followupQuestions?.length ?? 0) === 0) {
                        delete answer.followupQuestions;
                    }

                    if ((answer.answers?.length ?? 0) === 0) {
                        delete answer.answers;
                    }
                }
            }
        }
    }

    static formatQuestionOrAnswerForSubmit(entity: Question | Answer) {
        console.log(JSON.parse(JSON.stringify(entity)));
        this.deleteQuestionAnswersOrFollowup(entity);

        if (entity.questionType !== QuestionType.INPUT) {
            delete entity.validationRegex;
            delete entity.validationTags;
            delete entity.validationMessage;
        }
    }

    static formatQuestionnaireConfigForSubmit(questionnaireConfig: QuestionnaireConfig): QuestionnaireConfig {
        const config = cloneDeep(questionnaireConfig);

        for (let question of config) {
            this.formatQuestionOrAnswerForSubmit(question);
        }

        return config;
    }

    static resolveCreatedSelectQuestionOrAnswerIndexes(parentEntity: Question | Answer, parentEntityPathIndexes: PathIndex[], addingQuestion = true): PathIndex[] | null {
        let newPathIndexes: PathIndex[] | null = null;

        if (addingQuestion) {
            const newIdx = (parentEntity.followupQuestions?.length ?? 0) - 1;

            if (newIdx >= 0) {
                newPathIndexes = [...parentEntityPathIndexes, { index: newIdx, array: 'followupQuestions' }];
            }
        } else {
            const newIdx = (parentEntity.answers?.length ?? 0) - 1;

            if (newIdx >= 0) {
                newPathIndexes = [...parentEntityPathIndexes, { index: newIdx, array: 'answers' }];
            }
        }

        if (!newPathIndexes) {
            return null;
        }

        return newPathIndexes;
    }
}
