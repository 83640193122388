import { cloneDeep, merge } from 'lodash';
import { StorageKeys } from '../utils/storage_keys';
import { SwalUtils } from '../utils/swal_utils';
import * as Sentry from '@sentry/react';
import { Errors, IS_DEV } from '../utils/consts';
import i18next from 'i18next';
import { tryToParseImpersonationFromLocalStorage } from '../utils/utils';

export enum APIEntity {
    Leads = 'leads',
    Topics = 'topics',
    MessageTemplateCategories = 'message_template_categories',
    MessageTemplates = 'message_templates',
    Questionnaire = 'questionnaires',
    Users = 'users',
}

export enum ApiMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Patch = 'PATCH'
}

export interface APIPaginatedEndpointParams {
    perPage: number,
    page: number,
}

export interface ApiCallParams {
    url: string,
    signal?: AbortSignal,
    noAuth?: boolean,
    headers?: Record<string, string>,
    body?: ReadableStream<Uint8Array> | string | null | FormData,
    jsonData?: Record<string, unknown>,
    method?: ApiMethod,
    manualUnknownErrorHandling?: boolean,
    allowImpersonate?: boolean,
}

export interface GenericErrorResponse {
    detail: string,
    violations: Array<AuthErrorResponse>
}

export interface AuthErrorResponse {
    message: string,
    propertyPath: string,
}

export interface DefaultApiCallParams {
    headers: Record<string, string>,
    method: ApiMethod,
    body?: ReadableStream<Uint8Array> | string | null,
    allowImpersonate?: boolean,
}

export interface HydraCollection<T> {
    '@context': string;
    '@id': string;
    '@type': 'hydra:Collection';
    'hydra:member': T[];
    'hydra:totalItems': number;
}


export interface HydraItem {
    '@id': string;
    '@type': string;
}

export class API {
    public static readonly endpoint: string = process.env.REACT_APP_ENDPOINT || 'http://localhost:8000';
    public static readonly wsEndpoint: string = `${API.endpoint}/ws`;
    public static readonly apiEndpoint: string = API.endpoint + '/api';
    public static readonly storageEndpoint: string = API.endpoint + '/storage';
    public static readonly contentUrl: string = API.storageEndpoint + '/content';
    public static readonly publicEndpoint: string = API.endpoint + '/api/public';
    public static readonly refreshToken: string = `${API.apiEndpoint}/token/refresh`;
    public static readonly mediaObjects: string = `${API.apiEndpoint}/media_objects`;
    public static readonly leads: string = `${API.apiEndpoint}/leads`;
    public static readonly reports: string = `${API.apiEndpoint}/reports`;
    public static readonly login: string = `${API.apiEndpoint}/login`;
    public static readonly me: string = `${API.apiEndpoint}/me`;
    public static readonly users: string = `${API.apiEndpoint}/users`;
    public static readonly messages: string = `${API.apiEndpoint}/messages`;
    public static readonly super: string = `${API.apiEndpoint}/super`;
    public static readonly messageTemplates: string = `${API.apiEndpoint}/message_templates`;
    public static readonly predefinedResponses: string = `${API.apiEndpoint}/predefined_responses`;
    public static readonly viberKeyboards: string = `${API.apiEndpoint}/viber_keyboards`;

    static getApiSingleEntityUrl(entity: APIEntity): string {
        // if (entity === APIEntity.PatientsActiveConversations) {
        //     return `${API.getApiEntityUrl(APIEntity.Patients)}/conversations`;
        // }

        return API.getApiEntityUrl(entity);
    }

    static getApiEntityUrl(entity: APIEntity): string {
        return `${API.apiEndpoint}/${entity}`;
    }

    static getPublicApiEntityUrl(entity: APIEntity): string {
        return `${API.publicEndpoint}/${entity}`;
    }

    static getApiEntityPaginatedUrl(entity: APIEntity, { perPage = 30, page = 1 }: APIPaginatedEndpointParams): string {
        const url = new URL(API.getApiEntityUrl(entity));

        url.searchParams.append('per_page', perPage.toString());
        url.searchParams.append('page', page.toString());

        return url.toString();
    }

    static handleErrors(response: Response): Response | undefined {
        if (response.ok || response.status === 204) {
            return response;
        } else {
            throw response;
        }
    }

    public static async jsonApiCall<T>(params: ApiCallParams): Promise<T | null> {
        const r = await API.apiCall(params);

        if (r && r.status !== 204) {
            return await r.json();
        } else {
            return null;
        }
    }

    public static async strictJsonApiCall<T>(params: ApiCallParams): Promise<T> {
        const r = await API.apiCall(params);

        if (r && r.status !== 204) {
            return await r.json();
        }

        return {} as T;
    }

    public static apiCall(params: ApiCallParams = {
        url: '',
        body: undefined,
        jsonData: undefined,
        method: ApiMethod.Get,
        noAuth: false,
        manualUnknownErrorHandling: false,
    }): Promise<Response | undefined> {
        const originalParams = cloneDeep(params);

        const token = localStorage.getItem(StorageKeys.Token);

        const defaultParams: DefaultApiCallParams = {
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json, application/ld+json',
            },
            method: ApiMethod.Get,
            allowImpersonate: true,
            body: null,
        };

        if (params.body) {
            delete defaultParams.headers['content-type'];
        }

        if (params.jsonData) {
            params.body = JSON.stringify(params.jsonData);
            delete params.jsonData;
        }

        if (token && !params.noAuth) {
            defaultParams.headers['Authorization'] = `Bearer ${token}`;
        }

        const actualParams = merge(defaultParams, params);

        const impersonate = tryToParseImpersonationFromLocalStorage();

        if (actualParams.allowImpersonate && impersonate !== null) {
            const url = new URL(params.url);

            url.searchParams.append('impersonate', impersonate.email);

            params.url = url.toString();
        }

        const p = new Promise<Response | undefined>((resolve, reject) => {
            return fetch(params.url, actualParams)
                .then(this.handleErrors)
                .then(r => resolve(r))
                .catch(async ex => {
                    if (typeof ex.message === 'string' && ex.message.includes('aborted')) {
                        console.log('includes aborted');
                        return;
                    }

                    if (ex.status === 401) {
                        if (ex.json) {
                            const message = await ex.json()
                                .then((data: AuthErrorResponse) => {
                                    return data.message;
                                });
                            if (message === 'Invalid credentials.') {
                                // this is login, propagate this error up
                                // showSwalToast(message);
                                reject({ status: ex.status, message });
                            } else if (message === 'Expired JWT Token') {
                                // we should probs refresh token here
                                const refreshToken = localStorage.getItem(StorageKeys.RefreshToken);

                                if (refreshToken) {
                                    // proceed
                                    const data = new FormData();
                                    data.append('refresh_token', refreshToken);
                                    return fetch(API.refreshToken, {
                                        method: 'post',
                                        body: data,
                                    })
                                        .then(this.handleErrors)
                                        .then(r => r?.json())
                                        .then(r => {
                                            localStorage.setItem(StorageKeys.Token, r.token);
                                            localStorage.setItem(StorageKeys.RefreshToken, r.refresh_token);
                                            //redo api call
                                            API.apiCall(originalParams)
                                                .then(resolve);
                                        })
                                        .catch(() => {
                                            API.logOut();
                                        });
                                } else {
                                    API.logOut();
                                }
                            } else if (message === 'An authentication exception occurred.') {
                                // USER PROBABLY GOT DISABLED
                                SwalUtils.showErrorSwalToast(message);
                                API.logOut();
                            } else if (message === 'JWT Token not found') {
                                SwalUtils.showErrorSwalToast(message);
                                API.logOut();
                            } else if (message === 'Invalid JWT Token') {
                                API.logOut();
                            }
                        }
                    } else if (ex.status === 404) {
                        // showSwalToast(ex.url + '\n' + ex.statusText);
                    } else {
                        if (ex.json) {
                            try {
                                await ex.json()
                                    .then((data: GenericErrorResponse) => {
                                        let errorMessage = '';

                                        if (data.violations?.length) {
                                            const violation = data.violations[0];
                                            errorMessage = this.resolveApiError(`${violation.propertyPath}: - ${violation.message}`);
                                        } else if (data.detail) {
                                            errorMessage = this.resolveApiError(data.detail);
                                        }

                                        if (errorMessage) {
                                            SwalUtils.showErrorSwalToast(errorMessage);
                                        }
                                    });
                            } catch (parseJsonEx) {
                                if (params.manualUnknownErrorHandling) {
                                    reject(ex);
                                } else {
                                    SwalUtils.showErrorSwalToast('Desila se nepoznata greška');
                                    reject('Desila se nepoznata greška');
                                }
                            }
                        } else {
                            if (params.manualUnknownErrorHandling) {
                                reject(ex);
                            } else {
                                SwalUtils.showErrorSwalToast('Desila se nepoznata greška');
                                reject('Desila se nepoznata greška');
                            }
                        }
                    }

                    reject(ex);
                });
        });

        return p;
    }

    public static logOut(): void {
        localStorage.removeItem(StorageKeys.Token);
        localStorage.removeItem(StorageKeys.RefreshToken);
        localStorage.removeItem(StorageKeys.Impersonate);
        window.location.reload();
    }

    static resolveApiError(error: string) {
        switch (error) {
            case Errors.AGENT_MUST_BE_ASSIGNED_TO_HEALTH_CENTER_FOR_SMS_COMMUNICATION:
                return i18next.t('agent_must_be_assigned_to_topic_for_communication');
            default:
                if (IS_DEV) {
                    throw new Error(`Missing error key - ${error}`);
                } else {
                    console.error(`Missing error key - ${error}`);
                }
                Sentry.captureMessage(`Missing error key - ${error}`);
                return error;
        }
    }
}
