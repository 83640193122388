import React, { CSSProperties } from "react";
import "./animated_hider.scss";
import { motion } from "framer-motion";
import classNames from "classnames";

export enum AnimatedHiderVariant {
    Fade = "fade",
    Collapse = "collapse",
    ShrinkLeftRight = "shrink-left-right"
}

export interface AnimatedHiderProps {
    open?: boolean;
    className?: string;
    style?: CSSProperties,
    children: React.ReactNode | React.ReactElement | React.ComponentType<any>;
    collapseHasBottomMargin?: boolean,
    variant?: AnimatedHiderVariant;
}

export const pageAnimationVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const AnimatedHider = React.forwardRef<HTMLDivElement, AnimatedHiderProps>(
    (
        {
            open,
            className,
            children,
            style,
            collapseHasBottomMargin = true,
            variant = AnimatedHiderVariant.Fade,
        }: AnimatedHiderProps,
        ref,
    ) => {
        const animate =
            variant === AnimatedHiderVariant.Fade
                ? {
                    opacity: open ? 1 : 0,
                    display: "inherit",
                    transitionEnd: {
                        display: open ? "inherit" : "none",
                    },
                } : variant === AnimatedHiderVariant.ShrinkLeftRight ? {
                    width: open ? 'auto' : 0,
                    display: "inherit",
                    transitionEnd: {
                        display: open ? "inherit" : "none",
                    },
                } : {
                    height: open ? "auto" : "0px",
                    marginBottom: open && collapseHasBottomMargin ? "1rem" : "0rem",
                    transitionEnd: {
                        display: open ? "inherit" : "none",
                    },
                };

        const initial =
            variant === AnimatedHiderVariant.Fade
                ? {
                    opacity: open ? 1 : 0,
                    display: "inherit",
                    transitionEnd: {
                        display: open ? "inherit" : "none",
                    },
                } : variant === AnimatedHiderVariant.ShrinkLeftRight ? {
                    width: open ? 'auto' : 0,
                    display: "inherit",
                    transitionEnd: {
                        display: open ? "inherit" : "none",
                    },
                } : {
                    height: open ? "auto" : "0px",
                    marginBottom: open && collapseHasBottomMargin ? "1rem" : "0rem",
                    transitionEnd: {
                        display: open ? "inherit" : "none",
                    },
                };

        return (
            <motion.div
                ref={ref}
                animate={animate}
                initial={initial}
                style={style}
                className={classNames(
                    "animated-hider",
                    className,
                    `variant-${variant}`,
                )}
            >
                <div className="animated-hider-inner">
                    {children}
                </div>
            </motion.div>
        );
    },
);

export default AnimatedHider;
