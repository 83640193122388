import { combineReducers } from "@reduxjs/toolkit";
import { messengerReducer } from "./messengerSlice";
import { leadsReducer } from "./leadSlice";
import { loginReducer } from "./loginSlice";
import { messageTemplatesReducer } from "./messageTemplatesSlice";
import { topicsReducer } from "./topicsSlice";
import { questionnairesReducer } from "./questionnariesSlice";
import { messageTemplateCategoriesReducer } from "./messageTemplateCategoriesSlice";
import { usersReducer } from "./usersSlice";

const createRootReducer = () => combineReducers({
    messenger: messengerReducer,
    leads: leadsReducer,
    topics: topicsReducer,
    login: loginReducer,
    messageTemplates: messageTemplatesReducer,
    messageTemplateCategories: messageTemplateCategoriesReducer,
    questionnaires: questionnairesReducer,
    users: usersReducer,
});

export default createRootReducer;
