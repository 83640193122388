import { useEffect, useMemo, useRef } from "react";
import { Waypoint } from "react-waypoint";
import Spinner from "src/components/Ui/Spinner/Spinner";
import { Lead } from "src/entities/Lead/Lead";
import { Message, MessageContentType, MessageDirection } from "src/entities/message/Message";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { messengerAsyncActions } from "src/store/slices/messengerSlice";
import { Utils } from "src/utils/utils";
import MessengerMessage from "./MessengerMessage/MessengerMessage";
import "./messenger_message_list.scss";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import MessageListDateHeader from "./MessageListDateHeader";

export interface MessengerMessageListProps {
    messages: Message[];
    messagesAsync: boolean;
    lead: Lead;
}

const today = format(new Date(), "dd/MM/yy");

export function MessengerMessageList({
                                         messages,
                                         messagesAsync,
                                         lead,
                                         ...rest
                                     }: MessengerMessageListProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sendAsync = useAppSelector((state) => state.messenger.sendAsync);
    const hasMore = useAppSelector((state) => state.messenger.hasMore);
    const fetchMoreAsync = useAppSelector(
        (state) => state.messenger.fetchMoreAsync,
    );
    const messageListRef = useRef<HTMLDivElement>(null);
    const customerName = useMemo(
        () =>
            Utils.mergeName(lead),
        [lead],
    );
    const scrollDownAsync = messagesAsync || sendAsync;
    const disableScrolldown = useRef<boolean>(false);

    const loadingMessages = messagesAsync || fetchMoreAsync;

    const RenderedMessages = useMemo(() => {
        let previousMessageDate = today;

        return messages.filter(message => !(message.contentType === MessageContentType.QUESTIONNAIRE && message.direction === MessageDirection.OUTBOUND)).map(message => {
            let currentMessageDate = format(new Date(message.createdAt), "dd/MM/yy");

            let rets = [];

            console.log(currentMessageDate, previousMessageDate);

            if (currentMessageDate !== previousMessageDate) {
                previousMessageDate = currentMessageDate;

                rets.push(<MessageListDateHeader date={currentMessageDate === today ? t("today") : currentMessageDate}
                                                 key={`date-${message.id}`}/>);
            }

            return [
                ...rets,
                <MessengerMessage
                    key={message.id}
                    patientName={customerName}
                    message={message}
                />,
            ];
        });
    }, [t, customerName, messages]);

    async function loadPage() {
        if (loadingMessages || !hasMore || !messageListRef.current) return;

        disableScrolldown.current = true;

        const lastScrollHeight = messageListRef.current.scrollHeight;

        await dispatch(
            messengerAsyncActions.fetchMessagesPage(lead.id),
        );

        if (messageListRef.current) {
            const scrollDiff =
                (messageListRef.current.scrollHeight ?? 0) - lastScrollHeight;

            messageListRef.current.scrollTop += scrollDiff;

            disableScrolldown.current = false;
        }
    }

    useEffect(() => {
        if (!scrollDownAsync && disableScrolldown.current !== true) {
            if (messageListRef.current) {
                messageListRef.current.scrollTop =
                    messageListRef.current.scrollHeight;
            }
        }
    }, [scrollDownAsync, messages]);

    return (
        <div className="messenger-message-list" ref={messageListRef}>
            <Waypoint onEnter={loadPage} key={messages.length}>
                <div className="message-async-container-and-waypoint">
                    {loadingMessages && <Spinner size={35}/>}
                </div>
            </Waypoint>

            <div className="message-list">
                {RenderedMessages}
            </div>
        </div>
    );
}

export default MessengerMessageList;
