import "./breadcrumbs.scss";
import { Router } from "../../../../router/router";
import { useLocation } from "react-router-dom";
import { matchPath, PathMatch } from "react-router";
import { useMemo } from "react";

function Breadcrumbs() {
    const location = useLocation();

    const currentPathName = location.pathname;

    let match: PathMatch<string> | null = useMemo(() => {
        for (const route of Router.routes) {
            let m = matchPath({
                path: route.path,
            }, currentPathName);

            if (m != null) return m;
        }

        return null;
    }, [currentPathName]);

    const breadcrumbs = useMemo(() => {
        if (match === null) return [];

        const pathParams = match.params as Record<string, string>;

        return Router.routes
            // Get all routes that contain the current one. ( EXCLUDING ROOT )
            .filter(({ path }) => match!.pathname.includes(path) && path !== "/")
            // Swap out any dynamic routes with their param values.
            .map((route) => {
                const { path, name } = route;

                const extractedRoute = Object.keys(pathParams)
                    .length
                    ? Object.keys(pathParams).reduce(
                        (path, param) =>
                            path.replace(
                                `:${param}`,
                                pathParams?.[param] ?? "",
                            ),
                        path,
                    )
                    : path;

                return {
                    route: extractedRoute,
                    name,
                };
            });
    }, [match]);

    return (
        <div className={"breadcrumbs"}>
            {breadcrumbs.map((breadcrumb, index) => {
                const showRightArrow = index < breadcrumbs.length - 1;
                const Crumb = Router.RouteBreadcrumbs[breadcrumb.name];

                if (Crumb) {
                    return (
                        <div className="breadcrumb" key={index}>
                            <Crumb/>
                            <span className="right-arrow">{showRightArrow && ">"}</span>
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
}

export default Breadcrumbs;
