import './/media_uploader.scss';
import classNames from "classnames";
import { CSSProperties, useCallback } from "react";
import { IconCamera, IconUpload } from "@tabler/icons";
import { Accept, useDropzone } from "react-dropzone";
import { AnimatePresence, motion } from "framer-motion";
import MediaUploaderAttachment from "./MediaUploaderAttachment";
import { ReadFile, Utils } from "../../../utils/utils";

export interface MediaUploaderProps {
    className?: string,
    style?: CSSProperties,
    dropzoneStyle?: CSSProperties,
    addMediaText: string,
    files: ReadFile[],
    dragFileHereText: string,
    acceptAttachments?: Accept,
    multiple?: boolean,
    onAddAttachment: (attachment: ReadFile) => void,
    onRemoveAttachment: (index: number) => void,
}

function MediaUploader({
                           files,
                           dragFileHereText,
                           onAddAttachment,
                           acceptAttachments,
                           addMediaText,
                           style,
                           className,
                           dropzoneStyle,
                           onRemoveAttachment,
                           multiple = true,
                       }: MediaUploaderProps) {
    const isDisabled = !multiple && files.length > 0;

    const onDrop = useCallback(acceptedFiles => {
        if (isDisabled) {
            return;
        }

        for (const file of acceptedFiles) {
            Utils.readFile(file).then(attachment => {
                onAddAttachment(attachment);
            });
        }
    }, [isDisabled, onAddAttachment]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptAttachments,
        multiple,
        disabled: isDisabled,
    });

    return (
        <div className={classNames("media-uploader", className)}
             style={style}>
            <div style={dropzoneStyle}
                 className="media-uploader-dropzone" {...getRootProps()}>
                <input {...getInputProps()} />

                {!isDragActive && (
                    <div className="no-files">
                        <IconCamera size="32"/>

                        <div className="add-media-text">
                            {addMediaText}
                        </div>
                    </div>
                )}

                <AnimatePresence>
                    {isDragActive && (
                        <motion.div initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="dropzone-overlay">
                            <div className="dropzone-overlay-content">
                                <IconUpload/>
                                <div className="drag-file-here-text">{dragFileHereText}</div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <div className="media-uploader-files">
                {files.map((file, index) => {
                    return <MediaUploaderAttachment attachment={file}
                                                    key={index}
                                                    index={index}
                                                    onRemoveAttachment={onRemoveAttachment}/>;
                })}
            </div>
        </div>
    );
}

export default MediaUploader;
