import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { BaseEntity } from "src/store/helpers/entityReducer";
import "./customtable.scss";
import Button, { ButtonStyle } from "../Button/Button";
import { Utils } from "src/utils/utils";
import Spinner from "../Spinner/Spinner";
import useResizeObserver from "use-resize-observer";
import Checkbox from "../Checkbox/Checkbox";
import AnimatedHider from "src/components/Ui/AnimatedHider/AnimatedHider";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import {
    collapseFromRightMinWidthAnimation,
    transition350easeInOut,
} from "../AnimationVariants";
import { motion } from "framer-motion";
import { TablerIconProps } from "@tabler/icons";
import ColorfulIconButton, { ColorfulIconButtonVariant } from "src/components/Ui/ColorfulIconButton/ColorfulIconButton";
import { ReactChildren } from "../../../utils/type_utils";

export interface CustomTableRowContentOptions {
    isSelected: boolean;
    isMultiselectActive: boolean;
}

export interface CustomTableColumn<T extends BaseEntity> {
    name: string;
    headerClassName?: string;
    rowClassName?: string;
    headerContent: string | (() => React.ReactNode | React.ReactElement | React.ComponentType<any>);
    displayHeader?: boolean,
    rowContent: (
        item: T,
        options: CustomTableRowContentOptions,
    ) => React.ReactNode | React.ReactElement | React.ComponentType<any>;
    width?: string;
    minTableWidth?: number;
    sortFunc?: (a: T, b: T) => number;
    disabled?: boolean;
}

export interface CustomTableProps<T extends BaseEntity> {
    autoUpdateIntervalMilis?: number,
    columns: CustomTableColumn<T>[];
    currentPage?: number;
    lastPage?: number;
    hasMore?: boolean;
    onSwitchPage?: (nextPage: number) => void;
    isLoading?: boolean;
    isFullHeight?: boolean;
    selectedItems?: string[];
    onRowClick?: (id: string) => void;
    onMultiselect?: (id: string) => void;
    onSelectAll?: () => void;
    onUnselectAll?: () => void;
    data: T[];
    isSortEnabled?: boolean;
    showHeader?: boolean;
    noResultsMessage?: string;
    customMultiselectRenderer?: (data: T, isChecked: boolean, onMultiselect: (id: string) => void) => ReactChildren,
    headerCustomMultiselectRenderer?: (isChecked: boolean) => ReactChildren,
    headerMultiselectMinWidthString?: string,
    multiselectAction?: () => void;
    multiselectActionIcon?: FunctionComponent<TablerIconProps>;
    multiselectActionName?: string
}

export enum CustomTableSortOrder {
    Unsorted,
    Ascending,
    Descending,
}

export interface CustomTableHeaderProps<T extends BaseEntity> {
    columns: CustomTableColumn<T>[];
    multiselectEnabled: boolean;
    multiselectActive: boolean;
    sortOrder: CustomTableSortOrder;
    sortColumn: string | null;
    isSortEnabled: boolean;
    tableWidth: number;
    selectedItemsLen?: number;
    dataLen: number;
    onMultiselectCheckboxChange: (checked: boolean) => void;
    onChangeSort: (newColumn: string) => void;
    customMultiselectRenderer?: (isChecked: boolean) => ReactChildren,
    multiselectMinWidthString?: string,
    multiselectAction?: () => void;
    multiselectActionIcon?: FunctionComponent<TablerIconProps>;
    multiselectActionName?: string
}

//(v) => {
//     if (v) {
//         onSelectAll();
//     } else {
//         onUnselectAll();
//     }
// }

export function CustomTableHeader<T extends BaseEntity>({
                                                            multiselectEnabled,
                                                            isSortEnabled,
                                                            onChangeSort,
                                                            sortColumn,
                                                            sortOrder,
                                                            tableWidth,
                                                            columns,
                                                            multiselectActive,
                                                            selectedItemsLen,
                                                            dataLen,
                                                            onMultiselectCheckboxChange,
                                                            multiselectAction,
                                                            customMultiselectRenderer,
                                                            multiselectMinWidthString = "3.125rem",
                                                            multiselectActionIcon,
                                                            multiselectActionName
                                                        }: CustomTableHeaderProps<T>) {
    const { t } = useTranslation();
    const isMultiselectChecked = (selectedItemsLen === dataLen) && (dataLen !== 0);

    const Icon = multiselectActionIcon;
    const displayMultiselectActionIcon = Icon && multiselectAction;

    return (
        <div className="header">
            <div className="header-content text-regular text-14 text-color-3">
                <AnimatePresence>
                    {multiselectEnabled && (customMultiselectRenderer ? customMultiselectRenderer(isMultiselectChecked) : (
                        <motion.div
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={collapseFromRightMinWidthAnimation(
                                multiselectMinWidthString,
                            )}
                            className="header-multiselect"
                            transition={transition350easeInOut}
                        >
                            <Checkbox
                                checked={isMultiselectChecked}
                                onChange={onMultiselectCheckboxChange}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>
                {multiselectActive ? (
                    <div className="flex-row flex-between align-center multiselect-header">
                        <div className={"text-medium"}>
                            {t("selected")}: {selectedItemsLen}
                        </div>
                        {displayMultiselectActionIcon && (<div className="flex-row">
                                <div className="flex-center-both text-medium mr-20">
                                    {multiselectActionName}:
                                </div>
                                <AnimatedHider open={multiselectActive}>
                                    <ColorfulIconButton
                                        variant={ColorfulIconButtonVariant.WHITE}
                                        onClick={multiselectAction}>

                                        {Icon && <Icon/>}
                                    </ColorfulIconButton>
                                </AnimatedHider>
                            </div>
                        )}
                    </div>
                ) : (
                    columns.map((column, index) => {

                        if (column.displayHeader === false) {
                            return null;
                        }

                        if (tableWidth && column.minTableWidth) {
                            if (column.minTableWidth > tableWidth) {
                                return null;
                            }
                        }

                        const thisColumnSortInactive =
                            sortOrder === CustomTableSortOrder.Unsorted ||
                            sortColumn !== column.name;

                        const headerRender = typeof column.headerContent === "string" ? column.headerContent : column.headerContent();

                        return (
                            <AnimatePresence key={index}>
                                {!column.disabled && (
                                    <motion.div
                                        exit={{ flex: 0, overflow: "hidden" }}
                                        transition={transition350easeInOut}
                                        style={{ maxWidth: column.width }}
                                        className={classNames("header-column", [
                                            column.headerClassName,
                                        ])}
                                    >
                                        {headerRender}
                                        {isSortEnabled && column.sortFunc && (
                                            <div
                                                className={classNames(
                                                    "sort-arrows",
                                                    {
                                                        active: !thisColumnSortInactive,
                                                    },
                                                )}
                                                onClick={() =>
                                                    onChangeSort(column.name)
                                                }
                                            >
                                                {thisColumnSortInactive ? (
                                                    <>
                                                        <span className="material-icons-round">
                                                            keyboard_arrow_up
                                                        </span>
                                                        <span className="material-icons-round">
                                                            keyboard_arrow_down
                                                        </span>
                                                    </>
                                                ) : sortOrder ===
                                                CustomTableSortOrder.Ascending ? (
                                                    <span className="material-icons-round">
                                                        keyboard_arrow_up
                                                    </span>
                                                ) : (
                                                    <span className="material-icons-round">
                                                        keyboard_arrow_down
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        );
                    })
                )}
            </div>
        </div>
    );
}

const CustomTable = <T extends BaseEntity>({
                                               autoUpdateIntervalMilis,
                                               columns,
                                               currentPage,
                                               lastPage,
                                               onSwitchPage,
                                               hasMore,
                                               selectedItems = [],
                                               onRowClick = () => {
                                               },
                                               onSelectAll = () => {
                                               },
                                               onUnselectAll = () => {
                                               },
                                               onMultiselect,
                                               isLoading = false,
                                               isFullHeight = false,
                                               isSortEnabled = false,
                                               showHeader = true,
                                               data,
                                               multiselectAction,
                                               noResultsMessage,
                                               customMultiselectRenderer,
                                               headerCustomMultiselectRenderer,
                                               headerMultiselectMinWidthString,
                                               multiselectActionIcon,
                                               multiselectActionName
                                           }: CustomTableProps<T>) => {
    const { t } = useTranslation();
    const [forceUpdate, setForceUpdate] = useState(0);
    const tableRef = useRef<HTMLDivElement>(null);
    const { width: tableWidth = 1 } = useResizeObserver<HTMLDivElement>({
        ref: tableRef,
    });
    const [sortOrder, setSortOrder] = useState(CustomTableSortOrder.Unsorted);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const isSortActive =
        sortOrder !== CustomTableSortOrder.Unsorted && sortColumn !== null;
    const sortFunc = isSortActive
        ? columns.find((column) => column.name === sortColumn)?.sortFunc
        : null;
    const multiselectEnabled = onMultiselect !== undefined;
    const isMultiselectActive = selectedItems.length > 1;
    const hasPagination =
        currentPage !== undefined &&
        lastPage !== undefined &&
        onSwitchPage !== undefined &&
        hasMore !== undefined;

    const onChangeSort = useCallback(
        (columnName: string) => {
            if (sortColumn !== columnName) {
                setSortColumn(columnName);
                setSortOrder(CustomTableSortOrder.Ascending);
            } else {
                switch (sortOrder) {
                    case CustomTableSortOrder.Unsorted:
                        setSortOrder(CustomTableSortOrder.Ascending);
                        break;
                    case CustomTableSortOrder.Ascending:
                        setSortOrder(CustomTableSortOrder.Descending);
                        break;
                    case CustomTableSortOrder.Descending:
                        setSortOrder(CustomTableSortOrder.Unsorted);
                        break;
                    default:
                        setSortOrder(CustomTableSortOrder.Unsorted);
                }
            }
        },
        [sortColumn, setSortColumn, sortOrder, setSortOrder],
    );

    useEffect(() => {
        let updateInt: number | null = null;
        if (autoUpdateIntervalMilis) {
            setInterval(() => {
                setForceUpdate(forceUpdate + 1);
            }, autoUpdateIntervalMilis);
        }

        return () => {
            if (updateInt) {
                clearTimeout(updateInt);
            }
        };
    }, [autoUpdateIntervalMilis, setForceUpdate, forceUpdate]);

    const onMultiselectCheckboxChange = useCallback(
        (v) => {
            if (v) {
                onSelectAll();
            } else {
                onUnselectAll();
            }
        },
        [onSelectAll, onUnselectAll],
    );

    const displayData = useMemo(() => {
        if (isSortActive && sortFunc) {
            return [...data].sort(sortFunc);
        } else {
            return data;
        }
    }, [isSortActive, data, sortFunc]);

    return (
        <div
            ref={tableRef}
            className={classNames("custom-table", {
                "full-height": isFullHeight,
                multiselect: isMultiselectActive,
            })}
        >
            {showHeader && (
                <CustomTableHeader
                    columns={columns}
                    multiselectAction={multiselectAction}
                    onMultiselectCheckboxChange={onMultiselectCheckboxChange}
                    multiselectMinWidthString={headerMultiselectMinWidthString}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    tableWidth={tableWidth}
                    onChangeSort={onChangeSort}
                    selectedItemsLen={selectedItems?.length}
                    dataLen={data.length}
                    isSortEnabled={isSortEnabled}
                    multiselectActive={isMultiselectActive}
                    multiselectEnabled={multiselectEnabled}
                    customMultiselectRenderer={headerCustomMultiselectRenderer}
                    multiselectActionIcon={multiselectActionIcon}
                    multiselectActionName={multiselectActionName}
                />
            )}
            <div className="content">
                {isLoading ? (
                    <Spinner/>
                ) : (
                    displayData.length === 0 ? (
                        <div
                            className="flex-1 flex-center-both text-color-3">{noResultsMessage ?? t("no_results_found")}</div>
                    ) : (

                        displayData.map((item) => {
                            const isSelected = selectedItems?.includes(item.id);
                            return (
                                <div
                                    className={classNames("table-row", {
                                        selected: isSelected,
                                    })}
                                    key={item.id}
                                    onClick={() => onRowClick(item.id)}
                                >
                                    <div
                                        className={classNames("table-row-content")}
                                    >
                                        <AnimatePresence>
                                            {multiselectEnabled && (customMultiselectRenderer ? customMultiselectRenderer(item, isSelected, onMultiselect) : (
                                                <motion.div
                                                    initial="initial"
                                                    animate="in"
                                                    exit="out"
                                                    variants={collapseFromRightMinWidthAnimation(
                                                        "3.125rem",
                                                    )}
                                                    transition={
                                                        transition350easeInOut
                                                    }
                                                    className="row-multiselect"
                                                >
                                                    <Checkbox
                                                        checked={isSelected}
                                                        onChange={() => {
                                                            if (onMultiselect) {
                                                                onMultiselect(
                                                                    item.id,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </motion.div>
                                            ))}
                                        </AnimatePresence>

                                        {columns.map((column, index) => {
                                            let renderColumn = true;

                                            if (
                                                tableWidth &&
                                                column.minTableWidth
                                            ) {
                                                if (
                                                    column.minTableWidth >
                                                    tableWidth
                                                ) {
                                                    renderColumn = false;
                                                }
                                            }

                                            if (column.disabled) {
                                                renderColumn = false;
                                            }

                                            // if (!renderColumn) return null;

                                            return (
                                                <AnimatePresence key={index}>
                                                    {renderColumn && (
                                                        <motion.div
                                                            style={{ maxWidth: column.width }}
                                                            transition={transition350easeInOut}
                                                            className={classNames(
                                                                "content-row-column text-16 text-color-1",
                                                                {
                                                                    "text-regular":
                                                                        !isSelected,
                                                                    "text-medium":
                                                                    isSelected,
                                                                },
                                                                [
                                                                    column.rowClassName,
                                                                ],
                                                            )}
                                                        >
                                                            {column.rowContent(
                                                                item,
                                                                {
                                                                    isSelected,
                                                                    isMultiselectActive,
                                                                },
                                                            )}
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })
                    )
                )}
            </div>
            {hasPagination && (
                <div className="footer">
                    <div className="pagination">
                        {currentPage !== 1 && (
                            <Button
                                className={classNames("prev-next-btn")}
                                onClick={() => onSwitchPage(currentPage - 1)}
                                buttonStyle={ButtonStyle.Text}
                            >
                                <span
                                    style={{ paddingRight: 8 }}
                                    className="material-icons-round"
                                >
                                    arrow_back_ios
                                </span>
                                Naz
                            </Button>
                        )}

                        <div className="page-numbers">
                            {Utils.getPaginationRange(
                                currentPage,
                                lastPage,
                            ).map((num) => {
                                const isCurrent = num === currentPage;
                                return (
                                    <Button
                                        key={`pagination-page-${num}`}
                                        buttonStyle={ButtonStyle.Text}
                                        onClick={
                                            isCurrent
                                                ? () => {
                                                }
                                                : () => onSwitchPage(num)
                                        }
                                        className={classNames("page-button", {
                                            "current-page": isCurrent,
                                        })}
                                    >
                                        {num}
                                    </Button>
                                );
                            })}
                        </div>

                        {hasMore && (
                            <Button
                                className={classNames("prev-next-btn")}
                                onClick={() => onSwitchPage(currentPage + 1)}
                                buttonStyle={ButtonStyle.Text}
                            >
                                Nap
                                <span
                                    style={{ paddingLeft: 8 }}
                                    className="material-icons-round"
                                >
                                    arrow_forward_ios
                                </span>
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomTable;
