import React from "react";
import "./select_field.scss";
import ReactSelect, { ActionMeta, OnChangeValue } from "react-select";
import { WithClassname } from "src/utils/type_utils";
import classNames from "classnames";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

export const SELECT_CREATE_NEW_OPTION_KEY = "create_new_option_key";

export enum SelectFieldVariant {
    Gray = "variant-gray",
    Dark = "variant-dark"
}

export interface SelectFieldOption {
    label: string,
    value: string,
    isSeparated?: boolean,
}

interface SelectFieldProps<Option, IsMulti extends boolean = false> {
    value: Option,
    options?: Option[],
    onChange: (option: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => void,
    multiple?: IsMulti,
    placeholder?: string,
    readonly?: boolean,
    label?: string,
    error?: string,
    onCreateOption?: (input: string) => void,
    menuOverModal?: boolean,
    async?: boolean,
    selectFieldStyle?: SelectFieldVariant;
    clearable?: boolean
}

function SelectField<Option = SelectFieldOption, IsMulti extends boolean = false>(
    {
        className,
        options,
        value,
        onChange,
        multiple,
        placeholder,
        readonly,
        label,
        error,
        onCreateOption,
        async,
        menuOverModal = false,
        selectFieldStyle,
        clearable,
    }: WithClassname<SelectFieldProps<Option, IsMulti>>,
) {

    const { t } = useTranslation();

    return (
        <div
            className={classNames("select-field-container", className, selectFieldStyle, { readonly, error })}>
            {label && (
                <label className={classNames("input-label")}>
                    {label}
                    {/*<div className="input-error">{error}</div>  TODO*/}
                </label>
            )}
            {onCreateOption !== undefined ? (
                <CreatableSelect className="select-field"
                                 isLoading={async}
                                 loadingMessage={() => `${t("loading")}...`}
                                 classNamePrefix="select-field"
                                 placeholder={placeholder ?? ""}
                                 options={options}
                                 onCreateOption={onCreateOption}
                                 blurInputOnSelect={!multiple}
                                 value={value}
                                 isMulti={multiple}
                                 isClearable={clearable}
                                 menuPortalTarget={document.body}
                                 menuPlacement={"auto"}
                                 onChange={onChange}/>
            ) : (
                (readonly && !value) ? (
                        <div className="unknown-value">{t("unknown")}</div>
                    ) :
                    <ReactSelect
                        isLoading={async}
                        loadingMessage={() => `${t("loading")}...`}
                        className="select-field"
                        classNamePrefix="select-field"
                        placeholder={placeholder ?? ""}
                        isClearable={clearable}
                        options={options}
                        value={value}
                        isMulti={multiple}
                        onChange={onChange}
                        menuPortalTarget={document.body}
                        menuPlacement={"auto"}
                        styles={{
                            menuPortal: (base, props) => {
                                const nextStyle = {
                                    ...base,
                                };

                                if (menuOverModal) {
                                    nextStyle.zIndex = 10000000000;
                                }

                                return nextStyle;
                            },
                            option: (provided, { data, ...rest }) => {
                                const dataObj = data as unknown as SelectFieldOption;
                                const isCreateNewOption = dataObj.value.startsWith(SELECT_CREATE_NEW_OPTION_KEY);
                                const isSeparatedOption = dataObj.isSeparated;

                                const nextStyle = {
                                    ...provided,
                                };

                                if (isCreateNewOption || isSeparatedOption) {
                                    //$accentBlue
                                    nextStyle.borderTop = "1px solid #0095FF";
                                    nextStyle.padding = "1rem 12px";
                                    nextStyle.position = "sticky";
                                    nextStyle.bottom = "0";
                                    //$white
                                    nextStyle.background = "#FFFFFF";
                                }

                                return nextStyle;
                            },
                        }}
                    />
            )}
        </div>
    );
}

export default SelectField;
