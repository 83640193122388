import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { io, Socket } from "socket.io-client";
import { API } from "src/api/api";
import { IS_DEV } from "src/utils/consts";
import {
    getSocketActionToAsyncEventMapper,
    getSocketActionToEventMapper,
    SocketEventRegexes
} from "src/store/helpers/socketActions";
import { ReactChildren } from "../../utils/type_utils";
import { useAppSelector } from "../../store/hooks";
import { loginActions, loginSelectors } from "../../store/slices/loginSlice";
import { StorageKeys } from "../../utils/storage_keys";

const SocketIOContextInit = {
    connect: () => {
        console.log("connect?23");
    },
    disconnect: () => {
    },
};

const SocketIOContext = createContext(SocketIOContextInit);

export interface SocketIOProviderProps {
    children: ReactChildren;
}

export function useSocket() {
    return useContext(SocketIOContext);
}

function SocketIOProvider({ children }: SocketIOProviderProps) {
    const [socket, setSocket] = useState<Socket | null>(null);

    const effectiveUser = useAppSelector(loginSelectors.effectiveUser);

    const dispatch = useDispatch();
    const socketIOContextValue = {
        connect: () => {
            if (socket === null && !process.env.REACT_APP_DISABLE_SOCKET) {
                const newSocket = io(API.endpoint, {
                    path: "/ws",
                    reconnection: true,
                    reconnectionDelay: 5000,
                    reconnectionAttempts: Infinity,
                    reconnectionDelayMax: 10000,
                    auth: { token: localStorage.getItem(StorageKeys.Token) },
                    extraHeaders: {
                        "x-iam": "main",
                    },
                });

                setSocket(newSocket);
            }
        },
        disconnect: () => {
            if (socket != null) {
                socket.close();
                setSocket(null);
            }
        },
    };

    const dispatchEvent = useCallback((transformedEventName: string, payload: any) => {
        const type = `SOCKET_${transformedEventName}`;
        // TODO, uncomment
        const regularActions = getSocketActionToEventMapper()[type] ?? [];
        const asyncActions = getSocketActionToAsyncEventMapper()[type] ?? [];

        regularActions.forEach(action => dispatch(action(payload)));
        asyncActions.forEach(action => dispatch(action(payload)));

        dispatch({
            type,
            payload,
        });
    }, [dispatch]);

    useEffect(() => {
        if (socket) {
            socket.disconnect();
            socket.connect();
        }
    }, [effectiveUser])

    useEffect(() => {
        if (socket) {
            socket.on("connect", () => {
                console.log("Connect");
            });

            socket.on("connect_error", (err) => {
                if (err.message === "invalid credentials") {
                    if (typeof socket.auth === "object") {
                        socket.auth.token = localStorage.getItem(StorageKeys.Token);
                    }
                }

                setTimeout(() => {
                    socket.connect();
                }, 2000);
            });

            socket.onAny((eventName, message) => {
                try {
                    const payload = JSON.parse(message);

                    if (IS_DEV || true) {
                        console.groupCollapsed(eventName);
                        console.log(payload);
                        console.groupEnd();
                    }

                    // if its in format of "entName:id:...."
                    for (let [channel, regex] of Object.entries(SocketEventRegexes)) {
                        let match = eventName.match(regex);

                        if (match) {
                            const { eventName, entityId } = match.groups;
                            const transformedEventName = `${channel}_${eventName}`;
                            dispatchEvent(transformedEventName, { channelEntityId: entityId, payload });
                            return;
                        }
                    }

                    // if its: agent_...
                    const transformedEventName = eventName.replaceAll(":", "_");
                    dispatchEvent(transformedEventName, payload);
                } catch (ex) {
                    // console.error(ex);
                }
            });
        }
    }, [dispatchEvent, socket, dispatch]);

    return (
        <SocketIOContext.Provider value={socketIOContextValue}>
            {children}
        </SocketIOContext.Provider>
    );
}

export default SocketIOProvider;
