import React, { FunctionComponent, useState } from "react";
import classNames from "classnames";
import { TablerIconProps } from "@tabler/icons";
import { Route } from "../../../../router/router";
import { RawMenuItem } from "../Menu";
import GIcon from "../../GIcon/GIcon";
import AnimatedHider, { AnimatedHiderVariant } from "../../AnimatedHider/AnimatedHider";
import { useNavigate } from "react-router-dom";

export interface MenuItemProps {
    currentRoute?: Route,
    onClick?: React.MouseEventHandler;
    title: string;
    icon: FunctionComponent<TablerIconProps>;
    isSelected: boolean;
    isSubroute: boolean;
    subRoutes?: RawMenuItem[],
}

const MenuItem = ({
                      title,
                      icon,
                      currentRoute,
                      subRoutes = [],
                      onClick,
                      isSelected,
                      isSubroute = false,
                  }: MenuItemProps) => {
    const Icon = icon;
    const navigate = useNavigate();
    const isSubrouteParent = subRoutes.length > 0;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <div
                onClick={onClick}
                className={classNames("menu-item", { isSelected, isSubroute })}>
                <div className="menu-item-inner">
                    <div className="icon-expansion-container">
                        <div className="icon-container">
                            <Icon/>
                        </div>
                        {isSubrouteParent ? (
                            <GIcon className={classNames("expander-icon", { "expanded": isExpanded })}
                                   icon={"expand_more"}
                                   onClick={(e) => {
                                       e.stopPropagation();
                                       setIsExpanded(!isExpanded);
                                   }}/>
                        ) : (
                            <div className="icon-spacer"/>
                        )}
                    </div>
                    <span className="title">{title}</span>
                </div>
            </div>
            <AnimatedHider open={isExpanded}
                           variant={AnimatedHiderVariant.Collapse}>
                {subRoutes.map(subroute => {
                    return (
                        <MenuItem
                            onClick={() => {
                                navigate(subroute.routePath);
                            }}
                            isSubroute={true}
                            key={subroute.title}
                            icon={subroute.icon}
                            isSelected={currentRoute?.name === subroute.routeName}
                            title={subroute.title}
                        />
                    );
                })}
            </AnimatedHider>
        </>
    );
};

export default MenuItem;
