import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserUtils } from "src/entities/User/User";
import { AsyncAction } from "src/store/helpers/entityReducer";
import { RootState } from "src/store/store";
import i18 from "../../i18n/config";
import { IUserForm } from "src/entities/User/UserForm";
import { LoginApi } from "../../api/login";
import { StorageKeys } from "../../utils/storage_keys";
import { tryToParseImpersonationFromLocalStorage } from "../../utils/utils";

export interface LoginErrors {
    email?: string,
    password?: string,
    generic?: string,
}

export interface LoginState {
    loginAsync: boolean,
    userAsync: boolean,
    passwordAsync: boolean,
    loginErrors: LoginErrors,
    user: User | null,
    impersonatedUser: User | null,
}

const initialState: LoginState = {
    loginAsync: false,
    userAsync: false,
    passwordAsync: false,
    loginErrors: {},
    user: null,
    impersonatedUser: null,
};

const performLogin = (email: string, password: string): AsyncAction<Promise<boolean>> => {
    return async (dispatch) => {

        dispatch(loginActions.setLoginAsync(true));

        return await LoginApi.doLogin(email, password)
            .then(() => dispatch(getMe()))
            .catch((r) => {
                if (r.status === 401) {
                    dispatch(loginActions.setLoginErrors({ generic: `${i18.t("invalid_password_or_email_please_verify_your_credentials")}` }));
                } else {
                    dispatch(loginActions.setLoginErrors({ generic: `${i18.t("an_error_has_occurred")}` }));
                }
                return false;
            })
            .finally(() => dispatch(loginActions.setLoginAsync(false)));
    };
};

const getMe = (): AsyncAction<Promise<boolean>> => {
    return async (dispatch) => {

        dispatch(loginActions.setUserAsync(true));

        const me = await LoginApi.doMe()
            .finally(() => dispatch(loginActions.setUserAsync(false)));

        if (me) {
            dispatch(loginActions.setUser(me));

            const impersonatedUser = tryToParseImpersonationFromLocalStorage();
            dispatch(loginActions.setImpersonateUser(impersonatedUser));
        }

        return me !== null;
    };
};

const updateUser = ({
                        userId,
                        userForm,
                    }: { userId: string, userForm: IUserForm }): AsyncAction<Promise<User | null>> => {
    return async (dispatch) => {

        dispatch(loginActions.setUserAsync(true));

        try {
            const r = await LoginApi.editUser(userId, userForm);

            if (r) {
                dispatch(loginActions.setUser(r));
            }

            return r;
        } finally {
            dispatch(loginActions.setUserAsync(false));
        }
    };
};

function impersonateUser(user: User | null): AsyncAction {
    return async (dispatch, getState) => {
        const state = getState();

        if (user === null || (state.login.user?.id === user?.id)) {
            // this is us, disable impersonation
            localStorage.removeItem(StorageKeys.Impersonate);
            dispatch(loginActions.setImpersonateUser(null));
            return;
        }

        if (state.login.impersonatedUser?.id === user?.id) {
            // we're already impersonating this user, nothing to do
            return;
        }

        let actualUser = await LoginApi.doMe(user.email);

        localStorage.setItem(StorageKeys.Impersonate, JSON.stringify(actualUser));

        dispatch(loginActions.setImpersonateUser(actualUser));
    };
}

export const loginSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        setImpersonateUser(state, { payload }: PayloadAction<User | null>) {
            state.impersonatedUser = payload;
        },
        setLoginAsync(state, { payload }: PayloadAction<boolean>) {
            state.loginAsync = payload;
        },
        setUserAsync(state, { payload }: PayloadAction<boolean>) {
            state.userAsync = payload;
        },
        setLoginErrors(state, { payload }: PayloadAction<LoginErrors>) {
            state.loginErrors = payload;
        },
        setUser(state, { payload }: PayloadAction<User>) {
            state.user = payload;
        },
    },
});


const userSelector = (state: RootState) => state.login.user;

const impersonatedUserSelector = (state: RootState) => state.login.impersonatedUser;

const effectiveUser = createSelector([userSelector, impersonatedUserSelector], (user, impersonatedUser) => {
    if (impersonatedUser) {
        return impersonatedUser;
    }

    return user;
});

const isGrantedSuperAdminLevel = createSelector([userSelector], (user) => {
    return Boolean(user && UserUtils.isGrantedSuperAdminLevel(user));
});

export const loginActions = loginSlice.actions;
export const loginReducer = loginSlice.reducer;

export const loginSelectors = {
    user: userSelector,
    isGrantedSuperAdminLevel,
    impersonatedUserSelector,
    effectiveUser,
};

export const loginAsyncActions = { performLogin, getMe, updateUser, impersonateUser };
