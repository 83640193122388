import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./message_template_category.scss";
import Checkbox from "src/components/Ui/Checkbox/Checkbox";
import { IconCheck, IconChevronDown, IconCirclePlus, IconLock, IconPencil, IconTrash, IconX } from "@tabler/icons";
import classNames from "classnames";
import useHover from "src/utils/hooks/useHover";
import AlertUtils from "src/components/Ui/Alert/alert_utils";
import { useTranslation } from "react-i18next";
import InputField from "src/components/Ui/InputField/InputField";
import TextareaField from "src/components/Ui/TextareaField/TextareaField";
import AnimatedHider from "src/components/Ui/AnimatedHider/AnimatedHider";
import {
    IMessageTemplate,
    IMessageTemplateCategory,
} from "src/entities/messageTemplateCategory/IMessageTemplateCategory";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    messageTemplatesActions,
    messageTemplatesAsyncActions,
    messageTemplatesSelectors,
} from "src/store/slices/messageTemplatesSlice";
import { MessageTemplateForm } from "src/entities/messageTemplateCategory/MessageTemplateCategoryForm";
import { useDispatch } from "react-redux";
import {
    messageTemplateCategoriesAsyncActions,
} from "src/store/slices/messageTemplateCategoriesSlice";
import Spinner from "src/components/Ui/Spinner/Spinner";
import { loginSelectors } from "src/store/slices/loginSlice";
import { ReadFile, Utils } from "src/utils/utils";
import ReactTooltip from "react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import LinesEllipsis from "react-lines-ellipsis";
import { PredefinedMessagesFormMode } from "../PredefinedMessages";
import MediaUploader from "../../../../../components/Ui/MediaUploader/MediaUploader";
import useMediaUploader from "../../../../../utils/hooks/useMediaUploader";
import { ACCEPT_ATTACHMENTS } from "../../../../../utils/consts";
import { MediaObjectUtils } from "../../../../../entities/Mediaobject/MediaObject";
import MediaUploaderAttachment from "../../../../../components/Ui/MediaUploader/MediaUploaderAttachment";
import { cloneDeep } from "lodash";
import Dropdown, { DropdownAnchorEdge } from "../../../../../components/Ui/Dropdown/Dropdown";
import GIcon from "../../../../../components/Ui/GIcon/GIcon";
import DropdownItem from "../../../../../components/Ui/Dropdown/DropdownItem";

export interface MessageTemplateCategoryProps {
    messageTemplateCategory: IMessageTemplateCategory;
    footerButtonType: PredefinedMessagesFormMode;
    searchActive: boolean;
}

interface MessageTemplateProps {
    messageTemplate: IMessageTemplate;
    setAsyncMessageId: (asyncMessageId: string) => void;
    isSelected: boolean;
}

const MessageTemplate = ({
                             messageTemplate,
                             setAsyncMessageId,
                             isSelected,
                         }: MessageTemplateProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { attachments, setAttachments, removeAttachment, addAttachment } = useMediaUploader();

    const [editAsync, setEditAsync] = useState(false);

    const editingId = useAppSelector(state => state.messageTemplates.editingId);
    const isEditActive = editingId !== null;
    const isEditMode = useAppSelector(state => state.messageTemplates.editingId) === messageTemplate.id;

    const [messageTemplateForm, setMessageTemplateForm] = useState<MessageTemplateForm>({});
    const user = useAppSelector(loginSelectors.user);

    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);


    const onSelectTemplate = useCallback(() => {
        dispatch(messageTemplatesActions.selectOrDeselectEntity(messageTemplate.id));
    }, [dispatch, messageTemplate]);

    const onMessageTemplateUpdate = useCallback(async (messageTemplate: IMessageTemplate) => {
        let form: MessageTemplateForm = {
            text: messageTemplate.text,
        };

        if (messageTemplate.attachment) {
            const file = await MediaObjectUtils.toReadFile(messageTemplate.attachment);
            if (file) {
                setAttachments([file]);
            } else {
                setAttachments([]);
            }
        } else {
            setAttachments([]);
        }

        setMessageTemplateForm(form);
    }, [setMessageTemplateForm]);

    useEffect(() => {
        console.log('update message template');
        onMessageTemplateUpdate(messageTemplate);
    }, [messageTemplate, onMessageTemplateUpdate]);


    const onDeleteClick = useCallback(async (template: IMessageTemplate) => {
        const r = await AlertUtils.dangerAlert({
            title: t("deletion"),
            message: t("are_you_sure_you_want_to_delete_selected_message") + "?",
            positiveButtonText: t("delete"),
        });

        if (r) {
            await dispatch(messageTemplatesAsyncActions.deleteMessageTemplate(template));
        }
    }, [dispatch, t]);

    const onEditMessageTemplate = useCallback(async () => {
        setEditAsync(true);
        setAsyncMessageId(messageTemplate.category.id);
        await dispatch(messageTemplatesAsyncActions.updateMessageTemplate(messageTemplate.id, messageTemplateForm, attachments[0] ?? null));
        await dispatch(messageTemplatesActions.singleSelectOrDeselectEntity(messageTemplate.id));
        dispatch(messageTemplatesActions.setEditingId(null));
        setAttachments([]);
        setEditAsync(false);
    }, [dispatch, setEditAsync, setAttachments, messageTemplateForm, messageTemplate.id, attachments, messageTemplate.category.id, setAsyncMessageId]);


    return (
        <div className={classNames("message-template", { "edit-active": isEditActive, "editing-self": isEditMode })}
             ref={hoverRef}>
            <Checkbox className="mr-16 mt-10"
                      checked={isSelected}
                      onChange={onSelectTemplate}
            />
            {isEditMode ? (
                <div className="template-textarea-media-uploader-container">
                    <TextareaField className="template-textarea-field mt-8"
                                   autoFocus
                                   onChange={event => {
                                       setMessageTemplateForm(form => {
                                           const f = cloneDeep(form);
                                           f.text = event.target.value;
                                           return f;
                                       });
                                   }}
                                   value={messageTemplateForm.text}
                                   rows={3}/>
                    <MediaUploader addMediaText={t("add_attachment")}
                                   files={attachments}
                                   multiple={false}
                                   className="mt-10"
                                   dragFileHereText={t("add_files")}
                                   acceptAttachments={ACCEPT_ATTACHMENTS}
                                   onAddAttachment={(attachment) => {
                                       addAttachment(attachment);
                                   }}
                                   onRemoveAttachment={(attachment) => {
                                       setMessageTemplateForm(form => {
                                           form.attachment = null;
                                           return form;
                                       });

                                       removeAttachment(attachment);
                                   }}/>
                </div>
            ) : (
                <div className="message-template-view">
                    <div className="template-text">
                        {messageTemplate.text}
                    </div>
                    <div className="mt-10">
                        {messageTemplate.attachment && (
                            <MediaUploaderAttachment attachment={messageTemplate.attachment}
                                                     index={0}
                                                     readonly={true}
                                                     onRemoveAttachment={() => {
                                                     }}/>
                        )}
                    </div>
                </div>
            )}
            <div className="template-icons-container mt-8">
                <AnimatedHider open={isHover} className="flex-column">
                    {(messageTemplate.isPrivate && messageTemplate.user?.id === user?.id) ? (
                        <div className="icon-wrapper"
                             data-tip={t("no_action_has_been_approved_on_this_message")}>
                            <IconLock size={18} className="text-color-3"/>
                        </div>
                    ) : (
                        isEditMode ? (
                            editAsync ? (
                                <Spinner small={true}/>
                            ) : (
                                <>
                                    <div className="icon-wrapper blue-icon"
                                         onClick={onEditMessageTemplate}
                                         data-tip={t("done")}>
                                        <IconCheck size={18} className="text-color-3"/>
                                    </div>
                                    <div className="icon-wrapper gray-icon" onClick={() => {
                                        dispatch(messageTemplatesActions.deselectEntity(messageTemplate.id));
                                        dispatch(messageTemplatesActions.setEditingId(null));
                                    }}
                                         data-tip={t("cancel")}>
                                        <IconX size={18} className="text-color-3"/>
                                    </div>
                                </>
                            )
                        ) : (
                            <>
                                <Dropdown innerClassName="actions-dropdown"
                                          anchorEdge={DropdownAnchorEdge.BottomRight}
                                          anchor={
                                              <div>
                                                  <GIcon icon={"more_vert"} className="icon-more"/>
                                              </div>
                                          }>
                                    <DropdownItem className="flex-row align-center"
                                                  onClick={() => {
                                                      dispatch(messageTemplatesActions.singleSelectEntity(messageTemplate.id));
                                                      dispatch(messageTemplatesActions.setEditingId(messageTemplate.id));
                                                  }}>
                                        {t("edit")}
                                    </DropdownItem>
                                    <DropdownItem className="flex-row align-center"
                                                  onClick={() => onDeleteClick(messageTemplate)}>
                                        {t("delete")}
                                    </DropdownItem>
                                </Dropdown>
                            </>
                        )
                    )}
                </AnimatedHider>
            </div>
            <ReactTooltip place={"bottom"}/>
        </div>
    );
};

const MessageTemplateCategory = ({
                                     messageTemplateCategory,
                                     footerButtonType,
                                     searchActive,
                                 }: MessageTemplateCategoryProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isExpanded, setIsExpanded] = useState(false);
    const [editTitleActive, setEditTitleActive] = useState(false);
    const [addAsync, setAddAsync] = useState(false);
    const [addingNew, setAddingNew] = useState(false);
    const [newMessageTemplateText, setNewMessageTemplateText] = useState("");
    const [newMessageTemplateAttachment, setNewMessageTemplateAttachment] = useState<ReadFile | null>(null);

    const [asyncMessageId, setAsyncMessageId] = useState("");

    const [newCategoryName, setNewCategoryName] = useState(messageTemplateCategory.name);
    const [isCategoryNameClamped, setIsCategoryNameClamped] = useState(false);

    const { attachments, setAttachments, removeAttachment, addAttachment } = useMediaUploader();

    const async = useAppSelector(state => {
        const { addEditAsync, deleteAsync } = state.messageTemplates;
        return addEditAsync || deleteAsync;
    });

    const selectedTemplates = useAppSelector(messageTemplatesSelectors.selectedTemplates);

    const messageTemplateCategoryMap = useAppSelector(messageTemplatesSelectors.messageTemplateCategoryMap);

    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    const isCategorySelected = useMemo(() => {
        return (selectedTemplates.length > 0 && messageTemplateCategory.messageTemplates.length > 0)
            && Utils.arrayIncludesArray(selectedTemplates, messageTemplateCategory.messageTemplates.map(t => t.id));
    }, [messageTemplateCategory, selectedTemplates]);

    const onSelectMessageTemplateCategory = useCallback(() => {
        messageTemplateCategory.messageTemplates.filter(t => selectedTemplates.includes(t.id) === isCategorySelected).forEach(template => {
            dispatch(messageTemplatesActions.selectOrDeselectEntity(template.id));
        });
    }, [isCategorySelected, messageTemplateCategory, selectedTemplates, dispatch]);

    const onCancelAddingMessageTemplate = useCallback(() => {
        setAddingNew(false);
        setNewMessageTemplateText("");
    }, []);

    const onAddNewMessageTemplate = useCallback(async () => {
        if (newMessageTemplateText || newMessageTemplateAttachment) {
            setAddAsync(true);
            await dispatch(messageTemplatesAsyncActions.createMessageTemplate({
                text: newMessageTemplateText,
                category: messageTemplateCategory.id,
            }, newMessageTemplateAttachment));

            onCancelAddingMessageTemplate();
            setAddAsync(false);
            setAttachments([]);
            setNewMessageTemplateAttachment(null);
        }
    }, [dispatch, newMessageTemplateText, newMessageTemplateAttachment, setAddAsync, messageTemplateCategory.id, onCancelAddingMessageTemplate]);


    const onCancelEditingCategory = () => {
        setEditTitleActive(false);
    };


    const onEditCategory = useCallback(async () => {
        await dispatch(messageTemplateCategoriesAsyncActions.updateEntityAction({
            entityId: messageTemplateCategory.id,
            entityForm: { name: newCategoryName },
        }));
        onCancelEditingCategory();
    }, [dispatch, newCategoryName, messageTemplateCategory.id]);

    useEffect(() => {
        return () => {
            if (footerButtonType === PredefinedMessagesFormMode.Edit)
                setEditTitleActive(false);
        };
    }, [footerButtonType]);

    useEffect(() => {
        setIsExpanded(searchActive);
    }, [searchActive]);

    const thisCategory = messageTemplateCategoryMap[messageTemplateCategory.id];

    const handleReflow = (rleState: { clamped: boolean, text: string }) => {
        setIsCategoryNameClamped(rleState.clamped);
    };

    return (
        <div className="predefined-message-card-container" ref={hoverRef}>
            {editTitleActive ? (
                <div className="flex-row mb-8">
                    <InputField value={newCategoryName}
                                onChange={(e) => {
                                    setNewCategoryName(e.target.value);
                                }}
                                className="category-input-field"/>
                    <div className="template-icons-container mt-8 ml-10">
                        <div className="icon-wrapper blue-icon"
                             onClick={onEditCategory}
                             data-tip={t("done")}>
                            <IconCheck size={18} className="text-color-3"/>
                        </div>
                        <div className="icon-wrapper gray-icon"
                             onClick={onCancelEditingCategory}
                             data-tip={t("cancel")}>
                            <IconX size={18} className="text-color-3"/>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classNames("predefined-message-card")}>
                    <div className={classNames("card-header", { expanded: isExpanded })}
                         onClick={() => setIsExpanded(!isExpanded)}>
                        {thisCategory?.length > 0 && (
                            <Checkbox
                                className="mr-16"
                                checked={isCategorySelected}
                                onChange={onSelectMessageTemplateCategory}
                            />
                        )}
                        <LinesEllipsis text={messageTemplateCategory.name}
                                       maxLine="1"
                                       ellipsis="..."
                                       className="card-title"
                                       onReflow={handleReflow}
                                       data-for="category-name"
                                       data-tip={messageTemplateCategory.name}
                                       data-tip-disable={!isCategoryNameClamped}
                        />
                        <ReactTooltip id="category-name"/>
                        <div className="ml-auto flex-row">
                            <AnimatedHider open={!isExpanded && isHover} className="mr-16">
                                {messageTemplateCategory.isPrivate ? (
                                    <IconLock className="text-color-3" size={18}/>
                                ) : (
                                    footerButtonType === PredefinedMessagesFormMode.View && (
                                        <IconPencil className="edit-title-icon"
                                                    size={18}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setEditTitleActive(true);
                                                    }}/>
                                    )
                                )}
                            </AnimatedHider>
                            <IconChevronDown size={20} className="card-expanded-indicator"/>
                        </div>
                    </div>
                    <AnimatePresence>
                        {isExpanded && (
                            <motion.div style={{ overflow: "hidden" }}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}>
                                {async && messageTemplateCategory.id === asyncMessageId ? (
                                    <Spinner/>
                                ) : (
                                    <>
                                        {thisCategory && thisCategory.map(messageTemplate => {
                                            return <MessageTemplate
                                                key={messageTemplate?.id}
                                                messageTemplate={messageTemplate}
                                                isSelected={selectedTemplates.includes(messageTemplate.id)}
                                                setAsyncMessageId={setAsyncMessageId}
                                            />;
                                        })}
                                        {addingNew &&
                                            <div className="message-template">
                                                <div className="template-textarea-media-uploader-container ml-34">
                                                    <TextareaField className="template-textarea-field mt-8 "
                                                                   onChange={event => setNewMessageTemplateText(event.target.value)}
                                                                   value={newMessageTemplateText}
                                                                   rows={3}
                                                                   autoFocus={true}/>
                                                    <MediaUploader addMediaText={t("add_attachment")}
                                                                   files={attachments}
                                                                   multiple={false}
                                                                   className="mt-10"
                                                                   dragFileHereText={t("add_files")}
                                                                   acceptAttachments={ACCEPT_ATTACHMENTS}
                                                                   onAddAttachment={(attachment) => {
                                                                       setNewMessageTemplateAttachment(attachment);

                                                                       addAttachment(attachment);
                                                                   }}
                                                                   onRemoveAttachment={(attachment) => {
                                                                       setNewMessageTemplateAttachment(null);

                                                                       removeAttachment(attachment);
                                                                   }}/>
                                                </div>

                                                <div className="template-icons-container">
                                                    <AnimatedHider open={true} className="flex-column">
                                                        {addAsync ? (
                                                            <Spinner small={true}/>
                                                        ) : (
                                                            <div className="icon-wrapper blue-icon"
                                                                 onClick={onAddNewMessageTemplate}>
                                                                <IconCheck size={18} className="text-color-3"/>
                                                            </div>
                                                        )}
                                                        <div className="icon-wrapper gray-icon"
                                                             onClick={onCancelAddingMessageTemplate}>
                                                            <IconX size={18} className="text-color-3"/>
                                                        </div>
                                                    </AnimatedHider>
                                                </div>
                                            </div>
                                        }
                                        <div className="add-a-message-btn flex-row align-center mt-28 text-btn"
                                             onClick={() => setAddingNew(true)}>
                                            <IconCirclePlus size={20}
                                                            className="text-color-3 mr-16"
                                            />
                                            <div className="text-color-4">{t("add_a_message")}</div>
                                        </div>
                                    </>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            )}
            <ReactTooltip place={"bottom"}/>
        </div>
    );
};

export default MessageTemplateCategory;
