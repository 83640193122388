import React, { MouseEventHandler } from "react";
import "./impersonation_button.scss";
import { IconChevronDown } from "@tabler/icons";
import classNames from "classnames";
import { useAppSelector } from "src/store/hooks";
import { UserUtils } from "src/entities/User/User";
import { loginSelectors } from "src/store/slices/loginSlice";
import { Utils } from "../../../../../utils/utils";

export interface ImpersonationButtonProps {
    readonly onClick?: MouseEventHandler<HTMLDivElement>,
    dropdownOpen?: boolean
}

const ImpersonationButton = ({ onClick, dropdownOpen = false }: ImpersonationButtonProps) => {

    const user = useAppSelector(loginSelectors.user);
    const impersonatedUser = useAppSelector(loginSelectors.impersonatedUserSelector);


    const userRole = user ? UserUtils.getUserRoleText(user) : "N/A";
    const impersonatedUserRole = impersonatedUser ? UserUtils.getUserRoleText(impersonatedUser) : "N/A";

    return (
        <div className="impersonation-button-container flex-row align-center" onClick={onClick}>
            {impersonatedUser && (
                <div className="mr-16">
                    <div className="role-text">{impersonatedUserRole}</div>
                    <div className="info">
                        {user && <div className="text-color-accent-blue">{Utils.mergeName(impersonatedUser)}</div>}
                    </div>
                </div>
            )}
            <div className={classNames({ "strikeout": impersonatedUser !== null })}>
                <div className="role-text">{userRole}</div>
                <div className="info">
                    {user && <div className="text-color-accent-blue">{Utils.mergeName(user)}</div>}
                    <IconChevronDown size={17} className={classNames("dropdown-indicator", { "open": dropdownOpen })}/>
                </div>
            </div>
        </div>
    );
};

export default ImpersonationButton;
