import React, { useEffect, useRef } from "react";
import "./lead_files.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/store/hooks";
import AsyncContainer from "src/components/Ui/AsyncContainer/AsyncContainer";
import MediaObjectCard
    from "src/pages/Communication/LeadPanel/components/LeadGallery/GalleryCard/MediaObjectCard";
import { MediaObject, MediaObjectUtils } from "src/entities/Mediaobject/MediaObject";
import { Waypoint } from "react-waypoint";
import Spinner from "src/components/Ui/Spinner/Spinner";

export interface LeadFilesProps {
    leadId: string;
}

// TODO THIS
const LeadFiles = ({ leadId }: LeadFilesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // const files = useAppSelector(state => state.messenger.files);
    const files: MediaObject[] = [];

    // const filesAsync = useAppSelector(state => state.messenger.fetchFilesAsync);
    // const fetchMoreFilesAsync = useAppSelector(state => state.messenger.fetchMoreFilesAsync);
    const filesAsync = false;
    const fetchMoreFilesAsync = false;

    const filesListRef = useRef<HTMLDivElement>(null);

    const loadingFiles = filesAsync || fetchMoreFilesAsync;

    useEffect(() => {
        if (filesListRef.current) {
            filesListRef.current.scrollTop = 0;
        }
        loadFiles();
        //eslint-disable-next-line
    }, [leadId]);

    const openFile = (file: MediaObject) => {
        window.open(MediaObjectUtils.getUrl(file), "_blank");
    };

    async function loadFiles() {
        if (loadingFiles) return;

        // TODO
        // await dispatch(messengerAsyncActions.fetchMediaObjectsPageForLead(leadId, MediaObjectType.Files));
    }

    return (
        <AsyncContainer async={filesAsync}>
            <div className="lead-files" ref={filesListRef}>
                {(files.length === 0 && !loadingFiles) ? (
                    <div className="flex-1 flex-center-both text-color-3">{t("no_files")}</div>
                ) : (
                    files.map(mediaObject => {
                        return <MediaObjectCard key={mediaObject.id}
                                                mediaObject={mediaObject}
                                                onClick={() => openFile(mediaObject)}/>;
                    })
                )}
                <Waypoint onEnter={loadFiles}>
                    <div className="files-waypoint">
                        {loadingFiles && <Spinner size={35}/>}
                    </div>
                </Waypoint>
            </div>
        </AsyncContainer>
    );
};

export default LeadFiles;
