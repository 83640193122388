import * as React from "react";
import classNames from "classnames";
import {
    CSSProperties,
    FunctionComponent,
    MouseEventHandler, ReactNode,
    useCallback,
    useState,
} from "react";
import "./input_field.scss";
import { TablerIconProps } from "@tabler/icons";
import { WithClassname } from "src/utils/type_utils";
import { useTranslation } from "react-i18next";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.ba";

export enum InputType {
    Text = "text",
    Password = "password",
    Number = "number",
    Date = "date",
    Phone = "phone",
    Hours = "hours",
    Minutes = "minutes",
}

export interface InputProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    placeholder?: string;
    name?: string,
    value?: string | ReadonlyArray<string> | number | undefined;
    type?: InputType;
    style?: CSSProperties;
    error?: string,
    min?: number,
    max?: number,
    showErrorText?: boolean,
    readonly?: boolean,
    icon?: FunctionComponent<TablerIconProps>,
    onIconClick?: MouseEventHandler,
    onBlur?: () => void,
    onClick?: () => void,
    renderInput?: (value: string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void) => ReactNode
}


const InputField = React.forwardRef<HTMLDivElement, WithClassname<InputProps>>(({
                                                                                    onChange,
                                                                                    label,
                                                                                    name,
                                                                                    placeholder,
                                                                                    value = "",
                                                                                    min,
                                                                                    max,
                                                                                    type = InputType.Text,
                                                                                    style = {},
                                                                                    error,
                                                                                    showErrorText = false,
                                                                                    readonly = false,
                                                                                    icon,
                                                                                    className,
                                                                                    onIconClick,
                                                                                    onBlur,
                                                                                    onClick,
                                                                                    renderInput,
                                                                                }, ref) => {
    const { t } = useTranslation();
    const Icon = icon;

    const [focused, setIsFocused] = useState(false);
    const toggleFocus = useCallback(() => setIsFocused(!focused), [focused]);

    const cleaveOptions = type === InputType.Phone ? {
        phone: true,
        phoneRegionCode: "ba",
    } : type === InputType.Hours ? {
        time: true,
        timePattern: ['h'],
    } : type === InputType.Minutes ? {
        time: true,
        timePattern: ['m'],
    } : null;

    return (
        <div ref={ref} className={classNames("input-container", className, { readonly, focused, error })} style={style}>
            {label && (
                <label className={classNames("input-label")}>
                    {label}
                    {showErrorText && (
                        <div className="input-error">{error}</div>
                    )}

                </label>
            )}

            <div className="inner">
                {cleaveOptions ? (
                    <Cleave
                        onChange={onChange}
                        min={min}
                        max={max}
                        name={name}
                        placeholder={placeholder ? placeholder : (readonly && (value === "UNKNOWN" || !value)) ? t("unknown") : value.toString()}
                        value={value}
                        className={"cleave-input"}
                        options={cleaveOptions}/>
                ) : (
                    <>
                        <input
                            readOnly={readonly}
                            onChange={onChange}
                            min={min}
                            max={max}
                            onClick={onClick}
                            onFocus={toggleFocus}
                            onBlur={() => {
                                toggleFocus();

                                if (onBlur) {
                                    onBlur();
                                }
                            }}
                            name={name}
                            placeholder={placeholder}
                            value={readonly && !value ? t("unknown") : value}
                            type={type}
                        />
                        {Icon && <div onClick={onIconClick} className="icon-container">
                            <Icon/>
                        </div>}
                    </>
                )}
            </div>
        </div>
    );
});

export default InputField;
