import { bs, enUS } from "date-fns/locale";
import { LeadViberInfo } from "../entities/Lead/Lead";
import { isEqual, isObject, transform } from "lodash";
import { SelectFieldOption } from "../components/Ui/SelectField/SelectField";
import { StorageKeys } from "./storage_keys";
import { User } from "../entities/User/User";
import { LoginApi } from "../api/login";

export let dateFnsLocale = bs;

export type ReadFile = { file: string, mimeType: string, name: string };
export type FullName = { firstName?: string | null, lastName?: string | null };
export type FullNameWithViber = FullName & { viber?: LeadViberInfo | null }

export function getName<T extends FullNameWithViber>(ent: T): string {
    let hasMainName = true;

    if (ent.firstName === "UNKNOWN") {
        hasMainName = false;
    }

    if (!ent.firstName && !ent.lastName) {
        hasMainName = false;
    }

    if (!hasMainName) {
        return ent.viber?.name ?? "NN";
    } else {
        return Utils.mergeName(ent);
    }
}

export function changeDateFnsLocaleBasedOnLang(lang: string) {
    switch (lang) {
        case "en":
            dateFnsLocale = enUS;
            break;
        case "bs":
            dateFnsLocale = bs;
            break;
    }
}

export function tryToParseImpersonationFromLocalStorage() {
    const value = localStorage.getItem(StorageKeys.Impersonate);

    if (value) {
        return JSON.parse(value);
    }

    return null;
}

export class Utils {

    public static capitalize(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    public static findSelectOption(options: SelectFieldOption[], value?: unknown): SelectFieldOption | null {
        return options.find(o => o.value === value) ?? null;
    }

    public static toSnakeCase(str: string): string {
        const a = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);

        if (a) {
            return a.map(x => x.toLowerCase()).join('_');
        }

        return "";
    }

    public static arrayIncludesArray(source: Array<unknown>, value: Array<unknown>): boolean {
        return value.every(element => source.includes(element));
    }

    public static keyToTitleCase(key?: string, keyWordsDelimiter = "_") {

        if (!key) {
            return "";
        }

        return this.capitalize(key.replaceAll(keyWordsDelimiter, " "));
    }

    public static parseJSON<T = Record<string, unknown>>(json: string): T | null {
        try {
            return JSON.parse(json);
        } catch (ex) {
            return null;
        }
    }

    public static mergeName({ firstName, lastName }: FullName): string {
        return [firstName, lastName].filter(v => typeof v === "string").join(" ");
    }

    public static getObjectDiff(object: Record<string, unknown>, base: Record<string, unknown>): Record<string, unknown> {

        function changes(object: Record<string, unknown>, base: Record<string, unknown>): Record<string, unknown> {
            return transform(object, function (result, value, key) {
                if (!isEqual(value, base[key])) {
                    result[key] = (isObject(value) && isObject(base[key])) ? changes(value as Record<string, unknown>, base[key] as Record<string, unknown>) : value;
                }
            });
        }

        return changes(object, base);
    }

    public static readFile(file: File): Promise<ReadFile> {
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = () => {
                if (fr.result) {
                    if (typeof fr.result === "string") {
                        resolve({ mimeType: file.type, file: fr.result, name: file.name });
                    }
                } else {
                    reject();
                }
            };
            fr.onerror = reject;
            fr.readAsDataURL(file);
        });
    }

    public static convertFullNameToFirstAndLast(val: string): FullName {
        const i = val.indexOf(" ");
        const split = i === -1 ? [val] : [val.slice(0, i), val.slice(i + 1)];

        let firstName = split[0];
        let lastName = split[1];

        if (!firstName && i !== -1) {
            firstName = "";
        }

        if (!lastName && i !== -1) {
            lastName = "";
        }

        return {
            firstName,
            lastName,
        };
    }

    public static immerCompatibleObjectAssign<T>(source: T, updater: Partial<T>) {
        for (const [key, value] of Object.entries(updater)) {
            (source as any)[key] = value;
        }
    }

    public static searchByName(fullName: FullName, searchVal: string): boolean {
        return Utils.mergeName(fullName).toLowerCase().includes(searchVal.toLowerCase());
    }

    public static deg2rad(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    public static rad2deg(radians: number) {
        return radians * (180 / Math.PI);
    }

    public static genArrayFromTo(from: number, to: number, step = 1, inclusive = false, skipFirst = false): Array<number> {
        let out = [];

        for (let i = from; inclusive ? (i <= to) : (i < to); i += step) {
            if (skipFirst && i === from) continue;

            out.push(i);
        }

        return out;
    }

    public static dataURItoBlob(dataURI: string): Blob {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = atob(dataURI.split(",")[1]);

        // separate out the mime component
        let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        //New Code
        return new Blob([ab], { type: mimeString });
    }

    public static getPaginationRange(current: number, last: number): number[] {
        let range = [];

        for (let i = current - 1; (i >= 1 && current - i < 3); i--) {
            range.push(i);
        }

        range.push(current);

        for (let i = current + 1; (i <= last && i - current < 3); i++) {
            range.push(i);
        }

        return range.sort();
    }
}
