import './messenger_header_actions.scss';
import { t } from "i18next";
import Dropdown, { DropdownAnchorEdge } from "../../../../../components/Ui/Dropdown/Dropdown";
import GIcon from "../../../../../components/Ui/GIcon/GIcon";
import DropdownItem from "../../../../../components/Ui/Dropdown/DropdownItem";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { leadsAsyncActions, leadsSelectors } from "../../../../../store/slices/leadSlice";
import { loginSelectors } from "../../../../../store/slices/loginSlice";
import AlertUtils from "../../../../../components/Ui/Alert/alert_utils";
import { useTranslation } from "react-i18next";

export interface MessengerHeaderActionsProps {
    leadId: string,
}

export function MessengerHeaderActions({ leadId, ...rest }: MessengerHeaderActionsProps) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isSuperAdmin = useAppSelector(loginSelectors.isGrantedSuperAdminLevel);

    const onDeleteCustomer = useCallback(async () => {
        const r = await AlertUtils.dangerAlert({
            title: t("warning"),
            message: t("warning_this_action_will_permanently_delete_the_customer_are_you_sure"),
            positiveButtonText: t("confirm"),
            neutralButtonText: t("cancel"),
        });

        if (r) {
            dispatch(leadsAsyncActions.dangerouslyDeleteLead(leadId));
        }
    }, [leadId]);

    return (
        <div className="messenger-header-actions">

            {isSuperAdmin && (
                <div className="flex-row align-center">
                    <Dropdown innerClassName="actions-dropdown"
                              anchorEdge={DropdownAnchorEdge.BottomRight}
                              anchor={
                                  <div>
                                      <GIcon icon={"more_vert"} className="icon-more"/>
                                  </div>
                              }>
                        <DropdownItem className="flex-row align-center"
                                      onClick={onDeleteCustomer}>
                            {t("customer_delete_action")}
                        </DropdownItem>
                    </Dropdown>
                </div>
            )}
        </div>
    );
}

export default MessengerHeaderActions;
