import * as React from 'react';

export interface Route {
    path: string,
    name: RouteName,
}

export enum RouteName {
    Communication = 'Communication',
    Login = 'Login',
    Topics = 'Topics',
    Users = 'Users',
    Reports = 'Reports',
    Questionnaires = 'Questionnaires',
    ViberKeyboards = 'ViberKeyboards'
}

export class Router {

    public static readonly RoutePaths = {
        Communication: '/',
        Login: '/login',
        Topics: '/topics',
        Users: '/users',
        Questionnaires: '/questionnaires',
        Reports: '/reports',
        ViberKeyboards: '/viber_keyboards'
    };

    public static readonly routes: Array<Route> = [
        { path: Router.RoutePaths.Login, name: RouteName.Login },
        { path: Router.RoutePaths.Communication, name: RouteName.Communication },
        { path: Router.RoutePaths.Topics, name: RouteName.Topics },
        { path: Router.RoutePaths.Users, name: RouteName.Users },
        { path: Router.RoutePaths.Reports, name: RouteName.Reports },
        { path: Router.RoutePaths.Questionnaires, name: RouteName.Questionnaires },
        { path: Router.RoutePaths.ViberKeyboards, name: RouteName.ViberKeyboards },
    ];

    public static readonly RouteBreadcrumbs: Partial<Record<RouteName, React.ComponentType>> = {};
}
