import { pageAnimationVariants } from "../../components/Ui/AnimatedHider/AnimatedHider";
import './reports.scss';
import { motion } from 'framer-motion';
import DateRangePickerInput from "../../components/Ui/DateRangePickerInput/DateRangePickerInput";
import { format, subDays } from "date-fns";
import { useCallback, useState } from "react";
import { DatePickerMode } from "../../components/Ui/DateRangePicker/DateRangePicker";
import { cloneDeep } from "lodash";
import Button, { ButtonStyle } from "../../components/Ui/Button/Button";
import { useTranslation } from "react-i18next";
import { API } from "../../api/api";
import { saveAs } from "file-saver";


export interface ReportsProps {

}

function Reports({}: ReportsProps) {
    const { t } = useTranslation();
    const [dates, setDates] = useState([subDays(new Date(), 7), new Date()]);

    const generateReport = useCallback(async () => {
        const from = format(dates[0], "MM/dd/yyyy");
        const to = format(dates[1], "MM/dd/yyyy");

        const r = await API.apiCall({
            url: `${API.reports}/generate_report?from=${from}&to=${to}`,
        }).then(r => r?.blob());

        if (r) {
            saveAs(r, `sdp-izvjestaj-${from}-${to}.xlsx`);
        }
    }, [dates]);

    return (
        <motion.div initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageAnimationVariants}
                    className="reports page flex-row">
            <div className="picker-button-container">
                <DateRangePickerInput dates={dates}
                                      onDateChange={(index, newDate) => {
                                          if (index === 0) {
                                              setDates([newDate, subDays(newDate, 1)]);
                                          } else {
                                              const clone = cloneDeep(dates);
                                              clone[index] = newDate;
                                              setDates(clone);
                                          }
                                      }}
                                      mode={DatePickerMode.Day}/>

                <Button buttonStyle={ButtonStyle.Blue}
                        className="generate-report-button"
                        onClick={generateReport}>
                    {t("generate_report")}
                </Button>
            </div>
        </motion.div>
    );
}

export default Reports;
