import React, { CSSProperties } from "react";
import "./textarea_field.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export interface TextareaFieldProps {
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    label?: string;
    placeholder?: string;
    name?: string,
    value?: string;
    style?: CSSProperties;
    error?: string,
    readonly?: boolean,
    rows?: number,
    autoFocus?: boolean,
    readonly className?: string
}

const TextareaField = React.forwardRef<HTMLDivElement, TextareaFieldProps>(({
                                                                                onChange,
                                                                                label,
                                                                                name,
                                                                                placeholder,
                                                                                value = "",
                                                                                style = {},
                                                                                error,
                                                                                readonly = false,
                                                                                rows = 3,
                                                                                className,
                                                                                autoFocus = false,
                                                                            }, ref) => {

    const {t} = useTranslation();

    return (
        <div ref={ref} className={classNames("textarea-field", className, { readonly })} style={style}>
            {label && (
                <label className={classNames("input-label")}>
                    {label}
                    <div className="input-error">{error}</div>
                </label>
            )}
            <textarea
                onChange={onChange}
                name={name}
                placeholder={placeholder}
                value={readonly && !value ? t("unknown") : value}
                rows={rows}
                autoFocus={autoFocus}
            />
        </div>
    );
});

export default TextareaField;
