import { AsyncAction, BaseEntityReducerState, createEntityReducer } from "../helpers/entityReducer";
import { RootState } from "../store";
import { createSelector, PayloadAction } from "@reduxjs/toolkit";
import { APIEntity } from "../../api/api";
import { Lead, LeadQuery } from "src/entities/Lead/Lead";

import { PayloadWithChannelEntityId, SocketActions } from "../helpers/socketActions";
import { Utils } from "../../utils/utils";
import { CustomerClaimedEvent } from "../../entities/socketEvents";
import { LeadApi } from "../../api/lead";
import { ILeadForm } from "../../entities/Lead/LeadForm";

interface LeadsState extends BaseEntityReducerState<Lead, LeadQuery> {

}

const initialState: LeadsState = {
    entities: {},
    perPage: 15,
    currentPage: 1,
    totalItems: 0,
    fetchAsync: false,
    lastPage: 1,
    hasMore: true,
    selectedEntities: [],
    addEditAsync: false,
    deleteAsync: false,
    query: { isArchived: false },
    searchFields: ["optStatus", "firstName", "lastName", "address"],
    // searchFields: ["firstName"]
};

function setHasUnreadMessages(communicationId: string, userId: string, hasUnreadMessages: boolean): AsyncAction {
    return async (dispatch) => {
        const ret = await LeadApi.setHasUnreadMessages(communicationId, hasUnreadMessages).catch(() => false);

        if (ret) {
            dispatch(leadsActions.setReadState({
                leadId: communicationId,
                isRead: !hasUnreadMessages,
                userId: userId,
            }));
        }
    };
}

function socketLeadClaimed(payload: PayloadWithChannelEntityId<CustomerClaimedEvent>): AsyncAction {
    return async (dispatch, getState) => {
        const { payload: { customer_id, user_id } } = payload;
        const state = getState();
        const query = state.leads.query;
        const user = state.login.user;

        // we are in new leads view
        if (query?.user === null) {
            // just remove here, it got claimed, we are in new leads
            // we don't care if we claimed it or someone else claimed it
            // it doesn't belong in this view
            dispatch(leadsSlice.actions.removeEntity(customer_id));
        } else {
            // we are in my leads view
            if (user && user.id === user_id) {
                // we claimed it, fetch it
                dispatch(asyncActions.fetchSingleEntity({ id: customer_id, shouldAsync: false }));
            }
        }
    };
}

function socketLeadCreated(payload: PayloadWithChannelEntityId<{ id: string }>): AsyncAction {
    return async (dispatch) => {
        const { payload: { id } } = payload;

        dispatch(leadsAsyncActions.fetchSingleEntity({ id, shouldAsync: false }));
    };
}

function dangerouslyDeleteLead(leadId: string): AsyncAction {
    return async (dispatch) => {
        const r = await LeadApi.dangerouslyDeleteLead(leadId).catch(() => null);

        if (r) {
            dispatch(leadsActions.removeEntity(leadId));
        }
    };
}

const slice = createEntityReducer({} as Lead, APIEntity.Leads, initialState, {
    resetState() {
        return initialState;
    },
    setReadState(state, {
        payload: {
            isRead,
            userId,
            leadId,
        },
    }: PayloadAction<{ leadId: string, userId: string, isRead: boolean }>) {
        const lead = state.entities[leadId];

        if (lead) {
            const state = lead.readStates.find(state => state.userId === userId);
            if (state) {
                state.isRead = isRead;
            } else {
                lead.readStates.push({ isRead, userId });
            }
        }
    },
}, builder => {
    builder
        .addCase(SocketActions.CompanyCustomerUnsubscribed, (state, { payload: { payload: { customerId } } }) => {
            delete state.entities[customerId];
        })
        .addCase(SocketActions.AgentReadStateUpdated, (state, {
            payload: {
                payload: { isRead, customerId },
                channelEntityId,
            },
        }) => {
            const lead = state.entities[customerId];

            if (lead) {
                const state = lead.readStates.find(state => state.userId === channelEntityId);
                if (state) {
                    state.isRead = isRead;
                } else {
                    lead.readStates.push({ isRead, userId: channelEntityId });
                }
            }
        })
        .addCase(SocketActions.CompanyCustomerUpdated,
            (state, { payload: { payload: lead } }) => {
                if (state.entities[lead.id]) {
                    Utils.immerCompatibleObjectAssign(state.entities[lead.id], lead);
                }
            })
        .addCase(SocketActions.CompanyBumpCustomerConversation, (state, { payload: { payload: { id, bumpedAt } } }) => {
            if (state.entities[id]) {
                state.entities[id].bumpedAt = bumpedAt;
            }
        });
}, {} as ILeadForm, {} as LeadQuery, { appendMode: true });

export const {
    slice: leadsSlice,
    asyncActions,
} = slice;

const leadsListSelector = (state: RootState) => state.leads.entities;

export const leadsList = createSelector([leadsListSelector], (entities) => {
    return Object.values(entities).sort((a, b) => new Date(b.bumpedAt).getTime() - new Date(a.bumpedAt).getTime());
});

function selectedLead(state: RootState): Lead | null {
    return state.leads.selectedEntities.length === 1 ? (state.leads.entities[state.leads.selectedEntities[0]] ?? null) : null;
}

export const leadsSelectors = {
    leadsList,
    selectedLead,
};

export const leadsAsyncActions = {
    ...asyncActions,
    setHasUnreadMessages,
    socketLeadCreated,
    socketLeadClaimed,
    dangerouslyDeleteLead,
};

export const leadsActions = leadsSlice.actions;
export const leadsReducer = leadsSlice.reducer;
