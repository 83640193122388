import { Lead, LeadUtils, OptStatus } from "src/entities/Lead/Lead";
import React, { CSSProperties } from "react";
import classNames from "classnames";
import CircularInitials from "../../components/Ui/CircularInitials/CircularInitials";
import { IconAlertCircle, IconCircleCheck, IconStar } from "@tabler/icons";
import { dateFnsLocale, Utils } from "src/utils/utils";
import { formatDistance } from "date-fns";
import "./lead_list_item.scss";
import { MessageUtils } from "src/entities/message/Message";
import { useAppSelector } from "../../store/hooks";
import { loginSelectors } from "../../store/slices/loginSlice";
import { UserUtils } from "../../entities/User/User";

export interface LeadListItemProps {
    lead: Lead;
    style?: CSSProperties,
    className?: string,
    onRowClick: (id: string) => void,
    onMultiselect?: (id: string) => void,
    isSelected: boolean,
    multiSelectOverride?: boolean
}

function LeadListItem({
                          className,
                          onMultiselect,
                          onRowClick,
                          isSelected,
                          style,
                          lead,
                          multiSelectOverride,
                      }: LeadListItemProps) {
    const VerifiedStatusIcon = lead.isVerified ?
        <IconCircleCheck className="status-icon"/> :
        <IconAlertCircle className="status-icon not-verified"/>;

    const user = useAppSelector(loginSelectors.user);

    const isSuperAdmin = user !== null ? UserUtils.isSuperAdmin(user) : false;
    const isAdmin = user !== null ? UserUtils.isAdmin(user) : false;

    const channel = LeadUtils.getChannel({
        viberId: lead.viber.id,
        smsNumber: lead.sms.number,
    });
    const IconChannel = MessageUtils.getChannelIcon(channel);

    const hasMainName = LeadUtils.hasMainName(lead);
    const isUnread = !LeadUtils.isRead(lead, user?.id);
    const lastMessageCreatedAt = lead.bumpedAt;

    return (
        <div className={classNames(className, "lead-list-item", {
            selected: isSelected,
            'multi': multiSelectOverride,
            'opt-in': lead.optStatus === OptStatus.IN,
            'opt-out': lead.optStatus === OptStatus.OUT,
            'opt-unknown': lead.optStatus === OptStatus.UNKNOWN,
        })}
             onClick={() => onRowClick(lead.id)}
             style={style}>
            <div className="flex-row flex-1 flex-between">
                <div className="flex-row">
                    <div className="initials-container">
                        <CircularInitials isSelected={isSelected && multiSelectOverride}
                                          onClick={(e) => {
                                              // e.stopPropagation();
                                              e.preventDefault();
                                              if (onMultiselect) {
                                                  onMultiselect(lead.id);
                                              }
                                          }}
                                          entity={lead}/>
                    </div>

                    <div className="flex-row align-center">
                        <div className="lead-status flex-row">
                            <div className="flex-column">
                                <IconChannel className="status-icon"/>
                                {/*{VerifiedStatusIcon}*/}
                            </div>
                        </div>
                        <div>
                            <div className={classNames("text-16 text-color-1", {
                                "text-italic": !hasMainName,
                                "text-medium": isUnread,
                            })}>
                                {hasMainName ? (
                                    Utils.mergeName(lead)
                                ) : (
                                    LeadUtils.getDerivedName(lead)
                                )}
                            </div>
                            <div className={classNames("text-14 text-color-3", {
                                "text-medium": isUnread,
                                "text-color-accent-blue": isUnread,
                            })}>
                                {lastMessageCreatedAt && formatDistance(new Date(lastMessageCreatedAt), new Date(), {
                                    locale: dateFnsLocale,
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(isSuperAdmin || isAdmin) && (
                <div>
                    {lead.topic.name}
                </div>
            )}
        </div>
    );
}

export default LeadListItem;
