import { ReadFile } from "../../../../../utils/utils";
import { IconX } from "@tabler/icons";
import ColorfulIconButton, {
    ColorfulIconButtonVariant,
} from "../../../../../components/Ui/ColorfulIconButton/ColorfulIconButton";
import { AnimatePresence } from "framer-motion";
import useHover from "../../../../../utils/hooks/useHover";
import { useCallback, useRef } from "react";
import { motion } from "framer-motion";
import { useAppDispatch } from "../../../../../store/hooks";
import { messengerActions } from "../../../../../store/slices/messengerSlice";
import { MediaObjectUtils } from "../../../../../entities/Mediaobject/MediaObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

export interface MessageBarAttachmentProps {
    attachment: ReadFile,
    index: number,
    leadId: string,
}

function MessageBarAttachment({ index, leadId, attachment, ...rest }: MessageBarAttachmentProps) {
    const attachmentRef = useRef<HTMLDivElement>(null);
    const hover = useHover(attachmentRef);
    const dispatch = useAppDispatch();

    const removeAttachment = useCallback(() => {
        dispatch(messengerActions.removeAttachment({ index, id: leadId }));
    }, [dispatch, index]);

    return (
        <div className="message-bar-attachment" data-tip={attachment.name}>
            <div className="attachment" ref={attachmentRef}>
                <AnimatePresence>
                    {hover && (
                        <motion.div initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                            <ColorfulIconButton variant={ColorfulIconButtonVariant.RED}
                                                onClick={removeAttachment}
                                                className="remove-attachment-btn">
                                <IconX/>
                            </ColorfulIconButton>
                        </motion.div>
                    )}
                </AnimatePresence>

                {MediaObjectUtils.isFile(attachment) ? (
                    <div className="file-attachment"
                         key={attachment.name}>
                        <FontAwesomeIcon
                            icon={MediaObjectUtils.getFileIconName(attachment)}/>
                        <span
                            className="filename">{attachment.name}</span>
                    </div>
                ) : (
                    <img src={attachment.file}
                         alt="attachment"/>
                )}

                <div className="image-tint"/>
                <ReactTooltip/>
            </div>
        </div>
    );
}

export default MessageBarAttachment;
