import DropdownItem from "../../../../../../components/Ui/Dropdown/DropdownItem";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useRef } from "react";
import { useAppDispatch } from "../../../../../../store/hooks";
import { messengerActions } from "../../../../../../store/slices/messengerSlice";
import { Utils } from "../../../../../../utils/utils";
import { ACCEPT_ATTACHMENTS, ACCEPT_ATTACHMENTS_STR } from "../../../../../../utils/consts";

export interface MessengerAttachmentActionProps {
    leadId: string,
}

function MessengerAttachmentAction({ leadId }: MessengerAttachmentActionProps) {
    const { t } = useTranslation();
    const attachmentInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const onAddAttachmentClick = useCallback((e) => {
        const attRef = attachmentInputRef.current;
        if (attRef) {
            attRef.click();
        }
    }, []);

    const onPickAttachment = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const attachment = await Utils.readFile(file);
                dispatch(messengerActions.addAttachment({ file: attachment, id: leadId }));
            }
        }

        e.target.value = "";
    }, [dispatch, leadId]);

    return (
        <>
            <DropdownItem onClick={onAddAttachmentClick}>
                {t("add_attachment")}
            </DropdownItem>

            <input type="file"
                   onChange={onPickAttachment}
                   style={{ display: "none" }}
                   multiple={true}
                   accept={ACCEPT_ATTACHMENTS_STR}
                   ref={attachmentInputRef}/>
        </>
    );
}

export default MessengerAttachmentAction;
