import { API, ApiMethod } from "src/api/api";
import { Lead } from "../entities/Lead/Lead";

export class LeadApi {
    static setHasUnreadMessages = async (leadId: String, hasUnreadMessages: boolean): Promise<void | null> => {
        let url = new URL(`${API.leads}/${leadId}/${hasUnreadMessages ? "unread" : "read"}`);

        return await API.jsonApiCall({
            url: url.toString(),
            method: ApiMethod.Put,
            jsonData: {},
        });
    };

    static fetchSingleEntity = async (leadId: string): Promise<Lead | null> => {
        return await API.jsonApiCall({
            url: `${API.leads}/${leadId}`,
        });
    };

    static dangerouslyDeleteLead = async (leadId: string): Promise<Lead | null> => {
        return await API.jsonApiCall({
            url: `${API.super}/leads/${leadId}`,
            method: ApiMethod.Delete,
            jsonData: {},
        });
    };

    static archiveLead = async (leadId: string): Promise<Lead | null> => {
        return await API.jsonApiCall({
            url: `${API.leads}/${leadId}/archive`,
            method: ApiMethod.Put,
            jsonData: {},
        });
    };

    static unarchiveLead = async (leadId: string): Promise<Lead | null> => {
        return await API.jsonApiCall({
            url: `${API.leads}/${leadId}/unarchive`,
            method: ApiMethod.Put,
            jsonData: {},
        });
    };
}
