import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import "./predefined_messages.scss";
import { IconSearch, IconTrash } from "@tabler/icons";
import Button, { ButtonStyle } from "src/components/Ui/Button/Button";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
    messageTemplateCategoriesAsyncActions,
    messageTemplateCategoriesSelectors,
} from "src/store/slices/messageTemplateCategoriesSlice";
import AnimatedHider, { AnimatedHiderVariant } from "src/components/Ui/AnimatedHider/AnimatedHider";
import InputField from "src/components/Ui/InputField/InputField";
import ColorfulIconButton, { ColorfulIconButtonVariant } from "src/components/Ui/ColorfulIconButton/ColorfulIconButton";
import {
    messageTemplatesActions,
    messageTemplatesAsyncActions,
    messageTemplatesSelectors,
} from "src/store/slices/messageTemplatesSlice";
import Spinner from "src/components/Ui/Spinner/Spinner";
import { messengerActions } from "src/store/slices/messengerSlice";
import SearchInput from "src/components/Ui/SearchInput/SearchInput";
import { MessageTemplateCategoryUtils } from "src/entities/messageTemplateCategory/IMessageTemplateCategory";
import MessageTemplateCategory from "./PredefinedMessageCard/MessageTemplateCategory";
import { MediaObjectUtils } from "../../../../entities/Mediaobject/MediaObject";

export interface PredefinedMessagesProps {
    onClose: () => void;
    leadId: string;
}

export enum PredefinedMessagesFormMode {
    Add,
    Edit,
    Select,
    View
}

const PredefinedMessages = ({ leadId, onClose }: PredefinedMessagesProps) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const messageTemplateCategories = useAppSelector(messageTemplateCategoriesSelectors.messageTemplateCategoriesList);

    const selectedMessageTemplatesList = useAppSelector(messageTemplatesSelectors.selectedMessageTemplatesList);

    const async = useAppSelector(state => {
        return state.messageTemplates.fetchAsync || state.messageTemplateCategories.fetchAsync;
    });

    const selectedTemplates = useAppSelector(messageTemplatesSelectors.selectedTemplates);

    const [newCategoryName, setNewCategoryName] = useState("");
    const [predefinedMessagesFormMode, setPredefinedMessagesFormMode] = useState<PredefinedMessagesFormMode>(PredefinedMessagesFormMode.View);
    const [searchActive, setSearchActive] = useState(false);
    const [messageTemplateCategoriesSearch, setMessageTemplateCategoriesSearch] = useState("");
    const searchedMessageTemplateCategories = useMemo(() => MessageTemplateCategoryUtils.searchMessageTemplateCategory(messageTemplateCategories ?? [], messageTemplateCategoriesSearch), [messageTemplateCategories, messageTemplateCategoriesSearch]);


    const onClear = useCallback(() => setMessageTemplateCategoriesSearch(""), []);

    const cancelSendingPredefinedMessages = () => {
        dispatch(messageTemplatesActions.unselectAll());
    };

    const onSendPredefinedMessages = useCallback(async () => {
        console.log(selectedMessageTemplatesList);
        const templateText = selectedMessageTemplatesList.map(template => template.text).join(" ");
        let attachments = selectedMessageTemplatesList
            .filter(template => template.attachment)
            .map(template => MediaObjectUtils.toReadFile(template.attachment!));

        let atts = [];

        for (const attachment of await Promise.all(attachments)) {
            if (attachment) {
                atts.push(attachment);
            }
        }

        dispatch(messengerActions.appendMessageText({ text: templateText, id: leadId }));
        dispatch(messengerActions.setAttachments({ files: atts, id: leadId }));
        dispatch(messageTemplatesActions.unselectAll());
    }, [selectedMessageTemplatesList, leadId, dispatch]);

    const onDeleteSelectedMessages = useCallback(() => {
        selectedTemplates.forEach(template => dispatch(messageTemplatesAsyncActions.deleteEntity(template)));
        dispatch(messageTemplatesActions.unselectAll());
    }, [dispatch, selectedTemplates]);


    const cancelAddingNew = useCallback(() => {
        setNewCategoryName("");
        setPredefinedMessagesFormMode(PredefinedMessagesFormMode.View);
    }, []);

    const onAddNewCategory = useCallback(async () => {
        await dispatch(messageTemplateCategoriesAsyncActions.createEntity({ name: newCategoryName }));
        cancelAddingNew();
    }, [dispatch, newCategoryName, cancelAddingNew]);

    useEffect(() => {
        dispatch(messageTemplateCategoriesAsyncActions.fetchAllEntities());
        dispatch(messageTemplatesAsyncActions.fetchAllEntities());
    }, [dispatch]);

    const numberOfSelectedMessageTemplates = selectedTemplates.length;
    const selectActive = numberOfSelectedMessageTemplates !== 0;

    const isAddMode = predefinedMessagesFormMode === PredefinedMessagesFormMode.Add;

    return (
        <div className={classNames("predefined-messages")}>
            <div className={classNames("header", { "select-active": selectActive })}>
                {selectActive ? (
                    <>
                        <div className="">{t("selected")}: {numberOfSelectedMessageTemplates}</div>
                        <AnimatedHider open={selectActive} className="ml-auto">
                            <ColorfulIconButton
                                variant={ColorfulIconButtonVariant.WHITE}
                                onClick={onDeleteSelectedMessages}
                            >
                                <IconTrash size={18}/>
                            </ColorfulIconButton>
                        </AnimatedHider>
                    </>
                ) : (
                    <>
                        {!searchActive ? (
                            <>
                                <span className="header-title">{t("predefined_messages")}</span>
                                <IconSearch className="search-icon" onClick={() => setSearchActive(true)}/>
                            </>
                        ) : (
                            <SearchInput search={messageTemplateCategoriesSearch}
                                         className={classNames("in-header")}
                                         onSearch={setMessageTemplateCategoriesSearch}
                                         onClear={onClear}
                                         fullBorder={true}/>
                        )}
                    </>
                )}
            </div>
            {async ? (
                <Spinner/>
            ) : (
                <>
                    <div className="predefined-messages-body">
                        <AnimatedHider open={isAddMode}
                                       collapseHasBottomMargin={false}
                                       variant={AnimatedHiderVariant.Collapse}>
                            <InputField onChange={e => setNewCategoryName(e.target.value)}
                                        value={newCategoryName}
                                        className="w-100p mb-16 w-100p"/>
                        </AnimatedHider>

                        {(!searchedMessageTemplateCategories.length && !isAddMode) &&
                            <div className="flex-row justify-center text-color-3 text-18">
                                {t("no_results_found")}.
                            </div>}

                        {searchedMessageTemplateCategories.map(messageTemplateCategory => {
                                return <MessageTemplateCategory key={messageTemplateCategory.id}
                                                                messageTemplateCategory={messageTemplateCategory}
                                                                footerButtonType={predefinedMessagesFormMode}
                                                                searchActive={messageTemplateCategoriesSearch.length !== 0}
                                />;
                            },
                        )}
                    </div>
                </>
            )}
            <div className="footer">
                {isAddMode ?
                    (<>
                            <Button className="text-uppercase footer-button"
                                    buttonStyle={ButtonStyle.Text}
                                    onClick={() => cancelAddingNew()}>
                                {t("cancel")}
                            </Button>
                            <Button className="text-uppercase footer-button"
                                    buttonStyle={ButtonStyle.Blue}
                                    onClick={() => onAddNewCategory()}>
                                {t("save")}
                            </Button>
                        </>
                    ) : (
                        !selectActive ? (
                            <>
                                <Button buttonStyle={ButtonStyle.Text}
                                        className="footer-button text-uppercase mr-auto"
                                        onClick={onClose}>
                                    {t("close")}
                                </Button>
                                <Button className="text-uppercase footer-button"
                                        buttonStyle={ButtonStyle.Blue}
                                        onClick={() => setPredefinedMessagesFormMode(PredefinedMessagesFormMode.Add)}>
                                    + {t("add")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button className="text-uppercase footer-button"
                                        buttonStyle={ButtonStyle.Text}
                                        onClick={cancelSendingPredefinedMessages}>
                                    {t("cancel")}
                                </Button>
                                <Button className="text-uppercase footer-button"
                                        buttonStyle={ButtonStyle.Blue}
                                        onClick={onSendPredefinedMessages}>
                                    {t("add")}
                                </Button>
                            </>
                        )
                    )}
            </div>
        </div>
    );
};

export default PredefinedMessages;
