import { cloneDeep, merge, pick } from "lodash";
import i18n from "../../i18n/config";
import { Topic } from "./Topic";

export interface ITopicForm {
    name?: string,
    ocsViberToken?: string,
    ocsSmsNumber?: string,
    isWelcomeQuestionnaireEnabled?: boolean,
    welcomeQuestionnaire?: string | null,
    onConversationStartedMessage?: string | null,
    onConversationStartedButtonText?: string | null,
    ocsViberBotPicture?: string,
    autoreminderMessage?: string,
}

export type ITopicFormErrors = Partial<Record<keyof ITopicForm, string>>

export class TopicFormUtils {
    static validate(form: ITopicForm): ITopicFormErrors {
        const errors: ITopicFormErrors = {};

        return errors;
    }

    static prepareForm(form: ITopicForm): ITopicForm {
        return form;
    }

    static updateForm(hcForm: ITopicForm, data: ITopicForm) {
        return merge(cloneDeep(hcForm), data);
    }

    static createForm(topic?: Topic): ITopicForm {
        if (!topic) {         // empty form
            return {
                name: "",
                ocsSmsNumber: "",
                ocsViberToken: "",
                isWelcomeQuestionnaireEnabled: true,
            };
        }

        const keys: Array<Exclude<keyof ITopicForm, "welcomeQuestionnaire">> = ["autoreminderMessage", "onConversationStartedButtonText", "onConversationStartedMessage", "ocsViberBotPicture", "isWelcomeQuestionnaireEnabled", "name", "ocsSmsNumber", "ocsViberToken"];

        return {
            welcomeQuestionnaire: topic.welcomeQuestionnaire?.id,
            ...pick(topic, keys) as ITopicForm,
        };
    }

}
