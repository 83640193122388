import classNames from "classnames";
import * as React from "react";
import "src/components/Ui/Checkbox/checkbox.scss";
import { CSSProperties } from "react";

export enum CheckboxType {
    BLUE = "blue",
}

export interface CheckboxProps {
    type?: CheckboxType,
    style?: CSSProperties,
    className?: string,
    checked: boolean,
    onChange?: (check: boolean) => void,
    label?: string
}

const Checkbox = ({
                      type = CheckboxType.BLUE,
                      className,
                      style,
                      checked,
                      label,
                      onChange = () => {
                      }
                  }: CheckboxProps) => {
    return (
        <div className={classNames("mpdz checkbox-container", className, `type-${type}`)}
             style={style}>
            <input type="checkbox"
                   checked={checked}
                   onChange={() => onChange(!checked)}/>

            <div className="checkmark" onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onChange(!checked);
            }}/>

            {label && <div className="checkbox-label">{label}</div>}
        </div>
    );
};

export default Checkbox;
