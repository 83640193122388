import "./alert.scss";
import classNames from "classnames";
import Button, { ButtonStyle } from "../Button/Button";
import { useCallback, useEffect, useState } from "react";

export enum AlertButtonsType {
    NegativePositive = "negative-positive",
    NeutralPositive = "neutral-positive",
    Positive = "positive",
}

export enum AlertPopupSize {
    Small = "small",
}

export enum AlertVariant {
    RED = "red",
    BLUE = "blue",
    ORANGE = "orange"
}

export interface AlertProps {
    title: string,
    message: string,
    variant: AlertVariant,
    size?: AlertPopupSize,
    buttonsType?: AlertButtonsType,
    dismissible?: boolean,
    onResult: (success: boolean) => void,
    neutralButtonText?: string,
    positiveButtonText?: string,
    negativeButtonText?: string,
}

const Alert = ({
                   title,
                   message,
                   variant = AlertVariant.BLUE,
                   size = AlertPopupSize.Small,
                   buttonsType = AlertButtonsType.NeutralPositive,
                   dismissible = true,
                   onResult,
                   neutralButtonText,
                   negativeButtonText,
                   positiveButtonText,
               }: AlertProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

    const onButtonClick = useCallback((success) => {
        setVisible(false);
        onResult(success);
    }, [setVisible, onResult]);

    const renderFooterButtons = () => {
        let highlightedButtonStyle = ButtonStyle.Text;

        switch (variant) {
            case AlertVariant.RED:
                highlightedButtonStyle = ButtonStyle.Red;
                break;
            case AlertVariant.BLUE:
                highlightedButtonStyle = ButtonStyle.Blue;
                break;
            case AlertVariant.ORANGE:
                highlightedButtonStyle = ButtonStyle.Orange;
                break;
        }

        switch (buttonsType) {
            case AlertButtonsType.NeutralPositive:
                return (
                    <>
                        <Button buttonStyle={ButtonStyle.Text}
                                onClick={() => onButtonClick(false)}>
                            <span
                                className="text-medium text-14 text-uppercase text-color-3">{neutralButtonText ?? ""}</span>
                        </Button>
                        <Button buttonStyle={highlightedButtonStyle}
                                onClick={() => onButtonClick(true)}>
                            <span className="text-medium text-14 text-uppercase">{positiveButtonText ?? ""}</span>
                        </Button>
                    </>
                );
            case AlertButtonsType.NegativePositive:
                return (
                    <>
                        <Button buttonStyle={ButtonStyle.Red}
                                onClick={() => onButtonClick(false)}>
                            <span
                                className="text-medium text-14 text-uppercase text-color-3">{negativeButtonText ?? ""}</span>
                        </Button>
                        <Button buttonStyle={highlightedButtonStyle}
                                onClick={() => onButtonClick(true)}>
                            <span className="text-medium text-14 text-uppercase">{positiveButtonText ?? ""}</span>
                        </Button>
                    </>
                );
            case AlertButtonsType.Positive:
                return (
                    <>
                        <Button onClick={() => onButtonClick(true)}
                                buttonStyle={highlightedButtonStyle}>
                            <span className="text-medium text-14 text-uppercase">{positiveButtonText ?? ""}</span>
                        </Button>
                    </>
                );
        }
    };

    const onBackdropClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (dismissible) {
            setVisible(false);
            onResult(false);
        }
    }, [dismissible, onResult]);

    const onAlertBodyClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return (
        <div className={classNames("alert-backdrop", { open: visible })} onClick={onBackdropClick}>
            <div onClick={onAlertBodyClick} className={classNames("alert", `variant-${variant}`, `size-${size}`)}>
                <div className="header">
                    <div className="title text-regular text-18">{title}</div>
                </div>
                <div className="body">
                    <div className="text-regular text-16">{message}</div>
                </div>
                <div className="footer">
                    {renderFooterButtons()}
                </div>
            </div>
        </div>
    );
};

export default Alert;
