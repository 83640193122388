import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import "./date_range_picker_input.scss";
import {
    IconChevronLeft,
    IconChevronRight,
    TablerIconProps,
} from "@tabler/icons";
import DateRangePicker, {
    DatePickerMode,
} from "src/components/Ui/DateRangePicker/DateRangePicker";
import {
    addDays,
    addMonths,
    addWeeks,
    subDays,
    subMonths,
    subWeeks,
} from "date-fns";
import classNames from "classnames";
import { WithClassname } from "src/utils/type_utils";
import { formatLocale } from "src/utils/date_fns_format_locale";
import { DateTime } from 'luxon';

interface DateRangePickerInputProps {
    dates: Date[];
    onDateChange: (index: number, date: Date) => void;
    onSelectPrevious?: (date: Date) => void;
    onSelectNext?: (date: Date) => void;
    mode?: DatePickerMode;
    noPicker?: boolean;
    outlined?: boolean;
    readonly?: boolean;
    icon?: FunctionComponent<TablerIconProps>;
    customFormat?: string;
    label?: string;
}

function DateRangePickerInput({
                                  mode = DatePickerMode.Day,
                                  dates,
                                  onDateChange,
                                  onSelectPrevious,
                                  onSelectNext,
                                  className,
                                  readonly = false,
                                  noPicker = false,
                                  outlined = false,
                                  icon,
                                  customFormat,
                                  label,
                              }: WithClassname<DateRangePickerInputProps>) {
    const [open, setOpen] = useState(false);
    const dateRangePickerAnchorRef = useRef<HTMLDivElement>(null);

    const showArrows = onSelectPrevious && onSelectNext;
    const Icon = icon;

    const onPrevious = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const corr0Date = DateTime.now().setZone("utc")
                .set({
                    day: dates[0].getDate(),
                    month: dates[0].getMonth() + 1,
                    year: dates[0].getFullYear(),
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                }).toJSDate();

            if (onSelectPrevious) {
                if (mode === DatePickerMode.Month) {
                    onSelectPrevious(subMonths(corr0Date, 1));
                } else if (mode === DatePickerMode.Week) {
                    onSelectPrevious(subWeeks(corr0Date, 1));
                } else if (mode === DatePickerMode.Day) {
                    onSelectPrevious(subDays(corr0Date, 1));
                }
            }
        },
        [dates, mode, onSelectPrevious],
    );

    const onNext = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const corr0Date = DateTime.now().setZone("utc")
                .set({
                    day: dates[0].getDate(),
                    month: dates[0].getMonth() + 1,
                    year: dates[0].getFullYear(),
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                }).toJSDate();

            if (onSelectNext) {
                if (mode === DatePickerMode.Month) {
                    onSelectNext(addMonths(corr0Date, 1));
                } else if (mode === DatePickerMode.Week) {
                    onSelectNext(addWeeks(corr0Date, 1));
                } else if (mode === DatePickerMode.Day) {
                    onSelectNext(addDays(corr0Date, 1));
                }
            }
        },
        [dates, mode, onSelectNext],
    );

    const renderPickerValue = () => {
        if (mode === DatePickerMode.Month) {
            return formatLocale(dates[0], customFormat ?? "MMMM, yyyy");
        } else if (mode === DatePickerMode.Week) {
            return `${formatLocale(
                dates[0],
                customFormat ?? "dd.MM.",
            )} - ${formatLocale(dates[1], customFormat ?? "dd.MM.")}`;
        } else if (mode === DatePickerMode.Day) {
            let val = formatLocale(dates[0], customFormat ?? "dd.MM.yyyy");
            if (dates[1]) {
                val += ` - ${formatLocale(
                    dates[1],
                    customFormat ?? "dd.MM.yyyy",
                )}`;
            }
            return val;
        }

        return "";
    };

    const DateRangePickerStandaloneContent = (
        <div
            onClick={() => setOpen(!open)}
            className={classNames("flex-row picker-input-content", {
                "w-100p": noPicker,
            })}
        >
            {showArrows && <IconChevronLeft onClick={onPrevious}/>}

            <div className="flex-row">
                <div
                    ref={dateRangePickerAnchorRef}
                    className={classNames("picker-value", "w-100p")}
                >
                    {renderPickerValue()}
                </div>
            </div>

            {showArrows && <IconChevronRight onClick={onNext}/>}

            {Icon && (
                <div className="icon-container">
                    <Icon/>
                </div>
            )}
        </div>
    );

    if (readonly) {
        return (
            <div className={classNames(className)}>
                {label && (
                    <label className={classNames("input-label")}>
                        {label}
                    </label>
                )}
                <div className={classNames("date-range-picker-readonly")}>
                    {renderPickerValue()}
                </div>
            </div>
        );
    }

    return (
        <div className={classNames("date-range-picker-container", className)}>
            {label && (
                <label className={classNames("input-label")}>
                    {label}
                </label>
            )}
            <div
                className={classNames("date-range-picker-input", {
                    open: open && outlined,
                    outlined,
                })}
            >
                {noPicker ? (
                    DateRangePickerStandaloneContent
                ) : (
                    <>
                        {DateRangePickerStandaloneContent}
                        <DateRangePicker
                            date={dates}
                            isOpen={open}
                            onOpenChange={setOpen}
                            anchorRef={dateRangePickerAnchorRef}
                            mode={mode}
                            onDateChange={onDateChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default DateRangePickerInput;
