import React, { FormEvent, useCallback, useState } from "react";
import "./login.scss";
import InputField, { InputType } from "../../components/Ui/InputField/InputField";
import Button, { ButtonType } from "../../components/Ui/Button/Button";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { loginActions, loginAsyncActions, LoginErrors } from "src/store/slices/loginSlice";
import { Router } from "src/router/router";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Login() {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayForgotPasswordSubmitForm, setDisplayForgotPasswordSubmitForm] = useState(false);
    const [displayForgotPasswordRequestForm, setDisplayForgotPasswordRequestForm] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const loginAsync = useAppSelector((state) => state.login.loginAsync);
    const loginErrors = useAppSelector((state) => state.login.loginErrors);

    const validateForm = useCallback((): boolean => {
        const errors: LoginErrors = {};

        if (!email?.length) {
            errors.email = t("enter_your_email") + ".";
        }

        if (!password?.length) {
            errors.password = t("enter_your_password") + ".";
        }

        dispatch(loginActions.setLoginErrors(errors));

        return Object.keys(errors).length === 0;
    }, [dispatch, email, password, t]);

    const onSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        const success = await dispatch(loginAsyncActions.performLogin(email, password));

        if (success) {
            navigate(Router.RoutePaths.Communication, {
                replace: true,
            });
        }
    }, [navigate, email, password, dispatch, validateForm]);

    const onClickForgotPassword = () => {
        setDisplayForgotPasswordRequestForm(true);
    };

    const onClickSendEmail = () => {
        setDisplayForgotPasswordSubmitForm(true);
    };

    const onClickSendPasswordAgain = () => {

    };

    const renderForgotPasswordRequestForm = () => {
        return (
            <>
                <div className="title">{t("enter_your_email")}</div>
                <InputField onChange={e => setEmail(e.target.value)}
                            label={"Email:"}
                            error={loginErrors.email}
                            value={email}
                            name={"email"}/>
                <Button className="login-btn" loading={loginAsync} onClick={onClickSendEmail} disabled={!email}>
                    {t("send")}
                </Button>
            </>
        );
    };


    const renderForgotPasswordSubmitForm = () => {
        return (
            <>
                <div className="title">Nova šifra je poslana na broj telefona +387 62 345 ***</div>
                <InputField onChange={e => setPassword(e.target.value)}
                            label={t("password")}
                            error={loginErrors.password}
                            type={InputType.Password}
                            value={password}
                            name={"password"}/>
                <span className="forgot-pass-btn text-btn"
                      onClick={onClickSendPasswordAgain}>{t("send_again")}</span>
                <Button className="login-btn" type={ButtonType.Submit} loading={loginAsync}>
                    {t("sign_in")}
                </Button>
            </>
        );
    };

    return (
        <div className="login page">
            <div className="outer-container">
                <form className="inner-container" onSubmit={onSubmit}>
                    {!displayForgotPasswordRequestForm ?
                        <>
                            <InputField onChange={e => setEmail(e.target.value)}
                                        label={"Email:"}
                                        error={loginErrors.email}
                                        value={email}
                                        name={"email"}/>
                            <InputField onChange={e => setPassword(e.target.value)}
                                        label={t("password")}
                                        error={loginErrors.password}
                                        type={InputType.Password}
                                        value={password}
                                        name={"password"}/>
                            {/*<span className="forgot-pass-btn text-btn"*/}
                            {/*      onClick={onClickForgotPassword}>{t("forgot_password") + "?"}</span>*/}
                            <Button className="login-btn"
                                    type={ButtonType.Submit}
                                    loading={loginAsync}
                                    disabled={!email}>
                                {t("sign_in")}
                            </Button>
                        </> :
                        !displayForgotPasswordSubmitForm ? renderForgotPasswordRequestForm() : renderForgotPasswordSubmitForm()
                    }
                    <span className="error-msg">{loginErrors.generic}</span>
                </form>
            </div>
        </div>
    );
}

export default Login;
