import {
    AnyAction,
    CaseReducer,
    createAction,
    createSlice,
    Draft,
    PayloadAction,
    ThunkAction,
} from "@reduxjs/toolkit";
import {
    CaseReducerActions,
    Slice,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from "@reduxjs/toolkit/src/createSlice";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { RootState } from "../store";

import { API, ApiCallParams, APIEntity, ApiMethod } from "../../api/api";
import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "../../api/paginationResponse";

export type AsyncAction<T = void> = ThunkAction<T,
    RootState,
    unknown,
    AnyAction>;

export type ExtraReducers<State> = ((builder: ActionReducerMapBuilder<NoInfer<State>>) => void);

export interface BaseEntity {
    id: string;
}

// TODO, make query field not optional
export interface BaseEntityReducerState<T, Query = {}> {
    // ID -> entity map
    entities: Record<string, T>;
    query?: Query,
    selectedEntities: string[],
    currentPage: number;
    lastPage: number;
    totalItems: number;
    hasMore: boolean;
    perPage: number;
    fetchAsync: boolean;
    addEditAsync: boolean;
    deleteAsync: boolean;
    searchFields: (keyof T)[];
}

export type EntityReducers<State, Entity, Query> = {
    selectOrDeselectEntity: CaseReducer<State, PayloadAction<string>>,
    singleSelectOrDeselectEntity: CaseReducer<State, PayloadAction<string>>,
    deselectEntity: CaseReducer<State, PayloadAction<string>>
    deselectAll: CaseReducer<State>
    singleSelectEntity: CaseReducer<State, PayloadAction<string>>,
    unselectAll: CaseReducer<State, PayloadAction>,
    removeEntity: CaseReducer<State, PayloadAction<string>>,
    selectAll: CaseReducer<State, PayloadAction>,
    addEntity: CaseReducer<State, PayloadAction<{ id: string, newEntity: Entity }>>,
    updateEntity: CaseReducer<State, PayloadAction<{ id: string, data: Partial<Entity> }>>,
    setAddEditAsync: CaseReducer<State, PayloadAction<boolean>>,
    setFetchAsync: CaseReducer<State, PayloadAction<boolean>>,
    successFetchEntities: CaseReducer<State, PayloadAction<Entity[]>>,
    updateQuery: CaseReducer<State, PayloadAction<Query>>,
}

export interface EntityReducer<S, T, EntityForm, R extends SliceCaseReducers<S>, N extends string = string> {
    slice: Slice<S, R, N>,
    asyncActions: {
        fetchEntityPage: (page: number) => AsyncAction<Promise<PaginationResponse<T> | null>>,
        fetchAllEntities: () => AsyncAction<Promise<T[] | null>>,
        createEntity: (entityForm: EntityForm) => AsyncAction<Promise<T | null>>,
        updateEntityAction: ({
                                 entityId,
                                 entityForm,
                             }: { entityId: string, entityForm: EntityForm }) => AsyncAction<Promise<T | null>>,
        deleteEntity: (entityId: string) => AsyncAction<Promise<unknown>>,
        fetchSingleEntity: ({
                                id,
                                shouldAsync,
                                defaultSelect,
                            }: { id: string, shouldAsync?: boolean, defaultSelect?: boolean }) => AsyncAction,
        archiveEntity: (entityId: string) => AsyncAction<Promise<T | null>>,
        unarchiveEntity: (entityId: string) => AsyncAction<Promise<T | null>>
    }
}

interface EntityReducerOptions {
    appendMode: boolean,
}

export function createEntityReducer<S extends BaseEntityReducerState<T, Query>,
    T extends BaseEntity,
    EntityForm,
    Query,
    R extends SliceCaseReducers<S>,
    ER extends ExtraReducers<S>>(entity: T, entityName: APIEntity, state: S, reducers: ValidateSliceCaseReducers<S, R>, extraReducers?: ER, entityForm?: EntityForm, query?: Query, opt?: EntityReducerOptions, apiCallParams?: Partial<ApiCallParams>): EntityReducer<S, T, EntityForm, EntityReducers<S, T, Query> & ValidateSliceCaseReducers<S, R>> {

    const options = opt ?? { appendMode: false };

    // fetch
    const SetFetchEntityAsync = createAction<boolean>(
        `${entityName}/SET_FETCH_ENTITY_ASYNC`,
    );

    const SuccessFetchEntityPage = createAction<{ currentPage: number, lastPage: number, hasMore: boolean, data: T[], totalItems: number, }>(
        `${entityName}/SUCCESS_FETCH_ENTITY_PAGE`,
    );

    const SuccessFetchAllEntities = createAction<T[]>(
        `${entityName}/SUCCESS_FETCH_ALL_ENTITIES`,
    );

    const SuccessFetchSingleEntity = createAction<{ entity: T, defaultSelect: boolean }>(
        `${entityName}/SUCCESS_FETCH_SINGLE_ENTITY`,
    );

    // post/put
    const SetAddEditEntityAsync = createAction<boolean>(
        `${entityName}/SET_ADD_EDIT_ENTITY_ASYNC`,
    );

    const SuccessCreateEntity = createAction<{ newEntity: T }>(
        `${entityName}/SUCCESS_CREATE_ENTITY`,
    );

    const SuccessArchiveEntity = createAction<{ entity: T }>(
        `${entityName}/SUCCESS_ARCHIVE_ENTITY`,
    );

    const SuccessUnarchiveEntity = createAction<{ entity: T }>(
        `${entityName}/SUCCESS_UNARCHIVE_ENTITY`,
    );

    // delete
    const SetDeleteEntityAsync = createAction<boolean>(
        `${entityName}/SET_DELETE_ENTITY_ASYNC`,
    );

    const SuccessDeleteEntity = createAction<string>(
        `${entityName}/SUCCESS_DELETE_ENTITY`,
    );

    const slice = createSlice({
        name: entityName,
        initialState: state,
        reducers: {
            setAddEditAsync(state, {
                payload: async,
            }: PayloadAction<boolean>) {
                state.addEditAsync = async;
            },
            setFetchAsync(state, {
                payload: async,
            }: PayloadAction<boolean>) {
                state.fetchAsync = async;
            },
            successFetchEntities(state, {
                payload: entities,
            }: PayloadAction<T[]>) {
                if (!options.appendMode) {
                    state.entities = {};
                }

                entities.forEach((entity) => {
                    state.entities[entity.id] = entity as Draft<T>;
                });
            },
            singleSelectEntity(state, {
                payload: id,
            }: PayloadAction<string>) {
                state.selectedEntities = [id];
            },
            deselectEntity: (state, {
                payload: id,
            }: PayloadAction<string>) => {
                const index = state.selectedEntities.findIndex(entity => entity === id);
                if (index !== -1) {
                    state.selectedEntities.splice(index, 1);
                }
            },
            deselectAll: (state) => {
                state.selectedEntities = [];
            },
            selectOrDeselectEntity(state, {
                payload: id,
            }: PayloadAction<string>) {
                const index = state.selectedEntities.findIndex(entity => entity === id);

                if (index === -1) {
                    state.selectedEntities.push(id);
                } else {
                    state.selectedEntities.splice(index, 1);
                }
            },
            singleSelectOrDeselectEntity(state, {
                payload: id,
            }: PayloadAction<string>) {
                const index = state.selectedEntities.findIndex(entity => entity === id);

                if (index === -1) {
                    state.selectedEntities = [id];
                } else {
                    state.selectedEntities = [];
                }
            },
            removeEntity(state, { payload: id }: PayloadAction<string>) {
                delete state.entities[id];
                const idx = state.selectedEntities.findIndex(e => e === id);
                if (idx !== -1) {
                    state.selectedEntities.splice(idx);
                }
            },
            selectAll(state) {
                state.selectedEntities = Object.keys(state.entities);
            },
            unselectAll(state) {
                state.selectedEntities = [];
            },
            updateEntity(state, { payload }: PayloadAction<{ id: string, data: Partial<T> }>) {
                if (state.entities[payload.id]) {
                    Object.assign(state.entities[payload.id], payload.data);
                }
            },
            addEntity(state, { payload: { newEntity } }: PayloadAction<{ id: string, newEntity: T }>) {
                state.entities[newEntity.id] = newEntity as Draft<T>;
            },
            updateQuery(state, { payload }: PayloadAction<Query>) {
                Object.assign(state.query, payload);
            },
            ...reducers,
        },
        extraReducers: builder => {
            builder.addCase(SuccessFetchEntityPage, (state, { payload }) => {
                state.currentPage = payload.currentPage;
                state.hasMore = payload.hasMore;
                state.lastPage = payload.lastPage;
                state.totalItems = payload.totalItems;
                if (!options.appendMode || payload.currentPage === 1) {     // reset entities
                    state.entities = {};
                }
                payload.data.forEach((entity) => {
                    state.entities[entity.id] = entity as Draft<T>;
                });
            }).addCase(SuccessFetchAllEntities, (state, { payload }) => {

                state.entities = {};        // TODO, myb save to another state variable

                payload.forEach((entity) => {
                    state.entities[entity.id] = entity as Draft<T>;
                });

            }).addCase(SetFetchEntityAsync, (state, { payload }) => {
                state.fetchAsync = payload;
            }).addCase(SuccessCreateEntity, (state, { payload: { newEntity } }) => {
                state.entities[newEntity.id] = newEntity as Draft<T>;
            }).addCase(SetAddEditEntityAsync, (state, { payload }) => {
                state.addEditAsync = payload;
            }).addCase(SuccessDeleteEntity, (state, { payload }) => {
                delete state.entities[payload];
                const idx = state.selectedEntities.findIndex(e => e === payload);
                if (idx !== -1) {
                    state.selectedEntities.splice(idx);
                }
            }).addCase(SetDeleteEntityAsync, (state, { payload }) => {
                state.deleteAsync = payload;
            }).addCase(SuccessFetchSingleEntity, (state, { payload: { entity, defaultSelect } }) => {
                state.entities[entity.id] = entity as Draft<T>;
                if (defaultSelect) {
                    state.selectedEntities = [entity.id];
                }
            }).addCase(SuccessArchiveEntity, (state, { payload }) => {
                delete state.entities[payload.entity.id];
                const idx = state.selectedEntities.findIndex(e => e === payload.entity.id);
                if (idx !== -1) {
                    state.selectedEntities.splice(idx);
                }
            }).addCase(SuccessUnarchiveEntity, (state, { payload }) => {
                delete state.entities[payload.entity.id];
                const idx = state.selectedEntities.findIndex(e => e === payload.entity.id);
                if (idx !== -1) {
                    state.selectedEntities.splice(idx);
                }
            });

            extraReducers?.(builder);
        },
    });

    const actions = slice.actions as CaseReducerActions<EntityReducers<S, T, Query>>;

    const getReducerStateFromEntityName = (state: RootState) => {
        switch (entityName) {
            case APIEntity.Leads:
                return state.leads;
            case APIEntity.Topics:
                return state.topics;
            case APIEntity.MessageTemplates:
                return state.messageTemplates;
            case APIEntity.MessageTemplateCategories:
                return state.messageTemplateCategories;
            case APIEntity.Questionnaire:
                return state.questionnaires;
            case APIEntity.Users:
                return state.users;
            default:
                return state[entityName];
        }
    };

    const fetchSingleEntity = ({
                                   id: entityId,
                                   shouldAsync = true,
                                   defaultSelect = false,
                               }: { id: string, shouldAsync?: boolean, defaultSelect?: boolean }): AsyncAction => {
        return async (dispatch) => {
            if (shouldAsync) {
                dispatch(SetFetchEntityAsync(true));
            }

            const r = await API.jsonApiCall<T>({
                url: `${API.getApiSingleEntityUrl(entityName)}/${entityId}`,
            }).finally(() => dispatch(SetFetchEntityAsync(false)));

            if (r) {
                dispatch(SuccessFetchSingleEntity({ entity: r, defaultSelect }));

                if (shouldAsync) {
                    dispatch(SetFetchEntityAsync(false));
                }
            }

            return r;
        };
    };

    const fetchAllEntities = (): AsyncAction<Promise<T[] | null>> => {
        return async (dispatch, getState) => {

            dispatch(SetFetchEntityAsync(true));

            const url = new URL(API.getApiEntityUrl(entityName));

            const reducerState = getReducerStateFromEntityName(getState());

            const query = reducerState?.query;

            if (query) {
                Object.entries(query).forEach(([key, value]: [string, string]) => {
                    // TODO
                    // if (key === "search" && value !== undefined) {
                    //     reducerState.searchFields.forEach(field => {
                    //         url.searchParams.append(field, value);
                    //     });
                    // } else
                    if (value !== undefined) {
                        url.searchParams.append(key, value);
                    }
                });
            }

            const r = await API.jsonApiCall<T[]>({
                url: url.toString(),
                ...apiCallParams,
            }).finally(() => dispatch(SetFetchEntityAsync(false)));

            if (r) {
                dispatch(SuccessFetchAllEntities(r));
                return r;
            }

            return null;
        };
    };

    const fetchEntityPage = (page: number): AsyncAction<Promise<PaginationResponse<T> | null>> => {
        return async (dispatch, getState) => {
            const { perPage } = state;

            dispatch(SetFetchEntityAsync(true));

            const url = new URL(API.getApiEntityPaginatedUrl(entityName, {
                page,
                perPage,
            }));

            const reducerState = getReducerStateFromEntityName(getState());

            const query = reducerState?.query;

            if (query) {
                Object.entries(query).forEach(([key, value]: [string, string]) => {
                    // TODO
                    // if (key === "search" && value !== undefined) {
                    //     reducerState.searchFields.forEach(field => {
                    //         url.searchParams.append(field, value);
                    //     });
                    // } else
                    if (value !== undefined) {
                        url.searchParams.append(key, value);
                    }
                });
            }

            const r = await API.jsonApiCall<RawHydraResponse<T>>({
                url: url.toString(),
                headers: {
                    accept: "application/ld+json",
                },
            });

            dispatch(SetFetchEntityAsync(false));

            if (r) {
                const { firstPage, lastPage, hasMore, data, totalItems } = RawHydraResponseIntoPaginationResponse<T>(r);

                dispatch(SuccessFetchEntityPage({ lastPage, hasMore, currentPage: page, data, totalItems }));

                return {
                    firstPage,
                    lastPage,
                    hasMore,
                    data,
                    totalItems,
                };
            }

            return null;
        };
    };

    const createEntity = (entityForm: EntityForm): AsyncAction<Promise<T | null>> => {
        return async (dispatch, getState) => {

            dispatch(SetAddEditEntityAsync(true));

            try {
                const r = await API.jsonApiCall<T>({
                    url: `${API.getApiEntityUrl(entityName)}`,
                    method: ApiMethod.Post,
                    jsonData: entityForm as unknown as Record<string, unknown>,
                });

                if (r !== null) {
                    dispatch(SuccessCreateEntity({ newEntity: r }));
                }

                return r;
            } catch (ex: unknown) {
                throw(ex);
            } finally {
                dispatch(SetAddEditEntityAsync(false));
            }
        };
    };

    const updateEntityAction = ({
                                    entityId,
                                    entityForm,
                                }: { entityId: string, entityForm: EntityForm }): AsyncAction<Promise<T | null>> => {
        return async (dispatch, getState) => {

            dispatch(SetAddEditEntityAsync(true));

            try {
                const r = await API.jsonApiCall<T & BaseEntity>({
                    url: `${API.getApiEntityUrl(entityName)}/${entityId}`,
                    method: ApiMethod.Put,
                    jsonData: entityForm as unknown as Record<string, unknown>,
                });

                if (r !== null) {
                    dispatch(actions.updateEntity({ id: r.id, data: r }));
                }

                return r;
            } finally {
                dispatch(SetAddEditEntityAsync(false));
            }
        };
    };

    const deleteEntity = (entityId: string): AsyncAction<Promise<unknown>> => {
        return async (dispatch, getState) => {

            dispatch(SetDeleteEntityAsync(true));

            return await API.jsonApiCall<null>({
                url: `${API.getApiEntityUrl(entityName)}/${entityId}`,
                method: ApiMethod.Delete,
            })
                .then(() => dispatch(SuccessDeleteEntity(entityId)))
                .finally(() => dispatch(SetDeleteEntityAsync(false)));
        };
    };

    const archiveEntity = (entityId: string): AsyncAction<Promise<T | null>> => {
        return async (dispatch, getState) => {

            dispatch(SetAddEditEntityAsync(true));

            return await API.jsonApiCall<null>({
                url: `${API.getApiEntityUrl(entityName)}/${entityId}/archive`,
                method: ApiMethod.Put,
                jsonData: {},
            })
                .then(r => {
                    if (r) {
                        dispatch(SuccessArchiveEntity({ entity: r }));
                        return r;
                    }

                    return null;
                })
                .finally(() => dispatch(SetAddEditEntityAsync(false)));
        };
    };

    const unarchiveEntity = (entityId: string): AsyncAction<Promise<T | null>> => {
        return async (dispatch, getState) => {

            dispatch(SetAddEditEntityAsync(true));

            return await API.jsonApiCall<null>({
                url: `${API.getApiEntityUrl(entityName)}/${entityId}/unarchive`,
                method: ApiMethod.Put,
                jsonData: {},
            })
                .then(r => {
                    if (r) {
                        dispatch(SuccessUnarchiveEntity({ entity: r }));
                        return r;
                    }

                    return null;
                })
                .finally(() => dispatch(SetAddEditEntityAsync(false)));
        };
    };

    return {
        slice,
        asyncActions: {
            fetchEntityPage,
            fetchAllEntities,
            createEntity,
            updateEntityAction,
            deleteEntity,
            fetchSingleEntity,
            archiveEntity,
            unarchiveEntity,
        },
    };
}
