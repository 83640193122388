import { createAction } from "@reduxjs/toolkit";
import { messengerAsyncActions } from "../slices/messengerSlice";

import { MessageWithLeadId } from "src/entities/message/Message";
import {
    ActivateCustomerConversationEvent,
    CompanyBumpCustomerConversationEvent,
    CompanyMessageDeliveryStatusUpdatedEvent,
    CustomerClaimedEvent, CustomerUnsubscribedEvent, ReadStateUpdatedEvent,
} from "src/entities/socketEvents";
import { Lead } from "../../entities/Lead/Lead";
import { leadsAsyncActions } from "../slices/leadSlice";

export const SocketActionTypes = {
    AgentReadStateUpdated: "SOCKET_agent_read_state_updated",
    AgentActivateCustomerConversation: "SOCKET_agent_activate_customer_conversation",
    CompanyActivateCustomerConversation: "SOCKET_company_activate_customer_conversation",
    CompanyCustomerUnsubscribed: "SOCKET_company_customer_unsubscribed",
    CompanyInboundMessage: "SOCKET_company_inbound_message",
    CompanyOutboundMessage: "SOCKET_company_outbound_message",
    CompanyMessageDeliveryStatusUpdated: "SOCKET_company_message_delivery_status_updated",
    CompanyBumpCustomerConversation: "SOCKET_company_bump_customer_conversation",
    CompanyCustomerUpdated: "SOCKET_company_customer_updated",
    CompanyCustomerCreated: "SOCKET_company_customer_created",
    CompanyCustomerClaimed: "SOCKET_company_customer_claimed",
};

export type PayloadWithChannelEntityId<T> = { channelEntityId: string, payload: T };

export const SocketActions = {
    AgentReadStateUpdated: createAction<PayloadWithChannelEntityId<ReadStateUpdatedEvent>>(SocketActionTypes.AgentReadStateUpdated),
    AgentActivateCustomerConversation: createAction<PayloadWithChannelEntityId<ActivateCustomerConversationEvent>>(SocketActionTypes.AgentActivateCustomerConversation),
    CompanyCustomerUnsubscribed: createAction<PayloadWithChannelEntityId<CustomerUnsubscribedEvent>>(SocketActionTypes.CompanyCustomerUnsubscribed),
    CompanyInboundMessage: createAction<PayloadWithChannelEntityId<MessageWithLeadId>>(SocketActionTypes.CompanyInboundMessage),
    CompanyOutboundMessage: createAction<PayloadWithChannelEntityId<MessageWithLeadId>>(SocketActionTypes.CompanyOutboundMessage),
    CompanyMessageDeliveryStatusUpdated: createAction<PayloadWithChannelEntityId<CompanyMessageDeliveryStatusUpdatedEvent>>(SocketActionTypes.CompanyMessageDeliveryStatusUpdated),
    CompanyBumpCustomerConversation: createAction<PayloadWithChannelEntityId<CompanyBumpCustomerConversationEvent>>(SocketActionTypes.CompanyBumpCustomerConversation),
    CompanyCustomerUpdated: createAction<PayloadWithChannelEntityId<Partial<Lead> & { id: string }>>(SocketActionTypes.CompanyCustomerUpdated),
    CompanyCustomerCreated: createAction<PayloadWithChannelEntityId<{ id: string }>>(SocketActionTypes.CompanyCustomerCreated),
    CompanyCustomerClaimed: createAction<PayloadWithChannelEntityId<CustomerClaimedEvent>>(SocketActionTypes.CompanyCustomerClaimed),
};

export const SocketEventRegexes = {
    user: /user:(?<entityId>.*):(?<eventName>.*)/,
    agent: /agent:(?<entityId>.*):(?<eventName>.*)/,
    company: /company:(?<entityId>.*):(?<eventName>.*)/,
};

// this has to be a function so it happens after all the redux stuff has been initialized
export function getSocketActionToEventMapper(): Record<string, Array<Function>> {
    return {};
}

// this has to be a function so it happens after all the redux stuff has been initialized
export function getSocketActionToAsyncEventMapper() {
    return {
        [SocketActionTypes.CompanyOutboundMessage]: [messengerAsyncActions.handleOutboundMessageEvent],
        [SocketActionTypes.CompanyCustomerCreated]: [leadsAsyncActions.socketLeadCreated],
        [SocketActionTypes.CompanyActivateCustomerConversation]: [leadsAsyncActions.socketLeadCreated],
        [SocketActionTypes.AgentActivateCustomerConversation]: [leadsAsyncActions.socketLeadCreated],
        [SocketActionTypes.CompanyCustomerClaimed]: [leadsAsyncActions.socketLeadClaimed],
    };
}
