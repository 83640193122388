import React, {
    CSSProperties,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { useOnClickOutside } from "../../../utils/hooks/useOnClickOutside";
import "./dropdown.scss";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import { motion } from "framer-motion";

export enum DropdownAnchorEdge {
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    Right = "right",
    Left = "left",
    TopLeft = "top-left",
    TopRight = "top-right",
}

export enum AnimationType {
    Dropdown = "dropdown",
    Fade = "fade",
}

export enum AnimationOrigin {
    Top,
    Bottom
}

export interface DropdownProps<T extends HTMLElement> {
    className?: string;
    innerClassName?: string;
    closeOnInnerClick?: boolean,
    children: React.ReactNode | React.ReactElement | React.ComponentType<any>;
    open?: boolean;
    setOpen?: (open: boolean) => void;
    style?: CSSProperties;
    anchorWrapperStyle?: CSSProperties;
    anchorWrapperClassName?: string;
    anchor?: React.ReactNode | React.ReactElement | React.ComponentType<any>;
    anchorRef?: MutableRefObject<T | null>;
    anchorEdge?: DropdownAnchorEdge;
    animationType?: AnimationType,
    positionCorrection?: {
        top?: string;
        bottom?: string;
        left?: string;
        right?: string;
        height?: string;
    };
}

function Dropdown<T extends HTMLElement>({
                                             anchor,
                                             anchorWrapperClassName,
                                             anchorWrapperStyle,
                                             className,
                                             innerClassName,
                                             children,
                                             closeOnInnerClick = true,
                                             style,
                                             open,
                                             setOpen,
                                             anchorRef,
                                             animationType = AnimationType.Dropdown,
                                             anchorEdge = DropdownAnchorEdge.BottomLeft,
                                             positionCorrection = {
                                                 top: "8px",
                                                 bottom: "20px",
                                                 right: "8px",
                                             },
                                         }: DropdownProps<T>) {
    const [dropdownOpen, setDropdownOpen] = useState(open ?? false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownContentRef = useRef<HTMLDivElement>(null);
    const anchorWrapperRef = useRef<HTMLDivElement>(null);
    const { width, height } = useWindowSize();

    const [bottomAndLeft, setBottomAndLeft] = useState<{
        bottom: number;
        left: number;
    }>({ bottom: 0, left: 0 });

    const [anchorPosition, setAnchorPosition] = useState<DOMRect | null>(
        anchorRef?.current?.getBoundingClientRect() ??
        anchorWrapperRef.current?.getBoundingClientRect() ??
        null,
    );

    const dropdownOpenChange = useCallback(
        (newDropdownOpen) => {
            if (setOpen) {
                setOpen(newDropdownOpen);
            } else {
                setDropdownOpen(newDropdownOpen);
            }
        },
        [setOpen],
    );

    useOnClickOutside(
        dropdownRef,
        () => {
            if (dropdownOpen) {
                dropdownOpenChange(false);
            }
        },
        anchorRef,
        anchorWrapperRef,
        dropdownContentRef,
    );

    useEffect(() => {
        setDropdownOpen(open ?? false);
    }, [open]);

    useEffect(() => {
        setAnchorPosition(
            anchorRef?.current?.getBoundingClientRect() ??
            anchorWrapperRef.current?.getBoundingClientRect() ??
            null,
        );
    }, [anchorRef, anchorWrapperRef, open, dropdownOpen, width, height]);

    let dropdownStyle: CSSProperties = {};
    const right = window.innerWidth - (anchorPosition?.right ?? 0);

    if (anchorEdge === DropdownAnchorEdge.BottomLeft) {
        dropdownStyle = {
            top: `calc(${anchorPosition?.top ?? -10000}px + ${
                anchorPosition?.height ?? 0
            }px + ${positionCorrection?.top ?? "0px"} + ${bottomAndLeft.bottom}px)`,
            height: positionCorrection?.height,
            left: `calc(${anchorPosition?.left ?? -10000}px + ${
                positionCorrection?.left ?? "0px"
            })`,
        };
    } else if (anchorEdge === DropdownAnchorEdge.TopLeft) {
        dropdownStyle = {
            top: `calc(${anchorPosition?.top ?? -10000}px - ${
                (anchorPosition?.height ?? 0) * 2
            }px - ${positionCorrection?.bottom ?? "0px"} + ${bottomAndLeft.bottom}px)`,
            height: positionCorrection?.height,
            left: `calc(${anchorPosition?.left ?? -10000}px + ${
                positionCorrection?.left ?? "0px"
            })`,
        };
    } else if (anchorEdge === DropdownAnchorEdge.TopRight) {
        dropdownStyle = {
            top: `calc(${anchorPosition?.top ?? -10000}px - ${
                ((anchorPosition?.height ?? 0) * 3)
            }px - ${positionCorrection?.bottom ?? "0px"} + ${bottomAndLeft.bottom}px)`,
            right: `calc(${right}px + ${positionCorrection?.right ?? "0px"})`,
        };
    } else if (anchorEdge === DropdownAnchorEdge.BottomRight) {
        dropdownStyle = {
            top: `calc(${anchorPosition?.top ?? -10000}px + ${
                anchorPosition?.height ?? 0
            }px + ${positionCorrection?.top ?? "0px"})`,
            height: positionCorrection?.height,
            right: `calc(${right}px + ${positionCorrection?.right ?? "0px"})`,
        };
    } else if (anchorEdge === DropdownAnchorEdge.Right) {
        dropdownStyle = {
            top: `calc(${anchorPosition?.top ?? -10000}px + ${
                (anchorPosition?.height ?? 0) / 2
            }px + ${positionCorrection?.top ?? "0px"} + ${bottomAndLeft.bottom}px)`,
            height: positionCorrection?.height,
            left: `calc(${anchorPosition?.left ?? 0}px + ${anchorPosition?.width}px + ${
                positionCorrection?.right ?? "0px"
            })`,
        };
    } else if (anchorEdge === DropdownAnchorEdge.Left) {
        dropdownStyle = {
            top: `calc(${anchorPosition?.top ?? -10000}px + ${
                (anchorPosition?.height ?? 0) / 2
            }px + ${positionCorrection?.top ?? "0px"} + ${bottomAndLeft.bottom}px)`,
            height: positionCorrection?.height,
            right: `calc(${right}px + ${anchorPosition?.width ?? "0"}px + ${
                positionCorrection?.right ?? "0px"
            })`,
        };
    }

    const positionCorrector = useCallback((data) => {
        const contentRect = dropdownContentRef.current?.getBoundingClientRect();
        const pickerH = typeof data.height === "number" ? data.height : contentRect?.height ?? 0;

        if (!dropdownOpen) {
            setBottomAndLeft({ bottom: 0, left: 0 });
        }

        if (contentRect) {
            let top = (contentRect?.top ?? -10000) + 8;
            let left = contentRect?.left ?? -10000;

            const pickerW = contentRect.width;

            const leftAndWidth = left + pickerW;
            const bottomAndHeight = top + pickerH;

            let leftOffset = 0;
            let bottomOffset = 0;

            if (left + pickerW > width) {
                leftOffset = width - leftAndWidth;
            }

            if (top + pickerH > height) {
                bottomOffset = height - bottomAndHeight;
            }

            setBottomAndLeft({ bottom: bottomOffset, left: leftOffset });
        }
    }, [dropdownOpen, dropdownContentRef, height, width]);

    useEffect(() => {
        positionCorrector({ height: null });
    }, [positionCorrector]);

    const initial = animationType === AnimationType.Dropdown ? {
        height: dropdownOpen ? "auto" : "0px",
        transitionEnd: {
            display: dropdownOpen ? "inherit" : "none",
        },
    } : {
        height: "auto",
        opacity: dropdownOpen ? 1 : 0,
        scaleX: dropdownOpen ? 1 : 0,
        scaleY: dropdownOpen ? 1 : 0,
        display: "inherit",
        transformOrigin: "center",
        transitionEnd: {
            display: dropdownOpen ? "inherit" : "none",
        },
    };

    const transition = animationType === AnimationType.Dropdown ? { duration: 0.22, type: "tween" } : undefined;

    return (
        <div
            className={classNames(
                "dropdown-top-wrapper",
                className,
                `edge-${anchorEdge}`,
            )}
            ref={dropdownRef}>
            {anchor && (
                <div
                    className={classNames(
                        "dropdown-anchor-wrapper",
                        { open: dropdownOpen },
                        anchorWrapperClassName,
                    )}
                    onClick={() => dropdownOpenChange(!dropdownOpen)}
                    style={anchorWrapperStyle}
                    ref={anchorWrapperRef}>
                    {anchor}
                </div>
            )}

            <motion.div
                onAnimationComplete={positionCorrector}
                animate={initial}
                initial={initial}
                transition={transition}
                // transition={{ type: 'spring', damping: 20, stiffness: 250, velocity: 7, bounce: 0, mass: 0.1 }}
                ref={dropdownContentRef}
                className={classNames("dropdown", innerClassName, `animation-type-${animationType}`)}
                style={Object.assign({}, dropdownStyle, style)}>
                <div className="dropdown-inner"
                     onClick={(e) => {
                         if (closeOnInnerClick) {
                             dropdownOpenChange(false);
                         }
                     }}>{children}</div>
            </motion.div>
        </div>
    );
}

export default Dropdown;
